import { Typography } from '@mui/material'
import { GenieOverlay } from './GenieOverlay'

export const GenieError = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <GenieOverlay isClosable>
      <Typography variant="body2" color="error">
        {errorMessage}
      </Typography>
    </GenieOverlay>
  )
}
