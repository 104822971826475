import BillyGridCell, { GridCellProps } from '@/components/grid/billyGridCell'
import { PlanFragment } from '@/generated/graphql'
import buildLogger from '@/util/logger'
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid'
import { Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
const logger = buildLogger('PlanReplacementCompareView')
export const usePlanReplacementCompareViewStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    rowGap: theme.spacing(2),
  },
  replaceIcon: { margin: '0 auto' },
  planContainer: {
    display: 'flex',
    flexFlow: 'column',
    rowGap: theme.spacing(1),
  },
  replaceByIndicator: {
    display: 'grid',
    gridColumn: '2/span 1',
    placeSelf: 'center',
  },
}))

export type PlanReplacementCompareViewProps = {
  children?: React.ReactNode
  originalPlan: Pick<PlanFragment, 'id' | 'name'>
  replacementPlan: Pick<PlanFragment, 'id' | 'name'>
}

export function PlanReplacementCompareView({
  children,
  originalPlan,
  replacementPlan,
}: PlanReplacementCompareViewProps): JSX.Element {
  const { classes } = usePlanReplacementCompareViewStyles()
  const originalPlanCells: GridCellProps[] = [
    {
      label: 'Original Plan ID',
      description: originalPlan.id ?? '',
      variant: 'copy-description',
    },
    {
      label: 'Original Plan Name',
      description: originalPlan.name,
    },
  ]

  const replacementPlanCells: GridCellProps[] = [
    {
      label: 'Replacement Plan ID',
      description: replacementPlan.id ?? '',
      variant: 'copy-description',
    },
    {
      label: 'Replacement Plan Name',
      description: replacementPlan.name,
    },
  ]

  return (
    <>
      {children}
      <div className={classes.container}>
        <div className={classes.planContainer}>
          {originalPlanCells.map((cell, index) => (
            <BillyGridCell key={index} {...cell} />
          ))}
        </div>
        <div className={classes.replaceByIndicator}>
          <FlipCameraAndroidIcon className={classes.replaceIcon} color="action" />
          <Typography
            variant="overline"
            sx={{
              color: (theme) => theme.customPalette.textGray,
              cursor: 'default',
              whiteSpace: 'nowrap',
            }}
          >
            Replaced By
          </Typography>
        </div>
        <div className={classes.planContainer}>
          {replacementPlanCells.map((cell, index) => (
            <BillyGridCell key={index} {...cell} />
          ))}
        </div>
      </div>
    </>
  )
}
