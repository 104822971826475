import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import ActionButton from '@/components/button/actionButton'
import BillyGridCell, { GridCellProps } from '@/components/grid/billyGridCell'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import { PlanFragment } from '@/generated/graphql'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useCallback, useState } from 'react'
type ConfirmPlanReplacementDialogProps = {
  replacementPlan: Pick<PlanFragment, 'id' | 'name'>
  disabledExplanation?: string
}

type DialogProps = WithModalParams & ConfirmPlanReplacementDialogProps
const formID = 'ConfirmPlanReplacementDialog'
function ConfirmPlanReplacementDialog({ replacementPlan, disabledExplanation, open, onClose, onSubmit }: DialogProps) {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onFormSubmit = () => {
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.()
      setIsSubmitting(false)
      onClose?.()
    }

    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  const cells: GridCellProps[] = [
    {
      label: 'Plan ID',
      description: replacementPlan.id ?? '',
      variant: 'copy-description',
      xs: 6,
    },
    {
      label: 'Plan Name',
      description: replacementPlan.name,
      xs: 6,
    },
  ]

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Replacement Plan</DialogTitle>
      <DialogContent dividers>
        <form
          id={formID}
          onSubmit={(e) => {
            e.preventDefault()
            onFormSubmit()
          }}
        >
          <Grid container rowGap={2} columnSpacing={1}>
            {cells.map((cell, index) => (
              <BillyGridCell key={index} {...cell} />
            ))}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          key={'Replace'}
          buttonData={{
            disabledExplanation,
            label: 'Replace',
            loading: isSubmitting,
            buttonProps: { type: 'submit', form: formID },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useConfirmPlanReplacementDialog(modalProps: DialogProps) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: ConfirmPlanReplacementDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  const toggleConfirmPlanReplacementDialog = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  return [toggleConfirmPlanReplacementDialog]
}
