import { Box, Theme } from '@mui/material'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import { PropsWithChildren, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import { useIsScrolledToBottom } from '../GenieWidget/hooks/useIsScrolledToBottom'

const useMinimizeIconStyles = makeStyles()((theme: Theme) => ({
  minimizeIcon: {
    position: 'absolute',
    display: 'inline-flex',
    cursor: 'pointer',
    top: theme.spacing(-1.5),
    right: theme.spacing(-1),
    backgroundColor: 'white',
    borderRadius: '50%',
    boxShadow: '0px 0px 4px blue',
  },
}))

interface MinimizeIconWrapperProps {
  isWidgetOpen?: boolean
  isGenieIconMinimized: boolean
  setIsGenieIconMinimized: (value: boolean) => void
}

export const MinimizeIconWrapper = ({
  children,
  isWidgetOpen,
  isGenieIconMinimized,
  setIsGenieIconMinimized,
}: PropsWithChildren<MinimizeIconWrapperProps>) => {
  const { classes } = useMinimizeIconStyles()
  const { isScrolledToBottom, setIsScrolledToBottom } = useIsScrolledToBottom()
  const [showMinimizeIcon, setShowMinimizeIcon] = useState(false)

  const handleOnMouseEnter = () => {
    setShowMinimizeIcon(true)
    setIsScrolledToBottom()
  }

  return (
    <Box onMouseEnter={handleOnMouseEnter} onMouseLeave={() => setShowMinimizeIcon(false)}>
      {!isWidgetOpen && (isGenieIconMinimized || (isScrolledToBottom && showMinimizeIcon)) && (
        <Box className={classes.minimizeIcon} onClick={() => setIsGenieIconMinimized(!isGenieIconMinimized)}>
          <ExpandCircleDownOutlinedIcon
            fontSize="small"
            color="primary"
            sx={{ rotate: isGenieIconMinimized ? '180deg' : '0' }}
          />
        </Box>
      )}
      {children}
    </Box>
  )
}
