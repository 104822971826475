import { CommonOrderFormPageState } from '@/pageComponents/orders/EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'
import { useCallback } from 'react'
import { useTenantTimeZone } from '../../../components/UserTenantSessionProvider/useTenantTimeZone'
import JotaiMuiDateField from '../../../components/input/JotaiMuiDateField'
import { useJotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { ActionType, GetOrderDetailQuery, OrderType } from '../../../generated/graphql'
import { unixTimeSecondsToLuxonWithTz } from '../../../util/datetime/luxon/dateUtil'
import { useAmendmentOrderStartDateChangeDialog } from '../AmendmentOrderStartDateChangeDialog'
import { DeepMutable } from '@/components/SchemaForm/DeepMutable'

export function OrderAmendOrCancelDate() {
  const jotaiForm = useJotaiFormContext<CommonOrderFormPageState>()
  const tenantTZ = useTenantTimeZone()
  const { minDate, maxDate, label } = jotaiForm.useSelect(
    useCallback(
      (form) => ({
        minDate: unixTimeSecondsToLuxonWithTz(form.orderDetail.currentSubscription?.startDate, tenantTZ)?.toISO(),
        maxDate: unixTimeSecondsToLuxonWithTz(form.orderDetail.currentSubscription?.endDate, tenantTZ)?.toISO(),
        label:
          form.orderDetail.orderType === OrderType.Amendment
            ? 'Amendment Date'
            : form.orderDetail.orderType === OrderType.Cancel
            ? 'Cancellation Date'
            : undefined,
      }),
      [tenantTZ]
    )
  )
  const isLoading = jotaiForm.useSelect(
    useCallback((form) => {
      return !!form.urqlIsFetching?.DryRunCancellation || !!form.urqlIsFetching?.DryRunAmendment
    }, [])
  )

  const { orderLineItems, currentSubCharges } = jotaiForm.useSelect(
    useCallback(
      (form) => ({
        orderLineItems: form.orderDetail.lineItems,
        currentSubCharges: form.orderDetail.currentSubscription?.charges,
      }),
      []
    )
  )

  const onSubmitAmendmentOrderStartDateChange = (startDate: number) => {
    if (currentSubCharges) {
      const currentSubChargesMap = new Map(currentSubCharges.map((charge) => [charge?.id, charge?.quantity]))
      const updatedLineItems = orderLineItems.map((lineItem) => {
        if (lineItem.isRamp) {
          // If line item end date <= order start date, then reset quantity to current subscription quantity
          if (lineItem.endDate && lineItem.endDate <= startDate) {
            return { ...lineItem, quantity: currentSubChargesMap.get(lineItem.subscriptionChargeId as string) }
            // If line item start date <= order start date and line item end date > order start date, then update line item start date
          } else if (
            lineItem.effectiveDate &&
            lineItem.effectiveDate <= startDate &&
            lineItem.endDate &&
            lineItem.endDate > startDate
          ) {
            return { ...lineItem, effectiveDate: startDate }
          }
        }
        return lineItem
      })
      jotaiForm.set((form) => {
        form.orderDetail.lineItems = updatedLineItems as DeepMutable<GetOrderDetailQuery['orderDetail']['lineItems']>
      })
    }
  }
  const toggleAmendmentOrderStateChangeDialog = useAmendmentOrderStartDateChangeDialog({
    onSubmit: onSubmitAmendmentOrderStartDateChange,
    jotaiForm,
  })

  const isAnyLineItemBeingUpdated = orderLineItems?.some(
    (lineItem) => lineItem?.action === ActionType.Update && lineItem.isRamp
  )

  return label ? (
    <Grid item xs={4}>
      <JotaiMuiDateField
        atomSelector={(form) => form.orderDetail.startDate}
        atomUpdater={(value, draft) => {
          if (value && draft.orderDetail.startDate !== value) {
            if (draft.orderDetail.orderType === OrderType.Amendment && isAnyLineItemBeingUpdated) {
              toggleAmendmentOrderStateChangeDialog(value)
            } else {
              draft.orderDetail.startDate = value
            }
          }
        }}
        errorPath="orderDetail.startDate"
        form={jotaiForm}
        datePickerProps={{
          label,
          minDate,
          maxDate,
        }}
        disabled={isLoading}
      />
    </Grid>
  ) : (
    <></>
  )
}
