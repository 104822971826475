import React, { useCallback, useEffect, useRef } from 'react'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material'
import Image from 'next/image'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'
import { makeStyles } from 'tss-react/mui'
import { AI_SUMMARY_OBJECT, getSummaryApiEndpoint } from '@/util/ai/genie-ai'
import { eventStreamingStatus, useEventStream } from '@/components/hooks/useEventStream'
import { GenieMarkdown } from '@/components/GenieWidget/GenieMarkdown'
import { useSnackbarHandler } from '@/components/SnackbarHandler/SnackbarHandler'
import { useCopyWithFormatting } from '@/components/button/CopyButton'
import ActionButton from '@/components/button/actionButton'
import buildLogger from '@/util/logger'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import { GENIE_AI_PRIMARY_COLOR_ICON } from '@/components/GenieWidget/utils/constants'
import { BetaTag } from '@/components/GenieWidget/ChatHeader'

const logger = buildLogger('OrderSummary')

type DialogProps = WithModalParams & {
  orderId: string | undefined
}

const useStyles = makeStyles()((theme, _params, _classes) => ({
  container: {
    minHeight: '48rem',
    borderRadius: 8,
  },
  content: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  loadingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(16),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  aiCautionInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.customPalette.textGray,
  },
}))

function OrderSummaryDialog({ open = true, onClose, orderId }: DialogProps) {
  const { classes } = useStyles()
  const snackbarHandler = useSnackbarHandler()
  const errorHandler = useErrorHandler()
  const [handleCopy] = useCopyWithFormatting()
  const scrollToBottomRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const {
    data: summaryData,
    error,
    isError,
    isLoading: summaryIsLoading,
    sendRequest,
    streamingStatus,
  } = useEventStream()

  useEffect(() => {
    scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [summaryData])

  useEffect(() => {
    if (orderId && open) {
      const endpoint = getSummaryApiEndpoint(AI_SUMMARY_OBJECT.ORDER, orderId)
      logger.info('Send order summary request')
      const handleAsync = async () => {
        await sendRequest(endpoint)
      }
      handleAsync().catch(errorHandler)
    }
  }, [sendRequest, orderId, open, errorHandler])

  const onClickCopy = () => {
    const htmlContent = contentRef.current?.innerHTML || summaryData
    handleCopy(htmlContent, contentRef.current?.innerText)
    snackbarHandler.successAlert(`Copied to clipboard`)
  }

  const isStreaming = streamingStatus === eventStreamingStatus.ONGOING || summaryIsLoading
  if (orderId) {
    return (
      <Dialog
        key={orderId}
        open={!!open}
        onClose={isStreaming ? undefined : onClose}
        fullWidth
        maxWidth="lg"
        classes={{ paper: classes.container }}
      >
        <DialogTitle className={classes.title}>
          <Image src={GENIE_AI_PRIMARY_COLOR_ICON} width={28} height={28} />
          <Typography variant="h6">Order summary</Typography>
          <BetaTag />
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.content} key={orderId}>
          {summaryData && <GenieMarkdown ref={contentRef} content={summaryData} />}
          {summaryIsLoading && (
            <Box className={classes.loadingIcon}>
              <CircularProgress />
            </Box>
          )}
          {isError && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
          <div ref={scrollToBottomRef}></div>
        </DialogContent>
        <Divider />
        <DialogActions className={classes.actions}>
          <Box className={classes.aiCautionInfo}>
            <ErrorOutlineIcon fontSize="small" />
            AI assistant can make mistakes. Please double check details.
          </Box>
          <Box>
            <ActionButton
              buttonData={{
                buttonProps: { variant: 'outlined' },
                color: 'inherit',
                disabledExplanation: isStreaming ? 'Loading summary overview' : undefined,
                label: 'Done',
                onClick: onClose,
                tooltipPlacement: 'top',
              }}
            />
            <ActionButton
              buttonData={{
                buttonProps: { startIcon: <ContentCopyIcon fontSize="small" /> },
                disabledExplanation: isStreaming ? 'Loading summary overview' : undefined,
                label: 'Copy',
                onClick: onClickCopy,
                tooltipPlacement: 'top',
              }}
            />
          </Box>
        </DialogActions>
      </Dialog>
    )
  } else return null
}

export function useOrderSummaryDialog(modalProps: DialogProps) {
  const [, , toggleModal] = useModalsContext()
  const dialogKey = `orderSummaryDialog${modalProps.orderId}`

  useModal<DialogProps>({
    component: OrderSummaryDialog,
    schema: {
      key: dialogKey,
      modalProps,
    },
  })

  return useCallback(() => {
    toggleModal(dialogKey)
  }, [toggleModal, dialogKey])
}
