import Image from 'next/image'
import { GENIE_BOT_ICON } from './utils/constants'

export const AVATAR_WIDTH = '28px'

// TODO: use billy avatar
export const GenieAvatar = ({ className }: { className?: string }) => {
  return (
    <span style={{ width: AVATAR_WIDTH }}>
      <div
        className={className}
        style={{
          display: 'block',
          position: 'relative',
          width: AVATAR_WIDTH,
          height: AVATAR_WIDTH,
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Image src={GENIE_BOT_ICON} layout="fill" alt="Genie" />
      </div>
    </span>
  )
}
