import { Backdrop, Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PropsWithChildren, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(3),
  },
  content: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    padding: theme.spacing(2),
    backgroundColor: theme.customPalette.backgroundColor1,
    borderRadius: 8,
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(-2),
    top: theme.spacing(-2),
    backgroundColor: theme.customPalette.backgroundColor1,
    borderRadius: '50%',
    padding: theme.spacing(0.25),
    cursor: 'pointer',
  },
}))

export const GenieOverlay = ({ isClosable = false, children }: PropsWithChildren<{ isClosable?: boolean }>) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Backdrop className={classes.backdrop} open={isOpen}>
      <Box className={classes.content}>
        {isClosable && <CloseIcon className={classes.closeIcon} fontSize="small" onClick={() => setIsOpen(false)} />}
        {children}
      </Box>
    </Backdrop>
  )
}
