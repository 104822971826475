import { useEffect, useState } from 'react'
import { useGenieChatContext } from './context/GenieChatContext'
import { GENIE_FALLBACK_ERROR_MESSAGES, GENIE_LOADING_MESSAGES } from './utils/constants'
import { GenieLoader } from './GenieLoader'
import { GenieError } from './GenieError'

export const GenieLoadingAndErrorState = () => {
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const {
    createSessionError,
    createSessionIsError,
    createSessionIsLoading,
    getSessionError,
    getSessionIsError,
    getSessionIsLoading,
    loadMessagesError,
    loadMessagesIsError,
    loadMessagesIsLoading,
  } = useGenieChatContext()

  useEffect(() => {
    const loadingMessage = createSessionIsLoading
      ? GENIE_LOADING_MESSAGES.CREATING_CHAT_SESSION
      : getSessionIsLoading
      ? GENIE_LOADING_MESSAGES.VALIDATING_CHAT_SESSION
      : loadMessagesIsLoading
      ? GENIE_LOADING_MESSAGES.LOADING_CHAT_MESSAGES
      : undefined
    setLoadingMessage(loadingMessage)
  }, [createSessionIsLoading, getSessionIsLoading, loadMessagesIsLoading])

  useEffect(() => {
    const errorMessage = createSessionIsError
      ? createSessionError || GENIE_FALLBACK_ERROR_MESSAGES.CREATE_CHAT_SESSION
      : getSessionIsError
      ? getSessionError || GENIE_FALLBACK_ERROR_MESSAGES.GET_CHAT_SESSION
      : loadMessagesIsError
      ? loadMessagesError || GENIE_FALLBACK_ERROR_MESSAGES.LOAD_CHAT_MESSAGES
      : undefined
    setErrorMessage(errorMessage)
  }, [
    createSessionError,
    createSessionIsError,
    getSessionError,
    getSessionIsError,
    loadMessagesError,
    loadMessagesIsError,
  ])

  if (loadingMessage) return <GenieLoader message={loadingMessage} />
  if (errorMessage) return <GenieError errorMessage={errorMessage} />
  return null
}
