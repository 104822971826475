export function toTitleCase(value: string): string {
  return value
    .toLowerCase()
    .split(/[\s_]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export function toPascalCase(value: string): string {
  return value
    .toLowerCase()
    .split(/[\s_]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
}

export function trimNumber(value: string): string {
  const digitOnly = value.replace(/[^.0-9]*/g, '')
  if (/^0+$/.test(digitOnly)) {
    return '0'
  }
  return (value.includes('-') ? '-' : '') + digitOnly.replace(/^0+/, '')
}

export function trimDecimalPlaces(value: string, dp?: number): string {
  const splitted = value.split('.')
  return splitted.length === 2 ? value.replace(/\.\d+/, '.' + splitted[1].slice(0, dp ?? 5)) : value
}

export function pascalCaseToTitleCase(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1).replace(/([A-Z])/g, ' $1')
}

export async function copyTextAsync(toCopy: string) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(toCopy)
  } else {
    // used when in non secure connection where navigator.clipboard is not available
    const textArea = document.createElement('textarea')
    textArea.value = toCopy

    textArea.style.position = 'absolute'
    textArea.style.opacity = '0'

    document.body.prepend(textArea)
    textArea.select()

    try {
      document.execCommand('copy')
    } finally {
      textArea.remove()
    }
  }
}

export async function copyTextWithFormatting(htmlContent: string, textContent?: string) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([htmlContent], { type: 'text/html' }),
      }),
    ])
  } else if (textContent) {
    await copyTextAsync(textContent)
  }
}
