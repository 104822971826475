import { useCustomizationProps, withCustomizationDefaultValues } from '@/components/state/context/customizationContext'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import isEqual from 'fast-deep-equal/es6/react'
import { useMemo } from 'react'
import { DeepMutable, deepMutable } from '../../../components/SchemaForm/DeepMutable'
import JotaiFormProvider from '../../../components/state/jotaiFormProvider'
import useJotaiForm from '../../../components/state/useJotaiForm'
import {
  DocumentMasterTemplate,
  GetPaymentConfigurationQuery,
  LineItemFragment,
  ListAccountsQuery,
  OrderDetailFragment,
} from '../../../generated/graphql'
import buildLogger from '../../../util/logger'
import { getDefaultOrderDetail } from '../../../util/order'
import { useDryRunError } from '../useDryRunError'
import { CommonOrderFormPageState } from './CommonOrderFormPageState'
import { hasChangedBuilder } from './utils'

export const logger = buildLogger('NewOrderPage')

export type RampIntervalType = 'everyYear' | 'specifiedDate'

export type DocumentMasterTemplateItem = Omit<DocumentMasterTemplate, 'content'>

export interface NewOrderFormData extends CommonOrderFormPageState {
  accountList?: ListAccountsQuery['accounts']
  resellerAccountList?: ListAccountsQuery['accounts']
  dialogLineItemIndex?: number
  rampIntervalType: RampIntervalType
  paymentConfiguration?: GetPaymentConfigurationQuery['paymentConfiguration']
}

const NewOrderPage = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const customizationProps = useCustomizationProps({
    displayName: 'newOrderPage',
    parentLocator: 'order::new::',
  })
  const defaultValueSetters = customizationProps?.defaultValueSetters
  const { queueDryRun } = useDryRunActions()

  const jotaiForm = useJotaiForm<NewOrderFormData>(
    useMemo(() => {
      return deepMutable({
        defaultValue: withCustomizationDefaultValues(defaultValueSetters, {
          approvalSegments: [],
          isLineItemDialogOpen: false,
          isReset: false,
          orderDetail: deepMutable(getDefaultOrderDetail()),
          orderPlans: [],
          orderPlansForTerms: [],
          rampIntervalType: 'everyYear',
          documentMasterTemplates: [],
          paymentConfiguration: undefined,
          hasSalesforceIntegration: false,
          hasHubSpotIntegration: false,
        }),
        onSet: (oldValue, newValue, draft) => {
          const hasChanged = hasChangedBuilder(oldValue, newValue)
          // Calculate the plans which have been added to the order from the line items
          const hasNewLineItems = hasChanged((value) => value.orderDetail.lineItems.length)
          if (hasNewLineItems) {
            draft.orderPlans = deepMutable(newValue.orderDetail.lineItems).reduce((result, lineItem) => {
              const { plan } = lineItem
              if (plan) {
                if (!result.some((resultPlan) => resultPlan?.id === plan.id)) {
                  deepMutable(result).push(deepMutable(plan))
                }
              }
              return result
            }, [] as DeepMutable<NonNullable<LineItemFragment['plan']>[]>)
          }

          if (!newValue.isReset) {
            triggerOrderDetailsDryRunEffects({
              current: newValue.orderDetail,
              prev: oldValue.orderDetail,
              callback: queueDryRun,
            })
          }
        },
      })
    }, [defaultValueSetters, queueDryRun])
  )

  useDryRunError({ jotaiForm })

  return <JotaiFormProvider form={jotaiForm}>{children}</JotaiFormProvider>
}

export default NewOrderPage

//TODO: slowly migrating these to their corresponding components to reduce side effects
export const triggerOrderDetailsDryRunEffects = ({
  current,
  prev,
  callback,
}: {
  current: OrderDetailFragment
  prev: OrderDetailFragment
  callback?: () => void
}) => {
  if (!!current.lineItems?.length && current.startDate && current.termLength?.step !== null && current.account?.id) {
    if (
      !isEqual(prev.predefinedDiscounts, current.predefinedDiscounts) ||
      !isEqual(prev.billingCycle, current.billingCycle) ||
      !isEqual(prev.billingAnchorDate, current.billingAnchorDate)
    ) {
      logger.trace({
        msg: 'triggering triggerOrderDetailsDryRunEffects',
        timestamp: performance.now(),
      })
      callback?.()
    }
  }
}
