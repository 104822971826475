import { Box, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

export const TopBanner = ({
  messages,
  variant = 'info',
}: {
  messages: string[] | undefined
  variant?: 'info' | 'warning' | 'error'
}) => {
  const theme = useTheme()
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [fade, setFade] = useState(true)

  useEffect(() => {
    if (!messages) {
      return
    }

    const interval = setInterval(() => {
      setFade(false)
      setTimeout(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length)
        setFade(true)
      }, 500)
    }, 3000)

    return () => clearInterval(interval)
  }, [messages])

  if (!messages || messages.length === 0) {
    return <></>
  }

  return (
    <Box
      position="sticky"
      sx={{
        backgroundColor: theme.palette[variant].light,
        display: 'flex',
        justifyContent: 'center',
        top: 0,
        paddingX: 2,
      }}
    >
      {messages.length === 1 ? (
        <Typography color="white" variant="body1" paddingY={1}>
          {messages[0]}
        </Typography>
      ) : (
        <Box
          sx={{
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Typography
            color="white"
            variant="body1"
            paddingY={1}
            sx={{
              opacity: fade ? 1 : 0,
              transform: fade ? 'translateY(0)' : 'translateY(-20px)',
              transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {messages[currentMessageIndex]}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
