import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import { GenieResponseLoader } from './GenieResponseLoader'
import { GENIE_BOT_NAME, GENIE_FALLBACK_ERROR_MESSAGES } from './utils/constants'
import { useGenieChatContext } from './context/GenieChatContext'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import { eventStreamingStatus } from '../hooks/useEventStream'

const useStyles = makeStyles()((theme, _params, _classes) => ({
  composer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  form: {
    width: '100%',
    borderRadius: 16,
  },
  helperText: {
    fontSize: 12,
    color: theme.customPalette.textGray,
    paddingLeft: theme.spacing(1),
  },
  sendMessageError: {
    fontSize: 12,
    opacity: 0.9,
  },
}))

export const MessageComposer = () => {
  const { classes } = useStyles()

  const [inputText, setInputText] = useState('')

  const {
    sendMessageError,
    sendMessageIsError,
    sendMessageIsLoading,
    sendMessageStreamingStatus,
    sendUserMessage,
    sessionId,
  } = useGenieChatContext()
  const errorHandler = useErrorHandler()

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputText(ev.target.value)
  }

  const isInputValid = !!inputText.trim().length
  const disableSubmit =
    !sessionId || !isInputValid || sendMessageIsLoading || sendMessageStreamingStatus === eventStreamingStatus.ONGOING

  const onClickSend = () => {
    if (!sessionId || !isInputValid) return

    const handleAsync = async () => {
      const messageText = inputText.trim()
      await sendUserMessage(sessionId, messageText)
      setInputText('')
    }
    handleAsync().catch(errorHandler)
  }

  return (
    <Box className={classes.composer}>
      {sendMessageIsLoading && <GenieResponseLoader />}
      {sendMessageIsError && (
        <Typography className={classes.sendMessageError} color="error">
          {sendMessageError || GENIE_FALLBACK_ERROR_MESSAGES.SEND_CHAT_MESSAGE}
        </Typography>
      )}
      <TextField
        role="textbox"
        aria-label="Genie Input"
        className={classes.form}
        disabled={sendMessageIsLoading || sendMessageStreamingStatus === eventStreamingStatus.ONGOING || !sessionId}
        InputProps={{
          style: {
            borderRadius: 10,
            padding: 10,
            paddingLeft: 16,
            paddingRight: 4,
            fontSize: 14,
          },
          onKeyDown: (e) => {
            if (e.key === 'Enter' && e.shiftKey) {
              if (!disableSubmit) onClickSend()
            }
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                role="button"
                aria-label="send-message"
                color={isInputValid ? 'primary' : 'inherit'}
                disabled={disableSubmit}
                onClick={onClickSend}
              >
                {sendMessageIsLoading ? <CircularProgress size={20} sx={{ margin: 0.25 }} /> : <ArrowCircleUpIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        multiline
        maxRows={4}
        onChange={handleInputChange}
        placeholder={`Message ${GENIE_BOT_NAME} (Press Shift+Enter to send)`}
        value={inputText}
      />
      <Box className={classes.helperText}>{`AI assistant can make mistakes. Please double check responses.`}</Box>
    </Box>
  )
}
