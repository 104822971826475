import { DateTime } from 'luxon'

import { MessageWithStreaming } from './ui-types'
import { GENIE_USER_TYPE } from './constants'

export const getGenieMessage = (
  messageText: string,
  userType: GENIE_USER_TYPE,
  streaming = false
): MessageWithStreaming => {
  return {
    role: userType,
    message: messageText,
    createdAt: DateTime.now().toSeconds(),
    isStreaming: streaming,
  }
}
