import TableEmptyContent from '@/components/table/tableEmptyContent'
import { TableInCardWithSearchDGP } from '@/components/table/tableInCardWithSearchDGP'
import WithDynamicFeatureFlag from '@/components/WithDynamicFeatureToggle/WithDynamicFeatureToggle'
import { Feature } from '@/generated/graphql'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { CardHeader, IconButton, InputAdornment, TextField } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Row } from 'react-table'
import { makeStyles } from 'tss-react/mui'
import { useDebouncedCallback } from 'use-debounce'
import buildLogger from '../../util/logger'
import BillyCard from '../card/billyCard'
import GqlErrorDisplay from '../error/gqlErrorDisplay'
import { useTablePageQueryParam, useTableSearchQueryParam } from '../route/useQueryParam'
import useFuzzySearch, { FuzzySearchProps } from '../search/useFuzzySearch'
import BaseTable, { BaseTableProps, BillyColumn, Pagination } from './baseTable'

const logger = buildLogger('TableInCardWithSearch')

const PAGE_SIZE = 10

const useStyles = makeStyles()((theme, _params, _classes) => ({
  cardHeader: {
    '& .MuiCardHeader-action': {
      marginRight: 0,
      '& .MuiInputBase-root': {
        padding: 0,
        '& input': {
          fontSize: 14,
          fontWeight: 550,
        },
        '& .MuiInputAdornment-root': {
          transition: 'all .3s ease',
          justifyContent: 'center',
          minWidth: 40,
          minHeight: 40,
          margin: 0,
          padding: 0,
          backgroundColor: 'transparent',
        },
      },
    },
  },
}))

export type TableInCardWithSearchProps = Pick<
  BaseTableProps<Record<string, unknown>>,
  'rowActionType' | 'columnSort' | 'onChangeColumnSort'
> & {
  action?: JSX.Element
  columns: ReadonlyArray<BillyColumn>
  hidden?: boolean
  idField: string
  renderEmptyMessage?: string
  select?: FuzzySearchProps['where']
  term?: FuzzySearchProps['term']
  sortField?: FuzzySearchProps['sortField']
  table_name?: FuzzySearchProps['tableName']
  title: string
  urlPath: string
  tenantId?: string
  subHeader?: JSX.Element
}

/* eslint-disable react/jsx-key */
function TableInCardWithSearchLegacy({
  action,
  columns,
  hidden,
  idField,
  renderEmptyMessage,
  select,
  term,
  sortField,
  table_name,
  title,
  subHeader,
  urlPath,
  rowActionType,
  tenantId,
  columnSort,
  onChangeColumnSort,
}: TableInCardWithSearchProps): JSX.Element {
  const { classes } = useStyles()

  const [querySearchString, setQuerySearchString] = useTableSearchQueryParam(idField)
  const [queryPageString, setQueryPageString] = useTablePageQueryParam(idField)
  const [searchString, setSearchString] = useState(querySearchString)

  useEffect(() => {
    setSearchString(querySearchString)
  }, [querySearchString])

  const updateSearchQueryString = useDebouncedCallback((newSearchString: string) => {
    setQuerySearchString(newSearchString)
  }, 300)

  const pageIndex = parseInt(queryPageString) || 0

  const setPageIndex = (newPage: number) => {
    setQueryPageString(newPage ? newPage.toString() : '')
  }

  const [searchHits, searchResult, searchResponse] = useFuzzySearch(
    [
      {
        columns: columns.map((c) => ({ accessor: c.accessor as string, dataType: c.dataType })),
        pageIndex,
        pageSize: PAGE_SIZE,
        querySearchString,
        sortField,
        tableName: table_name,
        where: select,
        term: term,
      },
    ],
    false,
    tenantId
  )

  const searchError = searchResult.error

  const handleSearchChange = useCallback(
    (searchString: string) => {
      setSearchString(searchString)
      updateSearchQueryString(searchString)
    },
    [updateSearchQueryString]
  )

  const ref = useRef<HTMLInputElement>(null)

  const searchBox = (
    <TextField
      inputRef={ref}
      value={searchString}
      onChange={(event) => handleSearchChange(event.target.value)}
      placeholder="Search"
      size="small"
      name="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton size="small">
              <SearchIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={useCallback(() => {
              handleSearchChange('')
            }, [])}
            style={{ visibility: searchString ? 'visible' : 'hidden' }}
          >
            <IconButton size="small">
              <CloseIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )

  function getLinkTarget(row: Row<any>) {
    const id = row.original[idField]
    return urlPath.replace(':id', id)
  }

  const total = searchResponse?.hits.total as any
  const totalRows = total?.value || 0
  const pagination: Pagination = useMemo(() => {
    return {
      onPageIndexChange: (pageIndex) => {
        setPageIndex(pageIndex)
      },
      pageIndex,
      totalRows,
    }
  }, [totalRows])

  return (
    <BillyCard hidden={hidden}>
      <CardHeader title={title} action={action ?? searchBox} className={classes.cardHeader} subheader={subHeader} />
      <GqlErrorDisplay error={searchError} />
      <BaseTable
        columns={columns.map((column) => ({ ...column, hidden: column.hidden || column.defaultInvisible }))}
        data={searchHits}
        isLoading={searchResult.fetching}
        getLinkTarget={getLinkTarget}
        renderEmpty={() => <TableEmptyContent message={renderEmptyMessage} />}
        rowId={idField}
        pageSize={PAGE_SIZE}
        pagination={pagination}
        rowActionType={rowActionType}
        columnSort={columnSort}
        onChangeColumnSort={onChangeColumnSort}
      />
    </BillyCard>
  )
}

function TableInCardWithSearch(props: TableInCardWithSearchProps): JSX.Element {
  return (
    <>
      <WithDynamicFeatureFlag feature={Feature.UiEsTableOrder} status="enabled">
        <TableInCardWithSearchDGP
          {...props}
          defaultSortField={typeof props.sortField === 'string' ? props.sortField : props.columnSort?.key}
        />
      </WithDynamicFeatureFlag>
      <WithDynamicFeatureFlag feature={Feature.UiEsTableOrder} status="disabled">
        <TableInCardWithSearchLegacy {...props} />
      </WithDynamicFeatureFlag>
    </>
  )
}

export default TableInCardWithSearch
