import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import { useDryRunActions } from '@/pageComponents/orders/EditOrderPage/context/DryRunActionsContext'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useErrorHandler } from '../../../components/ErrorHandler/ErrorHandler'
import ActionButton from '../../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../../components/state/context/modalsContext'
import useJotaiForm, { JotaiForm } from '../../../components/state/useJotaiForm'
import useJotaiOnSubmit from '../../../components/state/useJotaiOnSubmit'
import { WithUrql } from '../../../components/state/useJotaiUrqlQuery'
import buildLogger from '../../../util/logger'
import { LineItemsTableOrderType } from '../LineItemsEditTable/LineItemsEditTable'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { LineItemDiscountDialogContent } from './LineItemDiscountDialogContent'
import { Discount } from '@/generated/graphql'
import { DeepMutable } from '@/components/SchemaForm/DeepMutable'

const logger = buildLogger('LineItemDiscountDialog')

export type LineItemDiscountDialogState = Pick<NewOrderFormData, 'orderDetail'> & WithUrql
export type LineItemDiscountDialogProps<F> = {
  jotaiForm: JotaiForm<F>
  orderType: LineItemsTableOrderType
  lineItemIndex: number
  discountPercent?: number
}

type DialogProps<F> = WithModalParams & LineItemDiscountDialogProps<F>

const formID = 'LineItemDiscountDialog'
const LineItemDiscountDialog = <F extends LineItemDiscountDialogState>({
  open,
  onClose,
  onSubmit,
  jotaiForm,
  orderType,
  lineItemIndex,
  discountPercent,
}: DialogProps<F>): JSX.Element => {
  const errorHandler = useErrorHandler()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [tempDiscountPercent, setTempDiscountPercent] = useState(discountPercent ?? 0)

  useEffect(() => {
    if (open) {
      setTempDiscountPercent(discountPercent ?? 0)
    }
  }, [open, discountPercent])

  function handleSubmit() {
    jotaiForm.set((form) => {
      const lineItem = form.orderDetail.lineItems?.[lineItemIndex]
      const discount = lineItem?.discounts?.[0] as DeepMutable<Discount>
      if (discount?.percent !== undefined) {
        discount.percent = tempDiscountPercent
      } else {
        lineItem.discounts = [{ percent: tempDiscountPercent, name: 'default', discountAmount: null }]
      }
    })
    async function doAsync() {
      setIsSubmitting(true)
      await onSubmit?.()
      setIsSubmitting(false)
      onClose?.()
    }
    doAsync().catch((error) => {
      setIsSubmitting(false)
      errorHandler(error)
    })
  }

  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'}>
      <DialogTitle>Charge Discounts</DialogTitle>
      <DialogContent dividers={true}>
        <LineItemDiscountDialogContent
          jotaiForm={jotaiForm}
          orderType={orderType}
          lineItemIndex={lineItemIndex}
          tempDiscountPercent={tempDiscountPercent}
          setTempDiscountPercent={setTempDiscountPercent}
        />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          buttonData={{
            label: 'Apply',
            color: 'primary',
            loading: isSubmitting,
            onClick: handleSubmit,
            disabledExplanation:
              discountPercent && discountPercent < 0
                ? 'Expected Sell Amount cannot be below the total applied predefined discounts'
                : '',
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export const useLineItemDiscountDialog = <F extends LineItemDiscountDialogState>({
  orderType,
}: {
  orderType: LineItemsTableOrderType
}) => {
  const [, , toggleModal] = useModalsContext()
  const [lineItemIndex, setLineItemIndex] = useState<number>(0)

  const orderJotaiForm = useJotaiFormContext<F>()
  const orderDetail = orderJotaiForm.useSelect(useCallback((form) => form.orderDetail, []))

  const { queueDryRun } = useDryRunActions()

  const discountDraftForm = useJotaiForm<LineItemDiscountDialogState>(
    useMemo(
      () => ({
        defaultValue: {
          orderDetail,
        },
      }),
      [orderDetail]
    )
  )

  const onSaveDiscount = useJotaiOnSubmit(
    useMemo(
      () => ({
        atom: discountDraftForm.atom,
        onSubmit: (value: LineItemDiscountDialogState) => {
          orderJotaiForm.set((form) => {
            form.orderDetail.lineItems = value.orderDetail.lineItems
          })
          queueDryRun()
        },
      }),
      [discountDraftForm.atom, orderJotaiForm, queueDryRun]
    )
  )
  const discountPercent = useMemo(
    () => orderDetail?.lineItems?.[lineItemIndex]?.discounts?.[0]?.percent ?? 0,
    [lineItemIndex, orderDetail]
  )

  useModal<DialogProps<LineItemDiscountDialogState>>({
    component: LineItemDiscountDialog,
    schema: {
      key: formID + '-' + lineItemIndex,
      modalProps: {
        jotaiForm: discountDraftForm,
        orderType,
        onSubmit: onSaveDiscount,
        lineItemIndex,
        discountPercent,
      },
    },
  })

  const toggleCurrentModal = useCallback(
    (index: number) => {
      toggleModal(formID + '-' + index)
    },
    [toggleModal]
  )

  return [toggleCurrentModal, setLineItemIndex] as [typeof toggleCurrentModal, typeof setLineItemIndex]
}
