import { CardContent, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useMemo, useRef } from 'react'
import { makeStyles } from 'tss-react/mui'

import { useUserTenantSession } from '../UserTenantSessionProvider/UserTenantSessionContext'
import { ChatMessage } from './ChatMessage'
import { GenieAvatar } from './GenieAvatar'
import { useGenieChatContext } from './context/GenieChatContext'

const useStyles = makeStyles()((theme, _params, _classes) => ({
  messagesList: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  emptyChatInfo: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: theme.spacing(1),
    color: theme.customPalette.textGray,
  },
}))

export const ListChatMessages = () => {
  const { classes } = useStyles()
  const userTenantSession = useUserTenantSession()
  const { messages } = useGenieChatContext()
  const scrollToBottomRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const messageList = useMemo(() => {
    return messages?.sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0))
  }, [messages])

  return (
    <CardContent className={classes.messagesList}>
      {messageList?.length === 0 ? (
        <Box className={classes.emptyChatInfo}>
          <GenieAvatar />
          <Typography variant="body2">Hi {userTenantSession?.currentUser?.displayName}, how can I help?</Typography>
        </Box>
      ) : (
        <>
          <div ref={scrollToBottomRef}></div>
          {messageList?.map((message) => {
            return <ChatMessage key={message.createdAt} message={message} />
          })}
        </>
      )}
    </CardContent>
  )
}
