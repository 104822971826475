import clsx from 'clsx'
import { ForwardedRef, forwardRef } from 'react'
import ReactMarkdown, { Options } from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme, _params, _classes) => ({
  markdownBody: {
    backgroundColor: 'rgb(255, 255, 255) !important',
    color: 'rgb(31, 35, 40) !important',
    '& pre, table tr': {
      backgroundColor: 'rgb(255, 255, 255) !important',
      color: 'rgb(31, 35, 40) !important',
    },
    '& table tr:nth-child(2n)': {
      backgroundColor: 'rgb(246, 248, 250)',
    },
    '& table th, table td': {
      border: '1px solid rgb(209, 217, 224)',
    },
  },
  markdownBodyContent: {
    paddingBottom: 1, // fixes margin collapse, when the last child has a margin-bottom
    '>*:last-child': {
      marginBottom: '0 !important',
    },
  },
}))

export interface GenieMarkdownProps {
  className?: string
  content: string
  components?: Options['components']
}

export const GenieMarkdown = forwardRef(function GenieMarkdown(
  { className, components, content }: GenieMarkdownProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { classes } = useStyles()

  return (
    <div ref={ref} className={clsx('markdown-body', classes.markdownBody)}>
      <ReactMarkdown
        className={clsx(classes.markdownBodyContent, className)}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={components}
      >
        {content}
      </ReactMarkdown>
    </div>
  )
})
