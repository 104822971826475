import { useJotaiFormContext } from '@/components/state/jotaiFormProvider'
import PersonIcon from '@mui/icons-material/Person'
import { Draft } from 'immer'
import React, { useCallback } from 'react'
import JotaiMuiEsAutocomplete from '../../components/input/JotaiMuiEsAutocomplete'
import buildLogger from '../../util/logger'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'
import { Grid } from '@mui/material'

const logger = buildLogger('OrderOwnerEsSelect')

const OrderOwnerEsSelectWithoutMemo = <
  F extends Pick<CommonOrderFormPageState, 'approvalSegments' | 'orderDetail' | 'users'>
>(): JSX.Element => {
  const jotaiForm = useJotaiFormContext<F>()
  return (
    <Grid item xs={4}>
      <JotaiMuiEsAutocomplete
        atomOptionsSelector={useCallback((form: F) => form.users, [])}
        atomUpdater={useCallback((value, draft) => {
          draft.orderDetail.owner = value
          draft.orderDetail.approvalSegment = undefined
          draft.approvalSegments = []
        }, [])}
        atomValueSelector={useCallback((form) => form.orderDetail.owner, [])}
        autocompleteProps={{
          getOptionLabel: (option) => option.displayName ?? '',
          renderOption: function renderOption(props, option) {
            return (
              <li {...props} key={option.id}>
                <PersonIcon />
                {option.displayName}
              </li>
            )
          },
        }}
        errorPath="orderDetail.owner"
        form={jotaiForm}
        textFieldProps={{
          label: 'Order Owner',
          name: 'members',
        }}
        fuzzySearchProps={[
          {
            columns: [{ accessor: 'display_name' }],
            fillWithNonMatchingResults: true,
            tableName: 'tenant_user',
            where: [{ key: 'state', value: 'ACTIVE' }],
          },
        ]}
        onSearchUpdate={(values, draft: Draft<F>) => {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          draft.users = values.map((searchResult: any) => {
            return {
              id: searchResult.user_id,
              displayName: searchResult.display_name,
              state: searchResult.state,
            }
          })
        }}
      />
    </Grid>
  )
}

export const OrderOwnerEsSelect = React.memo(OrderOwnerEsSelectWithoutMemo)
