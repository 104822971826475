import { useMemo } from 'react'

import { GENIE_VIEW_MODE } from './utils/constants'

export const useGenieViewMode = () => {
  return useMemo(() => {
    const setGenieMode = (mode: GENIE_VIEW_MODE) => {
      window.localStorage.setItem('genie-view-mode', mode)
    }
    const genieMode = window.localStorage.getItem('genie-view-mode') as GENIE_VIEW_MODE
    return {
      genieMode,
      setGenieMode,
    }
  }, [])
}
