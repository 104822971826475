import { useCallback, useState } from 'react'
import { billyRestClient, isApiError } from '@/components/data/billyRestClient'

export const useGetChatSession = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const getChatSession = useCallback(async (sessionId: string) => {
    try {
      setIsLoading(true)
      setIsError(false)
      setError(undefined)
      const resp = await billyRestClient.ai.getAgentSession(sessionId)
      return resp.status === 200
    } catch (err) {
      setIsError(true)
      if (isApiError(err)) {
        setError(err.response?.data?.message)
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    error,
    isError,
    isLoading,
    getChatSession,
  }
}
