import CloseIcon from '@mui/icons-material/Close'
import { Tooltip } from '@mui/material'
import Fab from '@mui/material/Fab'
import Image from 'next/image'

import { GENIE_AI_ICON, GENIE_BOT_NAME } from './utils/constants'
import { useEntitySession } from '../Entity/UseEntitySession'

interface GenieLauncherProps {
  isWidgetOpen: boolean
  setIsWidgetOpen: (status: boolean) => void
}

export const GenieLauncher = ({ isWidgetOpen, setIsWidgetOpen }: GenieLauncherProps) => {
  const entityContext = useEntitySession()

  const onClickWidgetIcon = () => {
    if (entityContext.hasFullSelection) {
      console.info('Please select an entity from top nav.')
    } else {
      setIsWidgetOpen(!isWidgetOpen)
    }
  }

  const tooltip = entityContext.hasFullSelection ? 'Please select an entity to continue' : GENIE_BOT_NAME
  const iconButton = (
    <Fab role="button" color="primary" aria-label="genie-launcher" onClick={onClickWidgetIcon}>
      {isWidgetOpen ? <CloseIcon /> : <Image src={GENIE_AI_ICON} width={28} height={28} />}
    </Fab>
  )
  return isWidgetOpen ? (
    iconButton
  ) : (
    <Tooltip title={tooltip} placement="top">
      {iconButton}
    </Tooltip>
  )
}
