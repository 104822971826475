import { useCallback, useState } from 'react'
import { getSessionIdFromLocationHeader } from '../utils/session-helper'
import { billyRestClient, isApiError } from '@/components/data/billyRestClient'

export const useCreateNewSession = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState<string | undefined>()

  const createSession = useCallback(async (): Promise<string | undefined> => {
    try {
      setIsLoading(true)
      setIsError(false)
      setError(undefined)
      const resp = await billyRestClient.ai.createAgentSession()
      return getSessionIdFromLocationHeader(resp.headers?.location)
    } catch (err) {
      setIsError(true)
      if (isApiError(err)) {
        setError(err.response?.data?.message)
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  return {
    createSession,
    error,
    isError,
    isLoading,
  }
}
