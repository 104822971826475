import { LOCALHOST_ADDRESS, SUBSKRIBE_COM_DOMAIN, SUBSKRIBE_NET_DOMAIN } from '@/util/url'

export const getPathFromGenieUrl = (url: string) => {
  let splitArray: string[]
  if (url?.includes(LOCALHOST_ADDRESS)) splitArray = url?.split(LOCALHOST_ADDRESS)
  else if (url?.includes(SUBSKRIBE_COM_DOMAIN)) splitArray = url?.split(SUBSKRIBE_COM_DOMAIN)
  else splitArray = url?.split(SUBSKRIBE_NET_DOMAIN)

  const path = splitArray?.pop() as string
  return path
}
