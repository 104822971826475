import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useCreateNewSession } from '../hooks/useCreateNewSession'
import { GENIE_USER_TYPE } from '../utils/constants'
import { useLoadChatMessages } from '../hooks/useLoadChatMessages'
import { useErrorHandler } from '@/components/ErrorHandler/ErrorHandler'
import { MessageWithStreaming } from '../utils/ui-types'
import { getGenieMessage } from '../utils/message-helper'
import { useGetChatSession } from '../hooks/useGetChatSession'
import { useSavedEntitySessionId } from '../hooks/useSavedEntitySessionId'
import { useEntitySession } from '@/components/Entity/UseEntitySession'
import { eventStreamingStatus, eventStreamingStatusType, useEventStream } from '@/components/hooks/useEventStream'
import { getChatMessageApiEndpoint } from '@/util/ai/genie-ai'
import buildLogger from '@/util/logger'

const logger = buildLogger('GenieChatContext')

type GenieChatContextType = {
  addMessage: (message: string, userType: GENIE_USER_TYPE) => void
  createNewSession: () => void
  createSessionError: string | undefined
  createSessionIsError: boolean
  createSessionIsLoading: boolean
  getSessionError: string | undefined
  getSessionIsError: boolean
  getSessionIsLoading: boolean
  loadMessagesError: string | undefined
  loadMessagesIsError: boolean
  loadMessagesIsLoading: boolean
  messages: MessageWithStreaming[]
  sendUserMessage: (sessionId: string, messageText: string) => Promise<void> | void
  sendMessageError: string | undefined
  sendMessageIsError: boolean
  sendMessageIsLoading: boolean
  sendMessageStreamingStatus: eventStreamingStatusType
  sessionId: string | undefined
  streamingMessageText: string
}

const GenieChatContext = createContext<GenieChatContextType>({
  addMessage: () => {
    logger.warn('addMessage not initialized')
  },
  createNewSession: () => {
    logger.warn('createNewSession not initialized')
  },
  createSessionError: undefined,
  createSessionIsError: false,
  createSessionIsLoading: false,
  getSessionError: undefined,
  getSessionIsError: false,
  getSessionIsLoading: false,
  loadMessagesError: undefined,
  loadMessagesIsError: false,
  loadMessagesIsLoading: false,
  messages: [],
  sendUserMessage: () => {
    logger.warn('sendUserMessage not initialized')
  },
  sendMessageError: undefined,
  sendMessageIsError: false,
  sendMessageIsLoading: false,
  sendMessageStreamingStatus: null,
  sessionId: undefined,
  streamingMessageText: '',
})

export function useGenieChatContext() {
  return useContext(GenieChatContext)
}

export const GenieChatProvider = ({ children }: PropsWithChildren) => {
  const [sessionId, setSessionId] = useState<string | undefined>()
  const [messages, setMessages] = useState<MessageWithStreaming[]>([])

  const {
    createSession,
    error: createSessionError,
    isError: createSessionIsError,
    isLoading: createSessionIsLoading,
  } = useCreateNewSession()
  const {
    getChatSession,
    error: getSessionError,
    isError: getSessionIsError,
    isLoading: getSessionIsLoading,
  } = useGetChatSession()
  const {
    loadChatMessages,
    error: loadMessagesError,
    isError: loadMessagesIsError,
    isLoading: loadMessagesIsLoading,
  } = useLoadChatMessages()
  const {
    data: streamingMessageText,
    error: sendMessageError,
    isError: sendMessageIsError,
    isLoading: sendMessageIsLoading,
    sendRequest,
    streamingStatus: sendMessageStreamingStatus,
  } = useEventStream()
  const errorHandler = useErrorHandler()

  const entityContext = useEntitySession()
  const entityId = entityContext.hasFullSelection ? 'allSelected' : entityContext.selectedEntities?.[0]?.id
  const [storedSessionId, setLocalStorageItem] = useSavedEntitySessionId(entityId)

  // Save sessionId to local storage whenever it changes
  useEffect(() => {
    if (sessionId) {
      setLocalStorageItem(sessionId)
    }
  }, [sessionId, setLocalStorageItem])

  const createNewSession = useCallback(() => {
    const handleAsync = async () => {
      const newSessionId = await createSession()
      if (newSessionId) {
        setSessionId(newSessionId)
        setMessages([]) // Reset messages for the new session
      }
    }
    handleAsync().catch((error) => errorHandler(error))
  }, [createSession, errorHandler])

  const loadSessionMessages = useCallback(
    (sessionId) => {
      const handleAsync = async () => {
        if (sessionId) {
          const messages = await loadChatMessages(sessionId)
          if (messages) {
            setMessages(messages)
          }
        }
      }
      handleAsync().catch((error) => errorHandler(error))
    },
    [loadChatMessages, errorHandler]
  )

  const validateChatSession = useCallback(
    (sessionId: string) => {
      const handleAsync = async () => {
        const isValid = await getChatSession(sessionId)
        if (isValid) {
          setSessionId(sessionId)
          loadSessionMessages(sessionId)
        }
      }
      handleAsync().catch((error) => errorHandler(error))
    },
    [getChatSession, loadSessionMessages, errorHandler]
  )

  // Fetch sessionId from local storage when component mounts
  useEffect(() => {
    if (storedSessionId) {
      validateChatSession(storedSessionId)
    } else {
      createNewSession() // If no sessionId, create a new session
    }
  }, [storedSessionId, createNewSession, validateChatSession])

  const addMessage = useCallback((message: string, userType: GENIE_USER_TYPE, streaming = false) => {
    const messageObj = getGenieMessage(message, userType, streaming)
    setMessages((prevMessages) => [...prevMessages, messageObj])
  }, [])

  useEffect(() => {
    if (sendMessageStreamingStatus === eventStreamingStatus.COMPLETED) {
      const updatedMessageList = messages.map((chatMessage) =>
        chatMessage.isStreaming
          ? {
              ...chatMessage,
              isStreaming: false,
              message: streamingMessageText,
            }
          : chatMessage
      )
      setMessages(updatedMessageList)
    }

    if (sendMessageStreamingStatus === eventStreamingStatus.ONGOING) {
      const streamingMessages = messages.filter((message) => message.isStreaming)
      if (streamingMessages.length === 0) {
        addMessage('', GENIE_USER_TYPE.ASSISTANT, true) // Add assistant message in context state
      }
    }
    // messages and streamingMessageText intentionally left out of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageStreamingStatus, addMessage])

  const sendUserMessage = useCallback(
    async (sessionId: string, messageText: string) => {
      addMessage(messageText, GENIE_USER_TYPE.USER) // Update user message to context
      const endpoint = getChatMessageApiEndpoint(sessionId, messageText)
      logger.info('Send chat message')
      await sendRequest(endpoint)
    },
    [sendRequest, addMessage]
  )

  const value = useMemo(
    () => ({
      addMessage,
      createNewSession,
      createSessionError,
      createSessionIsError,
      createSessionIsLoading,
      getSessionError,
      getSessionIsError,
      getSessionIsLoading,
      loadMessagesError,
      loadMessagesIsError,
      loadMessagesIsLoading,
      messages,
      sendUserMessage,
      sendMessageError,
      sendMessageIsError,
      sendMessageIsLoading,
      sendMessageStreamingStatus,
      sessionId,
      streamingMessageText,
    }),
    [
      addMessage,
      createNewSession,
      createSessionError,
      createSessionIsError,
      createSessionIsLoading,
      getSessionError,
      getSessionIsError,
      getSessionIsLoading,
      loadMessagesError,
      loadMessagesIsError,
      loadMessagesIsLoading,
      messages,
      sendUserMessage,
      sendMessageError,
      sendMessageIsError,
      sendMessageIsLoading,
      sendMessageStreamingStatus,
      sessionId,
      streamingMessageText,
    ]
  )

  return <GenieChatContext.Provider value={value}>{children}</GenieChatContext.Provider>
}
