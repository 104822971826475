import { Box, Grow, Typography } from '@mui/material'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'

import { AVATAR_WIDTH, GenieAvatar } from './GenieAvatar'
import { GENIE_USER_TYPE } from './utils/constants'
import { MessageWithStreaming } from './utils/ui-types'
import { getPathFromGenieUrl } from './utils/path-helper'
import { useBillyRouter } from '../route/useBillyRouter'
import { useGenieChatContext } from './context/GenieChatContext'
import { GenieMarkdown } from './GenieMarkdown'

const selfMessageBgColor = 'rgb(229 231 235)'
const otherMessageBgColor = 'rgb(191 219 254)'
const useStyles = makeStyles<{
  isUserMessage: boolean
}>()((theme, { isUserMessage }, _classes) => ({
  messageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '92%',
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(2),
  },
  bubble: {
    position: 'relative',
    maxWidth: `calc(100% - ${AVATAR_WIDTH})`,
    borderRadius: 12,
    fontWeight: 'normal',
  },
  scrollableBubbleContent: {
    display: 'flex',
    maxWidth: '100%',
    overflowX: 'auto',
    padding: theme.spacing(2),
  },
  selfMessage: {
    backgroundColor: selfMessageBgColor,
    borderBottomRightRadius: 0,
    '&:after': {
      content: '" "',
      position: 'absolute',
      width: 0,
      height: 0,
      top: 'auto',
      right: theme.spacing(-1),
      bottom: 0,
      left: 'auto',
      border: '16px solid',
      borderColor: `transparent transparent ${selfMessageBgColor} transparent`,
    },
  },
  otherMessages: {
    backgroundColor: otherMessageBgColor,
    borderTopLeftRadius: 0,
    '&:after': {
      content: '" "',
      position: 'absolute',
      width: 0,
      height: 0,
      left: theme.spacing(-1),
      right: 'auto',
      top: 0,
      bottom: 'auto',
      border: '16px solid',
      borderColor: `${otherMessageBgColor} transparent transparent transparent`,
    },
  },
  justifyLeft: {
    alignSelf: 'start',
    justifyContent: 'flex-start',
  },
  justifyRight: {
    alignSelf: 'end',
    justifyContent: 'flex-end',
  },
  markdown: {
    backgroundColor: isUserMessage ? selfMessageBgColor : otherMessageBgColor,
  },
  link: {
    cursor: 'pointer',
  },
}))

interface ChatMessageProps {
  message: MessageWithStreaming
}

export const ChatMessage = ({ message }: ChatMessageProps) => {
  const isUserMessage = message.role === GENIE_USER_TYPE.USER
  const { classes } = useStyles({ isUserMessage })
  const router = useBillyRouter()
  const { streamingMessageText } = useGenieChatContext()

  const onClickLink = (href: string | undefined) => {
    if (!href) return
    const path = getPathFromGenieUrl(href)
    router.push(path)
  }

  return (
    <Grow in={true} timeout={300}>
      <Box
        className={clsx(classes.messageContainer, isUserMessage ? classes.justifyRight : classes.justifyLeft)}
        role="listitem"
        aria-label="genie-message"
      >
        {!isUserMessage && <GenieAvatar />}
        <Box className={clsx(classes.bubble, isUserMessage ? classes.selfMessage : classes.otherMessages)}>
          <Box className={classes.scrollableBubbleContent}>
            <GenieMarkdown
              className={classes.markdown}
              content={message.isStreaming ? streamingMessageText : message.message || ''}
              components={{
                a(props) {
                  const { href, children } = props
                  return (
                    <Typography
                      className={classes.link}
                      variant="body2"
                      color={href ? 'primary' : 'inherit'}
                      onClick={() => onClickLink(href)}
                    >
                      {children}
                    </Typography>
                  )
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Grow>
  )
}
