import { Card } from '@/components/ui/card'
import { Box, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useCallback, useState } from 'react'

import { ChatHeader } from './ChatHeader'
import { GENIE_VIEW_MODE } from './utils/constants'
import { ListChatMessages } from './ListChatMessages'
import { MessageComposer } from './MessageComposer'
import { useGenieViewMode } from './useGenieViewMode'
import { GenieLoadingAndErrorState } from './GenieLoadingAndErrorState'

// These dimension are yet to be finalized based on team's feedback
const regularViewModeDimensions = {
  width: 450,
  height: 650,
}
const fullViewModeDimensions = {
  width: 1050,
  height: 750,
}

interface MakeStylesProps {
  genieMode: GENIE_VIEW_MODE
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    boxShadow: theme.shadows[8],
    animation: `$smoothAppear 0.3s ease-in-out`,
  },
  containerModeRegular: {
    width: regularViewModeDimensions.width,
    height: regularViewModeDimensions.height,
  },
  containerModeFull: {
    width: fullViewModeDimensions.width,
    height: fullViewModeDimensions.height,
  },
  '@keyframes smoothAppear': {
    from: {
      width: 0,
      height: 0,
      opacity: 0,
    },
    to: {
      width: (props: MakeStylesProps) =>
        props.genieMode === GENIE_VIEW_MODE.REGULAR_VIEW
          ? regularViewModeDimensions.width
          : fullViewModeDimensions.width,
      height: (props: MakeStylesProps) =>
        props.genieMode === GENIE_VIEW_MODE.REGULAR_VIEW
          ? regularViewModeDimensions.height
          : fullViewModeDimensions.height,
      opacity: 1,
    },
  },
}))

export const GenieWidget = () => {
  const { genieMode, setGenieMode } = useGenieViewMode()
  const mode = genieMode || GENIE_VIEW_MODE.FULL_VIEW // full-view is default view

  const classes = useStyles({ genieMode: mode })
  const [viewMode, setViewMode] = useState(mode)
  const isRegularViewMode = viewMode === GENIE_VIEW_MODE.REGULAR_VIEW

  const onClickSwitchViewMode = useCallback(() => {
    const mode = isRegularViewMode ? GENIE_VIEW_MODE.FULL_VIEW : GENIE_VIEW_MODE.REGULAR_VIEW
    setViewMode(mode)
    setGenieMode(mode)
  }, [isRegularViewMode, setGenieMode])

  return (
    <Box position="relative">
      <Card
        className={clsx(
          classes.container,
          isRegularViewMode ? classes.containerModeRegular : classes.containerModeFull
        )}
      >
        <ChatHeader viewMode={viewMode} onClickSwitchViewMode={onClickSwitchViewMode} />
        <GenieLoadingAndErrorState />
        <ListChatMessages />
        <MessageComposer />
      </Card>
    </Box>
  )
}
