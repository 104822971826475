import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import ActionButton from '../../components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '../../components/state/context/modalsContext'
import { JotaiForm } from '@/components/state/useJotaiForm'
import { CommonOrderFormPageState } from './EditOrderPage/CommonOrderFormPageState'

type AmendmentOrderStartDateChangeDialogProps = {
  jotaiForm: JotaiForm<CommonOrderFormPageState>
}

type DialogProps = WithModalParams & AmendmentOrderStartDateChangeDialogProps
const formID = 'AmendmentOrderStartDateChangeDialog'
function AmendmentOrderStartDateChangeDialog({ open, onClose, onSubmit }: DialogProps): JSX.Element {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Update Start Date</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Changing the order start date will reset any changes made to the order lines with ramp intervals. Are you sure
          you want to continue?
        </Typography>
      </DialogContent>
      <DialogActions>
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            buttonProps: { variant: 'outlined' },
          }}
        />
        <ActionButton
          buttonData={{
            label: 'Continue',
            onClick: () => {
              onSubmit?.()
              onClose?.()
            },
            color: 'primary',
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useAmendmentOrderStartDateChangeDialog(
  modalProps: DialogProps = { jotaiForm: {} as JotaiForm<CommonOrderFormPageState> }
) {
  const [, , toggleModal] = useModalsContext()
  const [startDate, setStartDate] = useState<number>()

  useModal<DialogProps>({
    component: AmendmentOrderStartDateChangeDialog,
    schema: {
      key: formID,
      modalProps: {
        ...modalProps,
        onSubmit: () => {
          modalProps.onSubmit?.(startDate)
          if (startDate) {
            modalProps.jotaiForm.set((form) => {
              form.orderDetail.startDate = startDate
            })
          }
        },
      },
    },
  })
  return useCallback(
    (value: number) => {
      toggleModal(formID)
      setStartDate(value)
    },
    [toggleModal]
  )
}
