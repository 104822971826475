import { Grid, Box } from '@mui/material'
import TableInCardWithSearch from '@/components/table/tableInCardWithSearch'
import { TenantJobType } from '@/generated/graphql'
import BillyLink from '@/components/link/billyLink'
import { BillyColumn } from '@/components/table/baseTable'
import StatusChip from '@/components/table/cells/statusChip'
import { useMemo } from 'react'

type ObjectJobViewTableProps = {
  title: string
  objectId: string
  searchType: string
  jobTypes: TenantJobType[]
}

export function ObjectJobViewTable(props: ObjectJobViewTableProps) {
  const { title, objectId, searchType, jobTypes } = props
  const jobColumns: ReadonlyArray<BillyColumn> = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'job_id',
        size: 'shrink',
      },
      {
        Header: 'Job',
        accessor: 'job_type',
        dataType: 'enum',
      },
      {
        Header: 'Source',
        accessor: 'object_id',
        size: 'shrink',
        hidden: true,
      },
      {
        Header: 'Created',
        accessor: 'created_on',
        dataType: 'datetime',
        size: 'shrink',
        sort: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: StatusChip,
        size: 'shrink',
      },
    ],
    []
  )

  return (
    <Grid item>
      <TableInCardWithSearch
        title={title}
        table_name="tenant_job_queue"
        idField="job_id"
        columns={jobColumns}
        select={[
          { key: 'object_id', value: objectId },
          {
            key: 'job_type',
            value: {
              query: jobTypes.join(' '),
              operator: 'or',
            },
          },
        ]}
        urlPath={'/settings/jobs/:id'}
        sortField="created_on"
        subHeader={
          <Box sx={{ display: 'flex', gap: '4px' }}>
            To see all {searchType} tasks{' '}
            <BillyLink nextProps={{ href: `/settings/jobs/module/all?job_id_search=${searchType}` }}>
              click here
            </BillyLink>
          </Box>
        }
      />
    </Grid>
  )
}
