import { Grid, Theme } from '@mui/material'
import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import { GenieChatProvider } from '../GenieWidget/context/GenieChatContext'
import { GenieWidget } from '../GenieWidget/GenieWidget'
import { GenieLauncher } from '../GenieWidget/GenieLauncher'
import WithDynamicFeatureFlag from '../WithDynamicFeatureToggle/WithDynamicFeatureToggle'
import { Feature } from '@/generated/graphql'
import BillyErrorBoundary from '../BillyErrorBoundary/BillyErrorBoundary'
import { MinimizeIconWrapper } from '../GenieWidget/MinimizeIconWrapper'

const useStyles = makeStyles<{ isGenieIconMinimized: boolean }>()((theme: Theme, { isGenieIconMinimized }) => ({
  container: {
    position: 'fixed',
    bottom: isGenieIconMinimized ? theme.spacing(-4) : theme.spacing(3),
    right: theme.spacing(3),
    zIndex: theme.zIndex.drawer,
    width: 'max-content',
    gap: theme.spacing(1),
    transition: 'bottom 0.3s ease-in-out',
  },
}))

export const GenieWidgetContainer = () => {
  const [isGenieIconMinimized, setIsGenieIconMinimized] = useState(false)
  const { classes } = useStyles({ isGenieIconMinimized })

  const [isWidgetOpen, setIsWidgetOpen] = useState(false)

  const onWidgetToggle = (value: boolean) => {
    setIsWidgetOpen(value)
    setIsGenieIconMinimized(false)
  }

  return (
    <BillyErrorBoundary>
      <WithDynamicFeatureFlag status="enabled" feature={Feature.SubskribeAiAgent}>
        <Grid container direction="column" alignItems="flex-end" className={classes.container}>
          <GenieChatProvider>{isWidgetOpen && <GenieWidget />}</GenieChatProvider>
          <MinimizeIconWrapper
            isWidgetOpen={isWidgetOpen}
            isGenieIconMinimized={isGenieIconMinimized}
            setIsGenieIconMinimized={setIsGenieIconMinimized}
          >
            <GenieLauncher isWidgetOpen={isWidgetOpen} setIsWidgetOpen={onWidgetToggle} />
          </MinimizeIconWrapper>
        </Grid>
      </WithDynamicFeatureFlag>
    </BillyErrorBoundary>
  )
}
