import BillyDataGridPro, { BillyDataGridProProps } from '@/components/table/billyDataGridPro'
import { SupportedRowProps } from '@/components/table/cells/LinkCell'
import { CardHeader } from '@mui/material'
import { GridColDef, GridColumnVisibilityModel, GridFilterModel, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { get } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Row } from 'react-table'
import { makeStyles } from 'tss-react/mui'
import { useDebouncedCallback } from 'use-debounce'
import buildLogger from '../../util/logger'
import BillyCard from '../card/billyCard'
import GqlErrorDisplay from '../error/gqlErrorDisplay'
import { useTablePageQueryParam, useTableSearchQueryParam } from '../route/useQueryParam'
import { mapFilterModelToEsQuery } from '../search/mapFilterItemsToEsQuery'
import useFuzzySearch, {
  FuzzySearchProps,
  isTypeofSupportedFuzzySearchFilterDataTypes,
  mapDataTypeToFilterOperators,
} from '../search/useFuzzySearch'
import { BaseTableDGPCell } from './BaseTableDGPCell'
import { BaseTableProps, BillyColumn, getDGPRenderCellByColumn, getSizeByDataType } from './baseTable'
import { useEsTableSorting } from './useEsTableSorting'

const logger = buildLogger('TableInCardWithSearch')

const PAGE_SIZE = 10

const useStyles = makeStyles()((theme, _params, _classes) => ({
  cardHeader: {
    '& .MuiCardHeader-action': {
      marginRight: 0,
      '& .MuiInputBase-root': {
        padding: 0,
        '& input': {
          fontSize: 14,
          fontWeight: 550,
        },
        '& .MuiInputAdornment-root': {
          transition: 'all .3s ease',
          justifyContent: 'center',
          minWidth: 40,
          minHeight: 40,
          margin: 0,
          padding: 0,
          backgroundColor: 'transparent',
        },
      },
    },
  },
}))

export type TableInCardWithSearchProps = Pick<
  BaseTableProps<Record<string, unknown>>,
  'rowActionType' | 'columnSort' | 'onChangeColumnSort'
> & {
  action?: JSX.Element
  columns: ReadonlyArray<BillyColumn>
  hidden?: boolean
  idField: string
  renderEmptyMessage?: string
  select?: FuzzySearchProps['where']
  term?: FuzzySearchProps['term']
  sortField?: FuzzySearchProps['sortField']
  table_name?: FuzzySearchProps['tableName']
  title: string
  urlPath: string
  tenantId?: string
  defaultSortField?: string
}

export function TableInCardWithSearchDGP({
  action,
  columns,
  hidden,
  idField,
  renderEmptyMessage,
  select,
  term,
  table_name,
  title,
  urlPath,
  tenantId,
  defaultSortField,
}: Pick<
  TableInCardWithSearchProps,
  | 'action'
  | 'columns'
  | 'hidden'
  | 'idField'
  | 'renderEmptyMessage'
  | 'select'
  | 'term'
  | 'table_name'
  | 'title'
  | 'urlPath'
  | 'tenantId'
  | 'defaultSortField'
>): JSX.Element {
  const { classes } = useStyles()

  const { sortModel, onSortModelChange, sortField } = useEsTableSorting(columns, defaultSortField)
  const { pageIndex, querySearchString, quickSearchProps } = useEsTableQuickSearch({ idField })

  const { fuzzySearchProps, ...filterProps } = useEsFilterModel({
    onChange: quickSearchProps.onFilterModelChange,
  })
  const fuzzySearchVariables: FuzzySearchProps[] = useMemo(() => {
    logger.trace({ msg: 'updated ES DGP filter', fuzzySearchProps })
    return [
      {
        columns: columns.map((c) => ({ accessor: c.accessor as string, dataType: c.dataType })),
        pageIndex,
        pageSize: PAGE_SIZE,
        querySearchString,
        sortField,
        tableName: table_name,
        where: select,
        term: term,
        ...fuzzySearchProps,
      },
    ]
  }, [columns, pageIndex, querySearchString, select, term, table_name, fuzzySearchProps, sortField])

  const [searchHits, searchResult, searchResponse] = useFuzzySearch(fuzzySearchVariables, false, tenantId)

  const searchError = searchResult.error

  const getLinkTarget = useCallback(
    (row: GridRenderCellParams['row']) => {
      const id = row?.[idField]
      return urlPath.replace(':id', id)
    },
    [idField, urlPath]
  )

  const total = searchResponse?.hits.total as unknown as { value: number } | undefined
  const totalRows = total?.value || 0
  const visibleColumnsProps = useVisibleColumnsProps({ columns })

  return (
    <BillyCard hidden={hidden}>
      <CardHeader title={title} action={action} className={classes.cardHeader} />
      <GqlErrorDisplay error={searchError} />
      <BillyDataGridPro
        variant="dgp-es-table-card"
        columns={baseTableColumnsToDataGridColumns({
          columns,
          getLinkTarget,
        })}
        rows={searchHits}
        loading={searchResult.fetching}
        renderEmptyMessage={renderEmptyMessage}
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        rowCount={totalRows}
        hideFooter={false}
        pagination
        autoPageSize
        paginationMode="server"
        filterMode="server"
        {...quickSearchProps}
        {...filterProps}
        {...visibleColumnsProps}
      />
    </BillyCard>
  )
}

export const useVisibleColumnsProps = ({
  columns,
}: {
  columns: ReadonlyArray<Pick<BillyColumn, 'accessor' | 'defaultInvisible'>>
}) => {
  const defaults = columns.reduce((acc, column) => {
    acc[column.accessor as string] = !column.defaultInvisible
    return acc
  }, {} as GridColumnVisibilityModel)

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(defaults)
  const onColumnVisibilityModelChange: BillyDataGridProProps['onColumnVisibilityModelChange'] = useCallback(
    (model: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(model)
    },
    [setColumnVisibilityModel]
  )
  return useMemo(
    () => ({ columnVisibilityModel, onColumnVisibilityModelChange }),
    [columnVisibilityModel, onColumnVisibilityModelChange]
  )
}

const useEsFilterModel = ({
  defaultModel,
  onChange,
}: {
  defaultModel?: GridFilterModel
  onChange?: BillyDataGridProProps['onFilterModelChange']
} = {}) => {
  const [filters, setFilters] = useState<GridFilterModel | undefined>(defaultModel)

  const props: Partial<BillyDataGridProProps> & {
    fuzzySearchProps: Partial<FuzzySearchProps>
  } = useMemo(
    () => ({
      filterMode: 'server',
      onFilterModelChange: (model, details) => {
        //TODO: more logic here
        setFilters(model)
        onChange?.(model, details)
      },
      fuzzySearchProps: mapFilterModelToEsQuery(filters),
      disableColumnFilter: false,
    }),
    [filters, onChange]
  )
  return props
}

//row can be any type in this case
//eslint-disable-next-line @typescript-eslint/no-explicit-any
type RowWithAny = Row<any>

export const baseTableColumnsToDataGridColumns = ({
  columns,
  getLinkTarget,
}: {
  // generic function
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: readonly BillyColumn<any>[]
  getLinkTarget?: (row: SupportedRowProps) => string
}) => {
  return columns
    .filter((column) => !column.hidden)
    .map((column, index) => {
      const Cell = column.Cell as React.FC<{ value: unknown; row: RowWithAny }> | undefined
      const accessor = column.accessor as string | undefined

      const defaultSize = getSizeByDataType(column.dataType)
      const flex = (column as unknown as { flex?: number }).flex ?? defaultSize.flex ?? undefined
      const minWidth = column.minWidth ?? defaultSize.minWidth
      const maxWidth = column.maxWidth ?? defaultSize.maxWidth

      const col: GridColDef = {
        minWidth,
        maxWidth,
        sortable: column.sort,
        field: column.accessor as string,
        flex,
        headerName: column.Header as string,
        renderCell:
          index === 0
            ? (params) => <BaseTableDGPCell column={column} getLinkTarget={getLinkTarget} params={params} />
            : Cell
            ? (params) => <Cell value={accessor ? params.row?.[accessor] : undefined} row={params.row} />
            : getDGPRenderCellByColumn(column),

        filterable: isTypeofSupportedFuzzySearchFilterDataTypes(column.dataType),
        filterOperators: mapDataTypeToFilterOperators(column.dataType),
        valueGetter: accessor ? (param) => get(param.row, accessor) : undefined,
      }
      return col
    })
}

function useEsTableQuickSearch({ idField }: { idField: string }) {
  const [querySearchString, setQuerySearchString] = useTableSearchQueryParam(idField)
  const [queryPageString, setQueryPageString] = useTablePageQueryParam(idField)
  const [searchDisplayString, setSearchDisplayString] = useState(querySearchString)

  const updateSearchQueryString = useDebouncedCallback((newSearchString: string) => {
    setQuerySearchString(newSearchString)
  }, 300)

  const pageIndex = parseInt(queryPageString) || 0

  const setPageIndex = useCallback(
    (newPage: number) => {
      setQueryPageString(newPage ? newPage.toString() : '')
    },
    [setQueryPageString]
  )

  const handleSearchChange = useCallback(
    (searchString: string) => {
      setSearchDisplayString(searchString)
      updateSearchQueryString(searchString)
    },
    [updateSearchQueryString]
  )

  const quickSearchProps: Partial<BillyDataGridProProps> = useMemo(
    () => ({
      paginationModel: {
        pageSize: PAGE_SIZE,
        page: pageIndex,
      },
      onPaginationModelChange: (model) => {
        setPageIndex(model.page)
      },
      onFilterModelChange: (model) => {
        handleSearchChange(model?.quickFilterValues?.join(' ') || '')
      },
      slotProps: {
        toolbar: {
          quickFilterProps: {
            value: searchDisplayString,
            onChange: (event) => handleSearchChange(event.target.value),
          },
        },
      },
    }),
    [pageIndex, setPageIndex, handleSearchChange, searchDisplayString]
  )

  return useMemo(
    () => ({ querySearchString, queryPageString, pageIndex, setPageIndex, handleSearchChange, quickSearchProps }),
    [querySearchString, queryPageString, pageIndex, setPageIndex, handleSearchChange, quickSearchProps]
  )
}
