import * as React from 'react'

import BillyCard from '@/components/card/billyCard'
import { CardContent, CardHeader as MuiCardHeader, Typography } from '@mui/material'

const Card = BillyCard

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(function CardHeaderWithoutRef(
  { children, ...props },
  ref
) {
  return <MuiCardHeader ref={ref} title={children} {...props} />
})

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  function CardTitleWithoutRef({ ...props }, ref) {
    return <Typography variant="h6" ref={ref} {...props} />
  }
)

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  function CardDescriptionWithoutRef({ ...props }, ref) {
    return <Typography ref={ref} variant={'subtitle1'} {...props} />
  }
)

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(function CardFooterWithoutRef(
  { ...props },
  ref
) {
  return <div ref={ref} {...props} />
})
export { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle }
