import React, { HTMLAttributeAnchorTarget, MouseEventHandler } from 'react'
import { Button, ButtonProps, CircularProgress, Tooltip, Link, TooltipProps } from '@mui/material'
import { ButtonTypeMap } from '@mui/material/Button/Button'
import { Theme } from '@mui/material/styles'
import NextLink from 'next/link'
import { makeStyles } from 'tss-react/mui'
import { ButtonDataList } from '../nav/secondaryBarWithButtons'

export type CustomColor = ButtonTypeMap['props']['color'] | 'danger'

/**
 * A link to a specific URL in the product.
 */
type LinkStr = string

export type ButtonData<T = string> = {
  label: string
  color?: CustomColor
  /**
   * The click handler for the button.  When this is a string, we pass it
   * to NextLink.
   */
  onClick?: LinkStr | MouseEventHandler
  target?: HTMLAttributeAnchorTarget
  menu?: ButtonDataList
  disabledExplanation?: string | false
  tooltipPlacement?: TooltipProps['placement']
  buttonProps?: ButtonProps & { target?: string }
  fullWidth?: boolean
  /**
   * - if set to undefined, always show
   * - if set to [], never show
   * - if set to [x, y, z], show when in state x, y, or z
   */
  show?: readonly T[]
  /**
   * - if set to undefined, never become primary
   * - if set to [], never become primary button
   * - if set to [x, y, z], becomes primary button when in state x, y, or z
   */
  loading?: boolean
  primary?: boolean
  /**
   * Secondary outlined action button
   */
  secondary?: boolean
  /**
   * An addon icon or react element that will be shown before item label
   */
  prefix?: React.ReactNode
}

export function shouldButtonShowIn<T = string>(currentState?: T) {
  return (buttonItem: ButtonData<T>) => (currentState ? buttonItem.show?.includes(currentState) ?? true : false)
}
export const getMenuAndPrimaryButton = <T,>(buttonList: ButtonData<T>[]) => {
  const firstIndexWithPrimary = buttonList.findIndex((button) => !!button.primary)
  const primaryIndex = firstIndexWithPrimary === -1 ? 0 : firstIndexWithPrimary
  const primaryButton = buttonList[primaryIndex]

  const secondaryIndex = buttonList.findIndex((button) => !!button.secondary)
  const secondaryButton = buttonList[secondaryIndex]

  const actionsMenu = buttonList.filter((_, index) => index !== primaryIndex && index !== secondaryIndex)
  return { actionsMenu, primaryButton, secondaryButton }
}
const useStyles = makeStyles()((theme: Theme) => ({
  dangerButton: {
    '&:hover': {
      borderColor: theme.palette.error.dark,
      color: theme.palette.error.dark,
    },
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
  disabledButton: {
    cursor: 'not-allowed',
  },
  fullWidth: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
  noWrapButton: {
    whiteSpace: 'nowrap',
  },
  toolbarButton: {
    marginLeft: theme.spacing(1),
  },
}))

export type getPageActionButtonsProps<T> = {
  actions: ButtonData<T>[]
  loading?: boolean
}

export const getPageButtons = <T extends string>({ actions, loading }: getPageActionButtonsProps<T>) => {
  const { actionsMenu, primaryButton, secondaryButton } = getMenuAndPrimaryButton<T>(actions)

  const primaryOrSecondaryButton = primaryButton || secondaryButton
  const buttonDataList: ButtonData<T>[] = primaryOrSecondaryButton
    ? [
        {
          label: 'More',
          menu: actionsMenu,
          buttonProps: { variant: 'outlined' },
          disabledExplanation: loading ? 'Please wait while the request is in progress' : '',
        },
        ...(secondaryButton
          ? [
              {
                ...secondaryButton,
                buttonProps: { ...secondaryButton.buttonProps, variant: 'outlined' as const },
                disabledExplanation: loading
                  ? 'Please wait while the request is in progress'
                  : secondaryButton.disabledExplanation,
              },
            ]
          : []),
        {
          ...primaryButton,
          buttonProps: { ...primaryButton.buttonProps },
          disabledExplanation: loading
            ? 'Please wait while the request is in progress'
            : primaryButton.disabledExplanation,
        },
      ]
    : [
        {
          label: 'Actions',
          menu: actionsMenu,
          buttonProps: { variant: 'outlined' },
          disabledExplanation: loading ? 'Please wait while the request is in progress' : '',
        },
      ]

  return buttonDataList
}

export default function ActionButton({
  buttonData,
  mode = 'default',
}: {
  buttonData: Readonly<ButtonData>
  mode?: 'default' | 'card-action' | 'cancel'
}): JSX.Element {
  const { classes, cx } = useStyles()
  const color =
    (buttonData.color === 'danger' ? 'primary' : buttonData.color) || (mode === 'cancel' ? 'inherit' : 'primary')
  const variant = ['card-action', 'cancel'].includes(mode) ? 'outlined' : 'contained'

  const disabled = !!buttonData.disabledExplanation || buttonData.loading

  const baseActionButton = (
    <Tooltip
      key={buttonData.label}
      placement={buttonData.tooltipPlacement}
      title={buttonData.disabledExplanation || ''}
    >
      <span className={cx(classes.noWrapButton, disabled && classes.disabledButton)}>
        <Button
          variant={variant}
          color={color}
          onClick={typeof buttonData.onClick !== 'string' ? buttonData.onClick : undefined}
          disabled={disabled}
          className={cx(
            buttonData.color === 'danger' && classes.dangerButton,
            buttonData.fullWidth ? classes.fullWidth : classes.toolbarButton,
            disabled && classes.disabledButton
          )}
          name={buttonData.buttonProps?.name ? buttonData.buttonProps.name : buttonData.label.toLowerCase()}
          {...buttonData.buttonProps}
        >
          {buttonData.loading ? (
            <CircularProgress size={16} sx={{ marginRight: 0.5 }} color="inherit" />
          ) : (
            buttonData.prefix && buttonData.prefix
          )}
          {buttonData.buttonProps?.children || buttonData.label}
        </Button>
      </span>
    </Tooltip>
  )
  if (typeof buttonData.onClick === 'string' && !disabled) {
    const link = buttonData.onClick
    return (
      <NextLink href={link} key={buttonData.label} passHref={true}>
        <Link target={buttonData.target}>{baseActionButton}</Link>
      </NextLink>
    )
  } else {
    return <>{baseActionButton}</>
  }
}
