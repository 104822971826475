import { Box, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { GenieAvatar } from './GenieAvatar'

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  spin: {
    animation: `$spinIcon 1.5s linear infinite`,
  },
  '@keyframes spinIcon': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

export const GenieResponseLoader = () => {
  const classes = useStyles()

  return (
    <Box className={classes.loader}>
      <Box className={classes.spin}>
        <GenieAvatar />
      </Box>
      <Typography variant="body2">Thinking...</Typography>
    </Box>
  )
}
