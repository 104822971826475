import { useState } from 'react'

const REMAINING_SCROLL_TO_BOTTOM = 50

export const useIsScrolledToBottom = () => {
  const [isScrolledToBottom, setScrollToBottom] = useState<boolean>()

  const setIsScrolledToBottom = () => {
    if (document.scrollingElement) {
      const { scrollHeight, clientHeight, scrollTop } = document.scrollingElement
      if (scrollHeight === clientHeight) {
        setScrollToBottom(true)
      } else {
        const scrollToBottom = scrollHeight - (clientHeight + scrollTop)
        setScrollToBottom(scrollToBottom < REMAINING_SCROLL_TO_BOTTOM)
      }
    } else {
      setScrollToBottom(false)
    }
  }

  return {
    isScrolledToBottom,
    setIsScrolledToBottom,
  }
}
