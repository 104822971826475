import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import buildLogger from '../../util/logger'
import { ButtonData } from '../button/actionButton'
import navCommon from '../nav/navCommon'
import SecondaryBarWithButtons from '../nav/secondaryBarWithButtons'
import LayoutWithNav, { LayoutWithNavProps } from './layoutWithNav'
import { TopBanner } from '../nav/TopBanner'
import { getEnv } from '@/util/isEnv'
import { useServerMaintenanceMessage } from '../hooks/useServerMaintenanceMessage'

const logger = buildLogger('NavLayout')

export type BreadcrumbData = {
  icon?: JSX.Element | string
  label: string
  link?: string
}

export type TActionButtons = ButtonData[] | JSX.Element

export type NavLayoutProps = Readonly<
  Pick<LayoutWithNavProps, 'tabs' | 'defaultTab'> & {
    breadcrumbs?: BreadcrumbData[]
    children?: React.ReactNode
    actionButtons?: TActionButtons
    className?: any
    rightDrawer?: () => JSX.Element
  }
>

function hasActionButtons(actionButtons: TActionButtons) {
  if (actionButtons) {
    if (Array.isArray(actionButtons)) {
      if (actionButtons.length > 0) {
        return true
      }
    } else {
      return true
    }
  }

  return false
}

const SecondaryBar = ({ actionButtons }: Required<Pick<NavLayoutProps, 'actionButtons'>>) => {
  if (actionButtons) {
    if (Array.isArray(actionButtons)) {
      if (actionButtons.length > 0) {
        return <SecondaryBarWithButtons buttonDataList={actionButtons} />
      }
    } else {
      return <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{actionButtons}</div>
    }
  }

  return null
}

const NavLayoutWithoutMemo = ({
  actionButtons,
  breadcrumbs,
  children,
  className,
  defaultTab,
  tabs,
  rightDrawer,
}: NavLayoutProps): JSX.Element => {
  const ref = useAutoScrollEffect()
  const envInfo = getEnv()
  const { messages } = useServerMaintenanceMessage()

  return (
    <>
      {envInfo === 'sandbox' && <TopBanner messages={[envInfo]} />}
      {messages && <TopBanner messages={messages} variant="warning" />}
      <LayoutWithNav
        secondaryBar={
          actionButtons && hasActionButtons(actionButtons) ? <SecondaryBar actionButtons={actionButtons} /> : undefined
        }
        breadcrumbs={breadcrumbs ?? []}
        tabs={tabs}
        defaultTab={defaultTab}
        rightDrawer={rightDrawer}
      >
        <Box ref={ref} className={className}>
          {children}
        </Box>
      </LayoutWithNav>
    </>
  )
}

export const NavLayout = React.memo(NavLayoutWithoutMemo) as typeof NavLayoutWithoutMemo

function useAutoScrollEffect() {
  const useStyles = makeStyles()((theme, _params, _classes) => ({}))
  const { theme } = useStyles()
  const topBarHeight = navCommon.topBarHeight(theme)

  const [scrollAttempts, setScrollAttempts] = useState(0)

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (document.location.hash) {
      const timeout = setTimeout(() => {
        if (document.location.hash) {
          const el = document.querySelector(document.location.hash)
          if (el) {
            el.scrollIntoView()
            const elTop = el.getBoundingClientRect().top
            if (ref.current) {
              if (scrollAttempts < 100) {
                setScrollAttempts(scrollAttempts + 10)
              }
              if (ref.current.scrollTop > topBarHeight && elTop < topBarHeight) {
                ref.current.scrollTop -= topBarHeight
              }
            }
          } else {
            if (scrollAttempts < 100) {
              setScrollAttempts(scrollAttempts + 1)
            }
          }
        }
      }, 50)
      return () => clearTimeout(timeout)
    }
  }, [scrollAttempts, topBarHeight])
  return ref
}
