export const GENIE_BOT_NAME = 'Subskribe AI'
export const GENIE_BOT_ICON = '/images/genie.png'
export const GENIE_BOT_TRANSPARENT_ICON = '/images/genie-transparent.png'
export const GENIE_AI_ICON = '/icons/ai-agent.svg'
export const GENIE_AI_PRIMARY_COLOR_ICON = '/icons/ai-agent-primary.svg'

export const lsGenieSessionIdKey = 'genie-session-id-v1'

export const LOAD_MESSAGES_LIMIT = 50

export enum GENIE_USER_TYPE {
  ASSISTANT = 'assistant',
  USER = 'user',
}

export enum GENIE_VIEW_MODE {
  REGULAR_VIEW = 'regular_view',
  FULL_VIEW = 'full_view',
}

export enum GENIE_LOADING_MESSAGES {
  CREATING_CHAT_SESSION = 'Loading new chat...',
  VALIDATING_CHAT_SESSION = 'Loading your chat...',
  LOADING_CHAT_MESSAGES = 'Loading chat messages...',
}

export enum GENIE_FALLBACK_ERROR_MESSAGES {
  CREATE_CHAT_SESSION = 'Error creating session. Please reach out to support.',
  GET_CHAT_SESSION = 'This chat session is either invalid or does not exist. Please try creating a new session.',
  LOAD_CHAT_MESSAGES = 'Error loading chat messages.',
  SEND_CHAT_MESSAGE = 'Something went wrong, please try sending another message.',
}
