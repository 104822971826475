import { deepMutable } from '@/components/SchemaForm/DeepMutable'
import { useConfirmPlanReplacementDialog } from '@/pageComponents/orders/PlanReplacement/ConfirmPlanReplacementDialog'
import { useViewPlanReplacementDialog } from '@/pageComponents/orders/PlanReplacement/ViewPlanReplacementDialog'
import { FlipCameraAndroid as FlipCameraAndroidIcon, InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ReplayIcon from '@mui/icons-material/Replay'
import {
  Box,
  Collapse,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { current } from 'immer'
import { memo, useCallback, useContext, useState } from 'react'
import BillyLink from '../../../components/link/billyLink'
import { JotaiFormContext } from '../../../components/state/jotaiFormProvider'
import { JotaiForm } from '../../../components/state/useJotaiForm'
import StatusChip from '../../../components/table/cells/statusChip'
import {
  ActionType,
  Feature,
  LineItemFragment,
  LineItemPlanReplacementFragment,
  PlanFragment,
  PlanStatus,
} from '../../../generated/graphql'
import buildLogger from '../../../util/logger'
import { useDryRunActions } from '../EditOrderPage/context/DryRunActionsContext'
import { NewOrderFormData } from '../EditOrderPage/NewOrderPage'
import { LineItemState } from '../LineItemsViewTable/LineItemsViewTable'
import PlanDialogBody from '../planDialogBody'
import { OrderEditPlanRowHeader } from './CustomColumn/OrderEditPlanRowHeader'
import { LineItemsTableOrderType } from './LineItemsEditTable'
import { LineItemTotal } from './LineItemTotal'
import OrderChargeRow from './orderChargeRow'
import { useOrderEditPlanRowStyles } from './useOrderEditPlanRowStyles'
import useDynamicFeatureFlag from '@/components/state/useDynamicFeatureFlag'

const logger = buildLogger('PlanRow')

function getIsPlanReplacementAvailable(lineItems?: LineItemFragment[]) {
  return lineItems?.some((li) => !!li.availableReplacementPlan?.id) ?? false
}
function getIsPlanReplaced(lineItems?: LineItemFragment[]) {
  return lineItems?.some((li) => !!li.replacedPlan?.id) ?? false
}
const getReplacementPropsSelector = (form: NewOrderFormData, plan: LineItemPlanReplacementFragment) => {
  const isRenewal = form.orderDetail.orderType === 'RENEWAL'
  const lineItemsFromPlan = form.orderDetail.lineItems.filter((li) => li.plan?.id === plan.id)
  // only lineItems contain replacement information, api assumes that all lineItems have the same replacement information
  const firstLineItem = lineItemsFromPlan?.[0]
  const isPlanReplaced = isRenewal && getIsPlanReplaced(lineItemsFromPlan)
  const isPlanPlacementAvailable = isPlanReplaced
    ? false
    : isRenewal && getIsPlanReplacementAvailable(lineItemsFromPlan)
  const originalPlan: LineItemPlanReplacementFragment = {
    id: '',
    name: '',
    charges: [],
    ...(isPlanReplaced ? firstLineItem?.replacedPlan : isPlanPlacementAvailable ? plan : undefined),
  }

  const replacementPlan: LineItemPlanReplacementFragment = {
    id: '',
    name: '',
    charges: [],
    ...(isPlanReplaced ? plan : isPlanPlacementAvailable ? firstLineItem?.availableReplacementPlan : undefined),
  }

  const isReplacementInOrderAlready =
    isPlanPlacementAvailable &&
    form.orderDetail.lineItems.some((li) => {
      return li.plan?.id && li.plan.id === replacementPlan.id
    })
  const disabledExplanation = isReplacementInOrderAlready
    ? 'Unable to replace. Same plan already exists on this order.'
    : ''

  return {
    isPlanReplaced,
    isPlanPlacementAvailable,
    replacementPlan,
    originalPlan,
    disabledExplanation,
  }
}

export type PlanRowProps = {
  orderType: LineItemsTableOrderType
  plan: LineItemPlanReplacementFragment & Pick<PlanFragment, 'id' | 'name' | 'status'>
  index: number
  isPlanRampedAndRemoved?: boolean
}

function mapNormalizeLineItemIndexes({
  lineItems,
  planId,
  orderType,
}: {
  lineItems: LineItemFragment[]
  planId?: string
  orderType: LineItemsTableOrderType
}) {
  return lineItems
    .map((lineItem, lineItemIndex) => {
      return {
        ...lineItem,
        lineItemIndex: lineItemIndex,
      }
    })
    .filter((lineItem) =>
      orderType === 'RESTRUCTURE' && lineItem.plan?.id === planId && lineItem.action === ActionType.Remove
        ? false
        : lineItem.plan?.id === planId
    )
    .map((lineItem) => lineItem.lineItemIndex)
}

const OrderEditPlanRow = memo(function OrderEditPlanRowWithoutMemo({
  orderType,
  plan,
  index,
}: PlanRowProps): JSX.Element {
  const { classes } = useOrderEditPlanRowStyles()
  const [isOpen, setIsOpen] = useState(true)
  const jotaiForm = useContext<JotaiForm<NewOrderFormData>>(JotaiFormContext)
  const { queueDryRun } = useDryRunActions()
  const isOrderTypeAmendmentOrRestructure = orderType === 'AMENDMENT' || orderType === 'RESTRUCTURE'

  const lineItemIndexesMatchesPlan = jotaiForm.useSelect(
    useCallback(
      (form: NewOrderFormData) =>
        mapNormalizeLineItemIndexes({
          orderType,
          lineItems: form.orderDetail.lineItems || [],
          planId: plan.id ?? undefined,
        }),
      [orderType, plan.id]
    )
  )

  const [isPlanDialogOpen, setIsPlanDialogOpen] = useState(false)

  const handlePlanDialogClose = (): void => {
    setIsPlanDialogOpen(false)
  }

  const handlePlanClick = (): void => {
    setIsPlanDialogOpen(true)
  }

  const isNewPlan = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const filteredLineItems = form.orderDetail.lineItems?.filter(
          (lineItem) => lineItem.plan?.id === plan.id && lineItem.action !== ActionType.Remove
        )

        return filteredLineItems.length > 0
          ? filteredLineItems.every((lineItem) => lineItem.action === ActionType.Add)
          : false
      },
      [plan]
    )
  )

  const removePlan = useCallback(() => {
    jotaiForm.set((form) => {
      form.orderDetail.lineItems = form.orderDetail.lineItems.filter((lineItem) =>
        lineItem.action === ActionType.Remove && lineItem.isRamp ? true : lineItem.plan?.id !== plan.id
      )
    })
    queueDryRun()
  }, [jotaiForm, plan.id, queueDryRun])

  const removeAmendmentPlan = useCallback(() => {
    if (isNewPlan) {
      removePlan()
    } else {
      jotaiForm.set((form) => {
        // remove new line items
        form.orderDetail.lineItems = form.orderDetail.lineItems.filter(
          (li) => li.plan?.id !== plan.id || li.action !== ActionType.Add
        )

        // remove existing line items
        form.orderDetail.lineItems
          .filter((li) => li.plan?.id === plan.id && li.endDate && li.endDate > form.orderDetail.startDate)
          .forEach((li) => {
            li.action = ActionType.Remove
            li.quantity = 0
          })
      })
      queueDryRun()
    }
  }, [isNewPlan, jotaiForm, plan.id, queueDryRun, removePlan])

  const revertAmendmentPlan = useCallback(() => {
    jotaiForm.set((form) => {
      // remove new line items
      form.orderDetail.lineItems = form.orderDetail.lineItems.filter(
        (li) => li.plan?.id !== plan.id || li.action !== ActionType.Add
      )

      // revert existing line items
      form.orderDetail.lineItems
        .filter((lineItem) => lineItem.plan?.id === plan.id)
        .forEach((lineItem) => {
          lineItem.action = ActionType.None
          lineItem.quantity = form.orderDetail.currentSubscription?.charges.find(
            (charge) => charge?.id === lineItem.subscriptionChargeId
          )?.quantity
          lineItem.arrOverride = undefined
        })
    })
    queueDryRun()
  }, [jotaiForm, plan.id, queueDryRun])

  const isPlanRemoved = jotaiForm.useSelect(
    useCallback(
      (form) => {
        const restructuredLineItems = form.orderDetail.lineItems?.filter(
          (lineItem) => lineItem.plan?.id === plan.id && lineItem.action !== ActionType.Remove && lineItem.isRamp
        )
        const filteredLineItems = form.orderDetail.lineItems?.filter((lineItem) => lineItem.plan?.id === plan.id)

        return restructuredLineItems.length > 0
          ? restructuredLineItems.every((lineItem) => lineItem.action === ActionType.Remove)
          : filteredLineItems.every((lineItem) => lineItem.action === ActionType.Remove)
      },
      [plan]
    )
  )

  const { replacementPlan, originalPlan, ...planReplacementProps } = jotaiForm.useSelect(
    useCallback((form) => getReplacementPropsSelector(form, plan), [plan])
  )
  const isRenewalReplacePlanEnabled = useDynamicFeatureFlag(Feature.RenewalReplacePlan)
  const isPlanPlacementAvailable = planReplacementProps.isPlanPlacementAvailable && isRenewalReplacePlanEnabled
  const isPlanReplaced = planReplacementProps.isPlanReplaced && isRenewalReplacePlanEnabled

  const [onConfirmPlanReplacement] = useConfirmPlanReplacementDialog({
    replacementPlan,
    disabledExplanation: planReplacementProps.disabledExplanation,
    onSubmit: () => {
      jotaiForm.set((draft) => {
        draft.orderDetail.lineItems
          .filter((lineItem) => lineItem.plan?.id === plan.id)
          ?.forEach((lineItem, index) => {
            if (lineItem.plan) {
              lineItem.replacedPlan = deepMutable({
                charges: plan.charges,
                name: plan.name,
                id: plan.id,
              })
            }
            lineItem.action = ActionType.Add
            lineItem.plan = deepMutable({
              status: PlanStatus.Active,
              ...current(lineItem.plan),
              ...replacementPlan,
            })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - update charge with partial
            lineItem.charge = replacementPlan.charges?.[index]
          })

        draft.orderPlans = deepMutable(
          current(draft.orderPlans)
            .map((orderPlan) => {
              if (orderPlan.id === plan.id) {
                return {
                  ...orderPlan,
                  ...replacementPlan,
                }
              }
              return orderPlan
            })
            .filter((orderPlan, index, self) => self.findIndex((t) => t.id === orderPlan.id) === index)
        )
      })
      queueDryRun()
    },
  })

  const [onViewPlanReplacement] = useViewPlanReplacementDialog({
    originalPlan,
    replacementPlan,
  })

  if (lineItemIndexesMatchesPlan.length === 0) {
    return <></>
  }

  return (
    <>
      <Dialog open={isPlanDialogOpen} onClose={handlePlanDialogClose} maxWidth={'md'} scroll={'paper'}>
        <PlanDialogBody onClose={handlePlanDialogClose} id={plan.id || ''} />
      </Dialog>
      <TableRow>
        <TableCell className={classes.planNameCell}>
          <span style={{ display: 'flex' }}>
            <Typography component="span" variant="body2" sx={{ mr: 1 }}>
              {index + 1}.
            </Typography>
            <BillyLink linkProps={{ onClick: () => handlePlanClick() }} style={{ display: 'inline' }}>
              {plan.name}
            </BillyLink>
          </span>
          {isPlanPlacementAvailable && (
            <>
              <Tooltip title="Confirm Plan Replacement">
                <IconButton
                  aria-label="confirm-plan-replacement"
                  size="medium"
                  onClick={onConfirmPlanReplacement}
                  sx={{ ml: 1 }}
                >
                  <FlipCameraAndroidIcon />
                </IconButton>
              </Tooltip>
              <span className={classes.statusChip}>
                <StatusChip value={'Replacement Plan Available'} />
              </span>
            </>
          )}
          {isPlanReplaced && (
            <>
              <Tooltip title="View Plan Replacement">
                <IconButton
                  aria-label="view-plan-replacement"
                  size="medium"
                  onClick={onViewPlanReplacement}
                  sx={{ ml: 1 }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              <span className={classes.statusChip}>
                <StatusChip value={'Replacement Plan'} />
              </span>
            </>
          )}
          {isPlanRemoved && isOrderTypeAmendmentOrRestructure && (
            <span className={classes.statusChip}>
              <StatusChip value={LineItemState.Removed} />
            </span>
          )}
          {isNewPlan && isOrderTypeAmendmentOrRestructure && (
            <span className={classes.statusChip}>
              <StatusChip value={LineItemState.New} />
            </span>
          )}
          {plan.status === PlanStatus.Deprecated && (
            <span className={classes.statusChip}>
              <StatusChip value={LineItemState.Deprecated} />
            </span>
          )}
        </TableCell>
        <TableCell align="left">
          <LineItemTotal plan={plan} />
        </TableCell>
        <TableCell align="right">
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {!['CANCELLATION', 'RESTRUCTURE'].includes(orderType) && isPlanRemoved && (
              <IconButton aria-label="Revert" size="medium" onClick={revertAmendmentPlan}>
                <ReplayIcon />
              </IconButton>
            )}
            {orderType !== 'CANCELLATION' && !isPlanRemoved && (
              <Tooltip title="Delete Plan">
                <IconButton
                  aria-label="delete plan"
                  size="medium"
                  onClick={orderType === 'AMENDMENT' ? removeAmendmentPlan : removePlan}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton aria-label="expand plan" size="small" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={3}
          style={{
            padding: 0,
            height: 'unset',
          }}
        >
          <Collapse in={isOpen} timeout="auto">
            <Table>
              <OrderEditPlanRowHeader orderType={orderType} />
              <TableBody className={classes.orderPlanChargesBody}>
                {lineItemIndexesMatchesPlan.map((lineItemIndex) => {
                  return <OrderChargeRow key={lineItemIndex} lineItemIndex={lineItemIndex} orderType={orderType} />
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
})

export default OrderEditPlanRow
