import { useCallback, useEffect, useState } from 'react'
import { AutocompleteProps, CircularProgress, InputAdornment, InputProps, useTheme } from '@mui/material'
import levenshtein from 'fast-levenshtein'
import { Draft } from 'immer'
import { useUpdateAtom } from 'jotai/utils'
import { useDebounce } from 'use-debounce'
import { formatUtcString } from '../../util/datetime/luxon/dateUtil'
import buildLogger from '../../util/logger'
import useFuzzySearch, { FuzzySearchProps } from '../search/useFuzzySearch'
import JotaiMuiAutocomplete, {
  BillyMuiAutocompleteProps,
  BillyMuiAutocomplete,
  DataTypeWithIdAndLabel,
  JotaiMuiAutocompleteProps,
} from './JotaiMuiAutocomplete'

const logger = buildLogger('JotaiMuiEsAutocomplete')

const SEARCH_WAIT_MS = 250

export type EsSearchOption = {
  id: string
  label: string
}

export const searchUpdateToOptions = (values: any[]): EsSearchOption[] => {
  return values.map((searchResult: any) => {
    const date = searchResult.created_on
    return {
      id: searchResult.id,
      label: `${searchResult.id} (${formatUtcString(date)})`,
    }
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const buildAutocompleteProps = <T extends EsSearchOption>(): Partial<AutocompleteProps<T, any, any, false>> => ({
  getOptionLabel: (option: string | T) => (typeof option === 'string' ? option : option.label),
  renderOption: function renderOption(props, option: T) {
    return (
      <li {...props} key={option.id}>
        {option.label}
      </li>
    )
  },
})

const buildQuery = (props: Partial<FuzzySearchProps>, debouncedUserInput?: string): FuzzySearchProps => {
  return {
    columns: props.columns ?? [],
    fillWithNonMatchingResults: true,
    querySearchString: debouncedUserInput ?? '',
    pageIndex: 0,
    pageSize: 10,
    ...props,
  }
}

function JotaiMuiEsAutocomplete<T, V>({
  fuzzySearchProps,
  onSearchUpdate,
  disableStringFilterStartWith,
  ...otherProps
}: JotaiMuiAutocompleteProps<T, V> & {
  fuzzySearchProps: Partial<FuzzySearchProps> | Partial<FuzzySearchProps>[]
  onSearchUpdate: (searchResults: unknown[], draft: Draft<T>) => void
  disableStringFilterStartWith?: boolean
}): JSX.Element {
  const theme = useTheme()

  const [userInput, setUserInput] = useState<string | undefined>()
  const [debouncedUserInput] = useDebounce(userInput, SEARCH_WAIT_MS)

  const [searchHits, searchResult] = useFuzzySearch(
    Array.isArray(fuzzySearchProps)
      ? fuzzySearchProps.map((p) => buildQuery(p, debouncedUserInput))
      : [buildQuery(fuzzySearchProps, debouncedUserInput)]
  )

  const setAtom = useUpdateAtom(otherProps.form.atom)

  useEffect(() => {
    if (onSearchUpdate) {
      setAtom((draft) => {
        onSearchUpdate(searchHits, draft)
      })
    }
  }, [onSearchUpdate, searchHits, setAtom])

  const getOptionLabel = otherProps.autocompleteProps?.getOptionLabel

  const loading = searchResult.fetching || userInput !== debouncedUserInput

  const inputProps: InputProps = {
    ...otherProps.textFieldProps.InputProps,
  }

  if (loading) {
    inputProps.endAdornment = (
      <InputAdornment position="end" style={{ paddingRight: theme.spacing(1) }}>
        <CircularProgress size={theme.typography.fontSize} />
      </InputAdornment>
    )
  }

  return (
    <JotaiMuiAutocomplete
      {...otherProps}
      atomOptionsSelector={useCallback(
        (form: T) => {
          const options: V[] = []
          const atomOptions = otherProps.atomOptionsSelector(form)
          if (atomOptions) {
            options.push(...atomOptions)
          }
          if (otherProps.atomValueSelector) {
            const value = otherProps.atomValueSelector(form)
            if (value) {
              options.push(value)
            }
          }
          if (otherProps.atomMultiValueSelector) {
            const values = otherProps.atomMultiValueSelector(form)
            if (values) {
              options.push(...values)
            }
          }
          // elastic search results
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return options.filter((obj: any, index, self) => index === self.findIndex((el: any) => el.id === obj.id))
        },
        [otherProps]
      )}
      autocompleteProps={{
        ...otherProps.autocompleteProps,
        disableClearable: loading,
        forcePopupIcon: !loading,
        filterOptions: (options, state) => {
          if (getOptionLabel) {
            return options.filter((option) => {
              const optionLabel = getOptionLabel(option)
              const inputValue = state.inputValue
              const levDistance = levenshtein.get(state.inputValue, getOptionLabel(option))
              return (
                (disableStringFilterStartWith
                  ? optionLabel.toLowerCase().includes(inputValue.toLowerCase())
                  : optionLabel.toLowerCase().startsWith(inputValue.toLowerCase())) || levDistance < 4
              )
            })
          }
          return options
        },
        filterSelectedOptions: true,
        inputValue: userInput ?? '',
        loading,
        onInputChange: useCallback((event, value, reason) => {
          if (reason !== 'reset' || event || value) {
            setUserInput(value)
          }
        }, []),
      }}
      textFieldProps={{
        ...otherProps.textFieldProps,
        InputProps: {
          ...inputProps,
        },
      }}
    />
  )
}

export default JotaiMuiEsAutocomplete

export type BillyMuiEsAutocompleteProps<
  DataType,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> = BillyMuiAutocompleteProps<DataType, Multiple, DisableClearable> & {
  fuzzySearchProps: Partial<FuzzySearchProps> | Partial<FuzzySearchProps>[]
  onSearchUpdate: (searchResults: unknown[]) => void
  tenantId?: string
}

export function BillyMuiEsAutocomplete<DataType extends DataTypeWithIdAndLabel, Multiple extends boolean | undefined>({
  fuzzySearchProps,
  onSearchUpdate,
  tenantId,
  ...otherProps
}: BillyMuiEsAutocompleteProps<DataType, Multiple, boolean>) {
  const theme = useTheme()

  const [userInput, setUserInput] = useState<string>('')
  const [debouncedUserInput] = useDebounce(userInput, SEARCH_WAIT_MS)

  const [searchHits, searchResult] = useFuzzySearch(
    Array.isArray(fuzzySearchProps)
      ? fuzzySearchProps.map((p) => buildQuery(p, userInput))
      : [buildQuery(fuzzySearchProps, userInput)],
    false,
    tenantId
  )

  useEffect(() => {
    if (onSearchUpdate) {
      onSearchUpdate(searchHits)
    }
  }, [onSearchUpdate, searchHits])

  const getOptionLabel = otherProps.autocompleteProps?.getOptionLabel

  const loading = searchResult.fetching || userInput !== debouncedUserInput

  const inputProps: InputProps = {
    ...otherProps.textFieldProps.InputProps,
  }

  if (loading) {
    inputProps.endAdornment = (
      <InputAdornment position="end" style={{ paddingRight: theme.spacing(1) }}>
        <CircularProgress size={theme.typography.fontSize} />
      </InputAdornment>
    )
  }

  return (
    <BillyMuiAutocomplete<DataType, Multiple, boolean>
      {...otherProps}
      autocompleteProps={{
        ...otherProps.autocompleteProps,
        disableClearable: !!loading,
        forcePopupIcon: !loading,
        filterOptions: (options, state) => {
          if (getOptionLabel) {
            return options.filter((option) => {
              const optionLabel = getOptionLabel(option)
              const inputValue = state.inputValue
              const levDistance = levenshtein.get(state.inputValue, getOptionLabel(option))
              return optionLabel.toLowerCase().startsWith(inputValue.toLowerCase()) || levDistance < 4
            })
          }
          return options
        },
        filterSelectedOptions: true,
        inputValue: userInput,
        loading,
        onInputChange: useCallback((_event, value) => {
          setUserInput(value)
        }, []),
      }}
      textFieldProps={{
        ...otherProps.textFieldProps,
        InputProps: {
          ...inputProps,
        },
      }}
    />
  )
}
