import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { Chip, Box, IconButton, Tooltip, Typography } from '@mui/material'
import Image from 'next/image'
import { makeStyles } from 'tss-react/mui'

import { CardHeader, CardTitle } from '@/components/ui/card'

import { GENIE_BOT_NAME, GENIE_BOT_TRANSPARENT_ICON, GENIE_VIEW_MODE } from './utils/constants'
import { useGenieChatContext } from './context/GenieChatContext'

const useStyles = makeStyles()((theme, _params, _classes) => ({
  header: {
    height: 60,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  headerRight: {
    display: 'flex',
    gap: theme.spacing(0.25),
  },
  actionItem: {
    width: 32,
    height: 32,
  },
}))

interface ChatHeaderProps {
  onClickSwitchViewMode: () => void
  viewMode: GENIE_VIEW_MODE
}

export const ChatHeader = ({ onClickSwitchViewMode, viewMode }: ChatHeaderProps) => {
  const { classes } = useStyles()
  const { createNewSession, sendMessageIsLoading } = useGenieChatContext()
  const isRegularViewMode = viewMode === GENIE_VIEW_MODE.REGULAR_VIEW

  return (
    <CardHeader className={classes.header}>
      <CardTitle className={classes.title}>
        <Box className={classes.headerLeft}>
          <Box sx={{ height: 24, width: 24, position: 'relative' }}>
            <Image src={GENIE_BOT_TRANSPARENT_ICON} layout="fill" />
          </Box>
          <Typography variant="subtitle1">{GENIE_BOT_NAME}</Typography>
          <BetaTag />
        </Box>
        <Box className={classes.headerRight}>
          <Tooltip title="New chat" placement="top" sx={{ color: (theme) => theme.palette.action.active }}>
            <IconButton className={classes.actionItem} onClick={createNewSession} disabled={sendMessageIsLoading}>
              <Image src="/icons/pencil-edit.svg" width={16} height={16} />
            </IconButton>
          </Tooltip>
          <Tooltip
            placement="top"
            sx={{ color: (theme) => theme.palette.action.active }}
            title={isRegularViewMode ? 'Full view' : 'Exit full view'}
          >
            <IconButton className={classes.actionItem} onClick={onClickSwitchViewMode} sx={{ padding: 0.5 }}>
              {isRegularViewMode ? <FullscreenIcon /> : <FullscreenExitIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </CardTitle>
    </CardHeader>
  )
}

export const BetaTag = () => {
  return <Chip sx={{ fontSize: 12 }} label="BETA" size="small" color="warning" variant="outlined" />
}
