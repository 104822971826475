import ActionButton from '@/components/button/actionButton'
import { WithModalParams, useModal, useModalsContext } from '@/components/state/context/modalsContext'
import {
  PlanReplacementCompareView,
  PlanReplacementCompareViewProps,
} from '@/pageComponents/orders/PlanReplacement/PlanReplacementCompareView'
import buildLogger from '@/util/logger'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useCallback } from 'react'
const logger = buildLogger('ViewPlanReplacementDialog')

type DialogProps = WithModalParams & PlanReplacementCompareViewProps
const formID = 'ViewPlanReplacementDialog'
function ViewPlanReplacementDialog({ open, onClose, originalPlan, replacementPlan }: DialogProps) {
  return (
    <Dialog open={!!open} onClose={onClose} maxWidth={'sm'} scroll={'paper'} fullWidth>
      <DialogTitle>Replacement Plan</DialogTitle>
      <DialogContent dividers>
        <PlanReplacementCompareView originalPlan={originalPlan} replacementPlan={replacementPlan} />
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        <ActionButton
          key={'Cancel'}
          buttonData={{
            label: 'Cancel',
            onClick: onClose,
            color: 'inherit',
            buttonProps: { variant: 'outlined' },
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export function useViewPlanReplacementDialog(modalProps: DialogProps) {
  const [, , toggleModal] = useModalsContext()

  useModal<DialogProps>({
    component: ViewPlanReplacementDialog,
    schema: {
      key: formID,
      modalProps,
    },
  })
  const toggleViewPlanReplacementDialog = useCallback(() => {
    toggleModal(formID)
  }, [toggleModal])

  return [toggleViewPlanReplacementDialog]
}
