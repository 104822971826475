import { useEffect, useState } from 'react'
import { billyRestClient } from '../data/billyRestClient'
import { useErrorHandler } from '../ErrorHandler/ErrorHandler'
import buildLogger from '@/util/logger'

export const logger = buildLogger('useServerMaintenanceMessage')

export type MaintenanceMessage = {
  message: string
}

export const useServerMaintenanceMessage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [maintenanceMessageList, setMaintenanceMessageList] = useState<any[]>([])
  const errorHandler = useErrorHandler()

  useEffect(() => {
    const getMaintenanceMessage = async () => {
      return await billyRestClient.request({
        method: 'GET',
        path: '/maintenance-message',
      })
    }

    getMaintenanceMessage()
      .then((response) => {
        setMaintenanceMessageList(response.data.map((msg: MaintenanceMessage) => msg.message))
      })
      .catch(logger.info)
  }, [errorHandler])

  return {
    messages: maintenanceMessageList,
  }
}
