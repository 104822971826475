import { useCallback, useState } from 'react'
import { lsGenieSessionIdKey } from '../utils/constants'

function getItem(localStorageKey: string, entityId: string | undefined) {
  const storedItem = localStorage.getItem(localStorageKey)

  if (storedItem === null || !entityId) {
    return null
  }

  try {
    const entitySessionIdsMap = JSON.parse(storedItem) || {}
    return entitySessionIdsMap[entityId]
  } catch (err) {
    console.error('Get stored chat session id: parsing error', err)
    return null
  }
}

export function useSavedEntitySessionId(entityId: string | undefined) {
  const [item] = useState(() => getItem(lsGenieSessionIdKey, entityId))

  const setLocalStorageItem = useCallback(
    (value: string) => {
      try {
        if (!entityId) {
          console.error('Please select an entity on top nav')
        } else if (typeof value !== 'string') {
          console.info('Chat session should a string')
        } else {
          const storedItem = localStorage.getItem(lsGenieSessionIdKey)
          const entitySessionIdsMap = storedItem === null ? {} : JSON.parse(storedItem)
          entitySessionIdsMap[entityId] = value
          localStorage.setItem(lsGenieSessionIdKey, JSON.stringify(entitySessionIdsMap))
        }
      } catch (err) {
        console.error(err)
      }
    },
    [entityId]
  )

  return [item, setLocalStorageItem] as const
}
