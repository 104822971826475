import { CircularProgress, Typography } from '@mui/material'
import { GenieOverlay } from './GenieOverlay'

export const GenieLoader = ({ message }: { message?: string }) => {
  return (
    <GenieOverlay>
      <Typography variant="body2" color="primary">
        {message || 'Loading...'}
      </Typography>
      <CircularProgress size={16} />
    </GenieOverlay>
  )
}
