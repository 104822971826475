/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountAddressJson {
  /** Address Line 1 of the Contact */
  streetAddressLine1?: string
  /** Address Line 2 of the Contact */
  streetAddressLine2?: string
  /** City of the Contact */
  city?: string
  /** State Code of the Contact (ISO 3166-2 state/province code). Currently supported for USA, Canada. For instance, for Arizona (USA), set state as AZ (not US-AZ).For British Columbia (Canada), set as BC (not CA-BC) */
  state?: string
  /** Country Code of the Contact ( ISO 3166 alpha-2 country code). */
  country?: string
  /** Zip or Postal Code of the Contact */
  zipcode?: string
}

export interface AccountReceivableContactJson {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  address: AccountAddressJson
}

export interface AccountJson {
  /** This is a system-generated Account ID */
  id?: string
  /** Account name */
  name: string
  /** (optional) Account Description */
  description?: string
  /** (optional) Phone number of the Account */
  phoneNumber?: string
  timezone?: string
  /** (optional) CRM ID of the Account */
  crmId?: string
  /** (optional) CRM type */
  crmType?: string
  /** (optional) ERP ID of this account */
  erpId?: string
  /** (optional) External ID of this account */
  externalId?: string
  /** The currency code (ISO 4217 format). If currency is not entered, USD will be applied by default. Possible currencies in Subskribe: AED AUD CAD CHF CZK DKK EUR GBP HKD INR MXN NOK NZD SAR SEK SGD TWD USD */
  currency?: string
  /** (optional) Indicates Tax Exemption Information. When the account does not qualify for Tax Exemption, add null in the request. When using Anrok Tax Integration, keep this value  null , as Anrok requires to upload Tax Exemption Certificate. */
  taxExemptionUseCode?:
    | 'A'
    | 'B'
    | 'C'
    | 'D'
    | 'E'
    | 'F'
    | 'G'
    | 'H'
    | 'I'
    | 'J'
    | 'K'
    | 'L'
    | 'M'
    | 'N'
    | 'P'
    | 'Q'
    | 'R'
  /** (optional) Input true, if this is a Reseller Account. Default is false */
  isReseller?: boolean
  /** (optional) Input true, if this Account will have an automatic payment. Default is false */
  hasAutomaticPayment?: boolean
  /** (optional) Input true, if this account needs to be excluded from Batch Operations like Bulk  Invoice Run, Invoice Generation. Default is false. This is an optional field. */
  excludeFromBatchOperations?: boolean
  /** (optional) Input true, if this account needs to be excluded from Dunning emails. Default is false. This is an optional field. */
  excludeFromDunning?: boolean
  /**
   * (optional) Supported payment types for this Account. This field can include various payment types such as ACH, Card, Check, Wire, and Invoice. This is an optional field.
   * @uniqueItems true
   */
  supportedPaymentTypes?: ('ACH' | 'CARD' | 'CHECK' | 'WIRE' | 'INVOICE' | 'EXTERNAL')[]
  /** (optional) Address of the Account */
  address?: AccountAddressJson
  /**
   * (optional) will be auto-populated as today's date
   * @format int64
   */
  updatedOn?: number
  /** (optional) Use this option to create custom fields for the Account object if you need to collect Account-specific information, such as region, age, or gender. */
  customFields?: Record<string, CustomFieldValue>
  /**
   * (optional) Enter the entity ID where this Account belongs to
   * @uniqueItems true
   */
  entityIds?: string[]
}

export interface CustomFieldDefault {
  value?: string
  selections?: string[]
}

export interface CustomFieldValue {
  type?: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  name: string
  label: string
  value?: string
  selections?: string[]
  options?: string[]
  required?: boolean
  source?: 'USER' | 'SYSTEM'
  defaultValue?: CustomFieldDefault
}

export interface PaginatedAccountsResponse {
  data?: AccountJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface MetricsJson {
  tcv?: number
  recurringTotal?: number
  nonRecurringTotal?: number
  arr?: number
  entryArr?: number
  exitArr?: number
  averageArr?: number
  arrTrend?: TimeSeriesAmountJson[]
  deltaTcv?: number
  deltaArr?: number
}

export interface TimeSeriesAmountJson {
  /** @format int64 */
  instant?: number
  amount?: number
}

export interface AccountContactJson {
  /** This is a system-generated Account Contact ID */
  id?: string
  /** Uniquely identifies the Account */
  accountId: string
  /** First Name of the Contact */
  firstName?: string
  /** (optional) Last Name of the Contact */
  lastName?: string
  /** Email of the Contact */
  email?: string
  /** (optional) Phone Number of the Contact */
  phoneNumber?: string
  /** (optional) Title of the Contact */
  title?: string
  address?: AccountAddressJson
  externalId?: string
  erpId?: string
  fullName?: string
}

export interface ErpInputJson {
  /**
   * @minLength 0
   * @maxLength 100
   */
  erpId?: string
}

export interface CrmAccountImportResponse {
  accountUrl?: string
  accountId?: string
}

export interface AccountingPeriod {
  /** @format int64 */
  startDate?: number
  /** @format int64 */
  endDate?: number
  deferredRevenueBalance?: number
  status?: 'OPEN' | 'CLOSE_IN_PROGRESS' | 'CLOSED' | 'UPCOMING'
  syncStatus?: 'NONE' | 'WAITING' | 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED'
  openedBy?: string
  /** @format int64 */
  openedOn?: number
  closedBy?: string
  /** @format int64 */
  closedOn?: number
  calculation?: AccountingPeriodCalculation
  openedByUser?: UserJson
  closedByUser?: UserJson
  synthetic?: boolean
  id?: string
  entityId?: string
}

export interface AccountingPeriodCalculation {
  deferredRevenueStartingBalance?: number
  deferredRevenueEndingBalance?: number
  additionalRevenue?: number
  recognizedRevenue?: number
  unrecognizedRevenue?: number
  /** @format int32 */
  unrecognizedTransactionCount?: number
}

export interface ApprovalSegmentJson {
  id?: string
  name?: string
  description?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
}

export interface EntityRef {
  entityId?: string
  displayId?: string
  name?: string
}

export interface UserGroupJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  description?: string
  users?: string[]
  externalId?: string
}

export interface UserJson {
  id?: string
  displayName?: string
  title?: string
  email?: string
  phoneNumber?: string
  state?: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  role?:
    | 'ADMIN'
    | 'FINANCE'
    | 'SALES'
    | 'SALES_MANAGER'
    | 'ACCOUNTANT'
    | 'BILLING_CLERK'
    | 'REVENUE_CLERK'
    | 'READ_ONLY'
    | 'EXECUTIVE'
    | 'CRM'
    | 'IMPORT'
    | 'BILLY_ADMIN'
    | 'BILLY_ENGINEER'
    | 'BILLY_JOB'
  ssoOnly?: boolean
  tenantName?: string
  cognitoUserStatus?:
    | 'UNCONFIRMED'
    | 'CONFIRMED'
    | 'ARCHIVED'
    | 'COMPROMISED'
    | 'UNKNOWN'
    | 'RESET_REQUIRED'
    | 'FORCE_CHANGE_PASSWORD'
    | 'EXTERNAL_PROVIDER'
    | 'UNKNOWN_TO_SDK_VERSION'
  userGroups?: UserGroupJson[]
  approvalSegments?: ApprovalSegmentJson[]
  hasAllEntitiesAccess?: boolean
  availableEntities?: EntityRef[]
  entityIds?: string[]
  externalId?: string
}

export interface MediaType {
  type?: string
  subtype?: string
  parameters?: Record<string, string>
  wildcardType?: boolean
  wildcardSubtype?: boolean
}

export interface OutboundEvent {
  name?: string
  comment?: string
  id?: string
  mediaType?: MediaType
  data?: object
  /** @format int64 */
  reconnectDelay?: number
  genericType?: Type
  reconnectDelaySet?: boolean
}

export interface Type {
  typeName?: string
}

export interface Message {
  role?: string
  message?: string
  /** @format int64 */
  createdAt?: number
}

export interface SubscriptionChargeAlias {
  /**
   * @minLength 5
   * @maxLength 100
   */
  aliasId?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  subscriptionId?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  chargeId?: string
  /** @format int64 */
  createdOn?: number
}

export interface AnrokIntegrationInput {
  apiKey?: string
}

export interface AccountAddress {
  /** @format uuid */
  id?: string
  addressId?: string
  streetAddressLine1?: string
  streetAddressLine2?: string
  city?: string
  state?: string
  country?: string
  zipcode?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
}

export interface Integration {
  targetService?: 'QUICKBOOKS' | 'XERO' | 'MERGE' | 'NETSUITE' | 'ANROK'
  clientId?: string
  environment?: string
  realmId?: string
  status?: 'PENDING' | 'ACTIVE' | 'DELETED'
  maskedApiKey?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  deleted?: boolean
  /** @format uuid */
  id?: string
}

export interface ApprovalFlowJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  description?: string
  status?: 'ACTIVE' | 'INACTIVE'
  states?: ApprovalStateJson[]
  transitionRules?: ApprovalTransitionRuleJson[]
}

export interface ApprovalRuleConditions {
  orderCondition?: string
  orderLineCondition?: string
}

export interface ApprovalStateActionJson {
  emailGroupId?: string
}

export interface ApprovalStateJson {
  id?: string
  name?: string
  approvalGroupId?: string
  approverId?: string
  approverType?: 'USER' | 'USER_GROUP' | 'ROLE'
  action?: ApprovalStateActionJson
  escalationPolicyId?: string
}

export interface ApprovalTransitionRuleJson {
  id?: string
  name?: string
  fromState?: string
  toState?: string
  condition?: string
  ruleConditions?: ApprovalRuleConditions
}

export interface ApprovalMatrixImportDataJson {
  id?: string
  fileName?: string
  uploadedBy?: string
  status?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
}

export interface ApprovalMatrixImportPreview {
  id?: string
  uploadedBy?: string
  segmentsToAdd?: string[]
  segmentsToDelete?: string[]
  approvalRoleSegmentChanges?: ApprovalRoleSegmentChange[]
}

export interface ApprovalRoleSegmentChange {
  roleName?: string
  segmentName?: string
  previousUserOrGroupName?: string
  newUserOrGroupName?: string
}

export interface ApprovalRoleJson {
  id?: string
  name?: string
  description?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
}

export interface Attachment {
  /** @format uuid */
  id: string
  name: string
  description?: string
  status?: 'UPLOADING' | 'UPLOADED'
  accountId?: string
  tag?: 'MASTER_SUBSCRIPTION_AGREEMENT' | 'STATEMENT_OF_WORK' | 'ORDER_FORM' | 'OTHER'
  inUse?: boolean
  isDeleted?: boolean
  /** @format int64 */
  createdOn: number
  /** @format int64 */
  updatedOn?: number
}

export interface AuthSamlIntegrationJson {
  attributeMapping?: Record<string, string>
  metadataUrl?: string
  providerName?: string
}

export interface AutomatedInvoiceRule {
  name?: string
  /** @format uuid */
  id?: string
  enabled?: boolean
  description?: string
  /** @uniqueItems true */
  entityIds?: string[]
  cronExpression?: string
  automatedInvoiceRuleId?: string
  /** @format int64 */
  updatedOn?: number
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  lastExecutionDate?: number
  cronExpressionMeaning?: string
  /** @format int64 */
  nextExecutionDate?: number
  /** @format int32 */
  targetDuration?: number
  /** @format int32 */
  invoiceDuration?: number
  includeUsageCharge?: boolean
  includeNonUsageCharge?: boolean
  autoPostInvoice?: boolean
  autoEmailInvoice?: boolean
  /** @format int64 */
  firstExecutionDate?: number
}

export interface AutomatedInvoiceRuleRequestJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name: string
  description?: string
  cronExpression: string
  /** @format int64 */
  firstExecutionDate: number
  /** @format int64 */
  lastExecutionDate?: number
  /** @format int32 */
  targetDuration?: number
  /** @format int32 */
  invoiceDuration?: number
  includeUsageCharge?: boolean
  includeNonUsageCharge?: boolean
  autoPostInvoice?: boolean
  autoEmailInvoice?: boolean
  enabled?: boolean
}

export interface AvalaraIntegration {
  accountId?: string
  companyCode?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  sandboxEnvironment?: boolean
  integrationId?: string
  shouldCommitDocuments?: boolean
}

export interface AvalaraIntegrationInput {
  /**
   * @minLength 0
   * @maxLength 1024
   */
  accountId?: string
  /**
   * @minLength 0
   * @maxLength 25
   */
  companyCode?: string
  accountLicenseKey?: string
  sandboxEnvironment?: boolean
  shouldCommitDocuments?: boolean
}

export interface ChargeJson {
  id?: string
  name?: string
  displayName?: string
  description?: string
  /** @format uuid */
  taxRateId?: string
  /** @format uuid */
  unitOfMeasureId?: string
  isRenewable?: boolean
  isListPriceEditable?: boolean
  /** @format int64 */
  minQuantity?: number
  /** @format int64 */
  defaultQuantity?: number
  /** @format int64 */
  maxQuantity?: number
  externalId?: string
  minAmount?: number
  maxAmount?: number
  recognitionRuleId?: string
  erpId?: string
  itemCode?: string
  planId?: string
  amount?: number
  type: 'ONE_TIME' | 'RECURRING' | 'USAGE' | 'PREPAID' | 'PERCENTAGE_OF'
  chargeModel: 'PER_UNIT' | 'VOLUME' | 'TIERED' | 'FLAT_FEE' | 'BLOCK' | 'RATE_CARD_LOOKUP'
  recurrence?: RecurrenceJson
  priceTiers?: PriceTierJson[]
  isDrawdown?: boolean
  minimumCommitBaseChargeId?: string
  overageBaseChargeId?: string
  isCustom?: boolean
  percent?: number
  percentDerivedFrom?: 'LIST_AMOUNT' | 'SELL_AMOUNT'
  targetPlanIds?: string[]
  ledgerAccountMapping?: LedgerAccountMapping
  /** @format int64 */
  durationInMonths?: number
  isEventBased?: boolean
  rateCardId?: string
  billingTerm?: 'UP_FRONT' | 'IN_ARREARS'
  billingCycle?: 'DEFAULT' | 'PAID_IN_FULL' | 'MONTH' | 'QUARTER' | 'SEMI_ANNUAL' | 'YEAR'
  shouldTrackArr?: boolean
  custom?: boolean
  drawdown?: boolean
  eventBased?: boolean
  listPriceEditable?: boolean
}

export interface LedgerAccountMapping {
  taxLiabilityAccountId?: string
  deferredRevenueAccountId?: string
  recognizedRevenueAccountId?: string
  contractAssetAccountId?: string
  ledgerAccountIds?: string[]
}

export interface PriceTierJson {
  untilQuantity: string
  amount: number
  overage?: number
}

export interface RecurrenceJson {
  cycle: 'MONTH' | 'QUARTER' | 'SEMI_ANNUAL' | 'YEAR' | 'PAID_IN_FULL'
  /**
   * @format int32
   * @min 1
   */
  step: number
}

export interface CreditMemoBalanceJson {
  accountId?: string
  /** @format uuid */
  creditMemoId?: string
  balance?: number
  /** @format int64 */
  updatedOn?: number
}

export interface CreditMemoJson {
  lineItems: CreditMemoLineItemJson[]
  accountId: string
  amount: number
  notes?: string
  creditMemoNumber: string
  currencyCode?: string
  status: 'DRAFT' | 'POSTED' | 'CLOSED'
  /** @format int64 */
  postedDate?: number
  /** @format int64 */
  creditMemoDate?: number
  createdFrom?: string
  /** @format int64 */
  createdOn: number
  /** @format int64 */
  updatedOn: number
  erpId?: string
}

export interface CreditMemoLineItemJson {
  tenantId: string
  chargeId?: string
  amount?: number
  taxAmount?: number
  /** @format int64 */
  startDate?: number
  /** @format int64 */
  endDate?: number
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
}

export interface CreditMemoPaginationResponseJson {
  data?: CreditMemoJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface CreditMemoLineItemRequestJson {
  chargeId?: string
  amount?: number
  /** @format int64 */
  startDate?: number
  /** @format int64 */
  endDate?: number
}

export interface StandaloneCreditMemoRequest {
  lineItems: CreditMemoLineItemRequestJson[]
  accountId: string
  entityId?: string
  amount: number
  notes?: string
  currencyCode: string
  status: 'DRAFT' | 'POSTED' | 'CLOSED'
  /** @format int64 */
  postedDate?: number
  /** @format int64 */
  creditMemoDate?: number
  billingContactId?: string
  creditReason?: string
  /** @format int64 */
  startDate?: number
  /** @format int64 */
  endDate?: number
}

export interface TenantCreditMemoConfigurationJson {
  creditMemoNumberPrefix?: string
  creditMemoNumberScheme?: string
  /** @format int32 */
  creditMemoNumberLength?: number
  /** @format int32 */
  creditMemoNextNumber?: number
}

export interface CustomFieldDefinitionCreateInput {
  parentObjectType:
    | 'ACCOUNT'
    | 'ORDER'
    | 'ORDER_ITEM'
    | 'PLAN'
    | 'CHARGE'
    | 'INVOICE'
    | 'SALES_ROOM'
    | 'SUBSCRIPTION_ITEM'
    | 'OPPORTUNITY'
  fieldType: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  fieldName?: string
  fieldLabel?: string
  options?: string[]
  required?: boolean
  source?: 'USER' | 'SYSTEM'
  defaultValue?: CustomFieldDefault
}

export interface CustomFieldDefinitionJson {
  id?: string
  parentObjectType?:
    | 'ACCOUNT'
    | 'ORDER'
    | 'ORDER_ITEM'
    | 'PLAN'
    | 'CHARGE'
    | 'INVOICE'
    | 'SALES_ROOM'
    | 'SUBSCRIPTION_ITEM'
    | 'OPPORTUNITY'
  fieldType?: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  fieldName?: string
  fieldLabel?: string
  options?: string[]
  required?: boolean
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  defaultValue?: CustomFieldDefault
}

export interface CustomFieldDefinitionUpdateInput {
  fieldName?: string
  fieldLabel?: string
  fieldType?: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  options?: string[]
  defaultValue?: CustomFieldDefault
}

export interface CustomFieldUpdateInput {
  value?: string
  selections?: string[]
}

export interface DiscountJson {
  name?: string
  percent?: number
  discountAmount?: number
  status?: 'ACTIVE' | 'DEPRECATED'
  discountedPrice?: number
}

export interface DocuSignIntegrationResponseJson {
  clientId?: string
  integrationId?: string
  environment?: 'DEMO' | 'PRODUCTION'
  isCompleted?: boolean
}

export interface DocuSignIntegrationRequestJson {
  clientId?: string
  clientSecret?: string
  environment?: 'DEMO' | 'PRODUCTION'
}

export interface DocumentTemplateJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  sectionUuid?: string
  type: 'ORDER' | 'INVOICE' | 'CREDIT_MEMO' | 'EMAIL' | 'UPSELL_EARLY_RENEWAL' | 'DUNNING' | 'CANCEL_AND_RESTRUCTURE'
  description?: string
  content?: string
  status?: 'DRAFT' | 'ACTIVE' | 'DEPRECATED'
  isUserSelectable?: boolean
  /** @format int32 */
  version?: number
  hasNewerVersion?: boolean
}

export interface DocumentTemplateRequestJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  sectionUuid?: string
  type: 'ORDER' | 'INVOICE' | 'CREDIT_MEMO' | 'EMAIL' | 'UPSELL_EARLY_RENEWAL' | 'DUNNING' | 'CANCEL_AND_RESTRUCTURE'
  description?: string
  content?: string
  status?: 'DRAFT' | 'ACTIVE' | 'DEPRECATED'
  isUserSelectable?: boolean
}

export interface DunningSettingJson {
  isEnabled: boolean
  dunningTypeMap?: Record<string, boolean>
}

export interface CompanyContactJson {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  address: AccountAddressJson
}

export interface EntityJson {
  entityId?: string
  displayId?: string
  name?: string
  prorationScheme?: 'FIXED_DAYS' | 'CALENDAR_DAYS'
  prorationMode?: 'NORMALIZED' | 'EXACT_DAYS' | 'EXACT'
  invoiceConfigId?: string
  invoiceConfig?: NumberConfig
  creditMemoConfig?: NumberConfig
  timezone?: string
  functionalCurrency?: string
  wireInstruction?: string
  companyContact?: CompanyContactJson
  accountReceivableContact?: AccountReceivableContactJson
  erpId?: string
}

export interface NumberConfig {
  configId?: string
  scheme?: 'SEQUENCE' | 'PSEUDO_RANDOM'
  prefix?: string
  /** @format int32 */
  length?: number
  /** @format int64 */
  nextSequenceNumber?: number
}

export interface AccountContact {
  /** @format uuid */
  id?: string
  contactId?: string
  /**
   * @minLength 0
   * @maxLength 36
   */
  externalId?: string
  /**
   * @minLength 0
   * @maxLength 100
   */
  erpId?: string
  accountId?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  firstName?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  lastName?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  email?: string
  emailVerified?: boolean
  /**
   * @minLength 0
   * @maxLength 255
   */
  phoneNumber?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  title?: string
  addressId?: string
  address?: AccountAddress
  state?: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  fullName?: string
}

export interface Entity {
  /** @format uuid */
  id?: string
  tenantId?: string
  entityId?: string
  displayId?: string
  name?: string
  prorationScheme?: 'FIXED_DAYS' | 'CALENDAR_DAYS'
  prorationMode?: 'NORMALIZED' | 'EXACT_DAYS' | 'EXACT'
  invoiceConfigId?: string
  invoiceConfig?: NumberConfig
  timezone?: TimeZone
  functionalCurrency?: string
  wireInstruction?: string
  companyContact?: AccountContact
  accountReceivableContact?: AccountContact
  erpId?: string
}

export interface TimeZone {
  displayName?: string
  id?: string
  /** @format int32 */
  dstsavings?: number
  /** @format int32 */
  rawOffset?: number
}

export interface DataImport {
  importId?: string
  /** @uniqueItems true */
  entityIds?: string[]
  fileName?: string
  fileType?: 'CSV'
  /** @format int64 */
  fileSizeBytes?: number
  /** @format int64 */
  rowCount?: number
  importedBy?: string
  /** @format int64 */
  importedOn?: number
  domain?:
    | 'CATALOG'
    | 'ACCOUNT'
    | 'ORDER'
    | 'USAGE'
    | 'BULK_ACCOUNT_UPDATE'
    | 'BULK_ORDER_UPDATE'
    | 'BULK_SUBSCRIPTION_UPDATE'
    | 'BULK_PRODUCT_UPDATE'
    | 'BULK_PLAN_UPDATE'
  operation?: 'CREATE' | 'UPDATE'
  status?: 'VALIDATED' | 'PROCESSING' | 'SUCCESSFUL' | 'FAILED' | 'PARTIALLY_SUCCESSFUL'
  /** @format int64 */
  completedOn?: number
}

export interface FlatfileWorkbookResponse {
  data?: FlatfileWorkbookResponseData
}

export interface FlatfileWorkbookResponseData {
  spaceId?: string
  environmentId?: string
}

export interface EmailNotifiersList {
  toIds?: string[]
  ccIds?: string[]
  bccIds?: string[]
}

export interface InvoiceItemJson {
  id?: string
  planId?: string
  chargeId?: string
  orderId?: string
  orderLineItemId?: string
  subscriptionChargeId?: string
  subscriptionChargeGroupId?: string
  listAmount?: number
  discountAmount?: number
  amount?: number
  taxAmount?: number
  taxRate?: TaxRateJson
  listUnitPrice?: number
  sellUnitPrice?: number
  /** @format int64 */
  quantity?: number
  /** @format int64 */
  drawdownQuantityUsed?: number
  /** @format int64 */
  drawdownQuantityRemaining?: number
  /** @format int64 */
  periodStartDate?: number
  /** @format int64 */
  periodEndDate?: number
  isBilled?: boolean
  functionalListAmount?: number
  functionalDiscountAmount?: number
  functionalAmount?: number
  functionalTaxAmount?: number
}

export interface InvoiceJson {
  invoiceNumber?: string
  /** @format int64 */
  postedDate?: number
  /** @format int64 */
  invoiceDate?: number
  /** @format int64 */
  voidedDate?: number
  /** @format int64 */
  dueDate?: number
  currency?: string
  paymentTerm?: string
  totalDiscount?: number
  subTotal?: number
  taxTotal?: number
  taxTransactionCode?: string
  total?: number
  subscriptionId?: string
  accountId?: string
  invoiceItems?: InvoiceItemJson[]
  billingContact?: AccountContactJson
  shippingContact?: AccountContactJson
  status?: 'DRAFT' | 'POSTED' | 'PAID' | 'CONVERTED' | 'VOIDED'
  purchaseOrderNumber?: string
  purchaseOrderRequired?: boolean
  note?: string
  emailNotifiersList?: EmailNotifiersList
  erpId?: string
  generationMethod?:
    | 'USER_INITIATED'
    | 'API_INITIATED'
    | 'BULK_INVOICE_RUN'
    | 'AUTOMATED_INVOICE_JOB'
    | 'RULE_DRIVEN_INVOICE_JOB'
  generatedBy?: string
  exchangeRateId?: string
  exchangeRate?: number
  /** @format int64 */
  exchangeRateDate?: number
  functionalTotalDiscount?: number
  functionalSubTotal?: number
  functionalTaxTotal?: number
  functionalTotal?: number
}

export interface TaxRateJson {
  /** @format uuid */
  id?: string
  name?: string
  description?: string
  taxPercentage?: number
  taxCode?: string
  taxInclusive?: boolean
  status: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  inUse?: boolean
}

export interface InvoiceItemPreviewJson {
  orderLineItemId?: string
  amount?: number
  listAmount?: number
  discountAmount?: number
  listUnitPrice?: number
  sellUnitPrice?: number
}

export interface InvoicePreviewJson {
  orderId?: string
  lineItems?: InvoiceItemPreviewJson[]
  invoiceItems?: InvoiceItemJson[]
  total?: number
  totalDiscount?: number
  /** @format int64 */
  createdOn?: number
}

export interface InvoiceJsonPaginationResponse {
  data?: InvoiceJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface InvoiceBalanceJson {
  accountId?: string
  invoiceNumber?: string
  balance?: number
  /** @format int64 */
  updatedOn?: number
}

export interface UpdateInvoiceRequest {
  /** @format int64 */
  invoiceDate: number
  /** @format int64 */
  dueDate?: number
  /**
   * @minLength 0
   * @maxLength 1000
   */
  note?: string
  /**
   * @minLength 0
   * @maxLength 255
   */
  purchaseOrderNumber?: string
  billingContactId?: string
  emailNotifiersList?: EmailNotifiersList
}

export interface VoidInvoiceRequest {
  /** @format int64 */
  voidDate: number
  invoiceBalance: number
}

export interface BillingEventEntry {
  id?: string
  /** @format int64 */
  triggerOn?: number
  amount?: number
  /** @format int64 */
  createdOn?: number
}

export interface BulkInvoiceRun {
  entityId?: string
  name?: string
  description?: string
  /** @format int64 */
  targetDate?: number
  /** @format int64 */
  invoiceDate?: number
  chargeInclusionOption?: 'INCLUDE_USAGE' | 'EXCLUDE_USAGE' | 'ONLY_USAGE'
  id?: string
  bulkInvoiceRunId?: string
  automatedInvoiceRuleId?: string
  status?: 'QUEUED' | 'CREATED' | 'PROCESSING' | 'FAILED' | 'COMPLETED' | 'RUNNING'
  phase?:
    | 'INVOICE_GENERATION_NOT_STARTED'
    | 'NO_INVOICES_FOUND'
    | 'INVOICES_GENERATING'
    | 'INVOICES_GENERATED'
    | 'INVOICES_POSTING'
    | 'INVOICES_POSTED'
    | 'INVOICES_EMAILING'
    | 'INVOICES_EMAILED'
  invoiceSelector?: BulkInvoiceRunSelector
  failureReason?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
}

export interface BulkInvoiceRunExclusions {
  accounts?: AccountJson[]
  /** @uniqueItems true */
  accountIds?: string[]
}

export interface BulkInvoiceRunSelector {
  exclusions?: BulkInvoiceRunExclusions
}

export interface BulkInvoiceRunItem {
  /** @format uuid */
  id?: string
  runId?: string
  accountId?: string
  accountName?: string
  subscriptionId?: string
  draftInvoiceNumber?: string
  postedInvoiceNumber?: string
  /** @format int64 */
  invoiceCreatedOn?: number
  invoiceCurrencyCode?: string
  invoiceAmount?: number
  failureReason?: string
  excludedForPosting?: boolean
  excludedForEmailing?: boolean
  emailSent?: boolean
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  draftInvoiceNotGenerated?: boolean
  invoiceNotPosted?: boolean
  draftInvoiceGenerated?: boolean
  invoicePosted?: boolean
  includedForEmailing?: boolean
  includedForPosting?: boolean
}

export interface InvoiceNumberPrefix {
  prefix?: string
}

export interface TenantInvoiceConfig {
  invoiceConfigId?: string
  invoiceNumberPrefix?: InvoiceNumberPrefix
  invoiceNumberScheme?: 'SEQUENCE' | 'PSEUDO_RANDOM'
  /** @format int64 */
  invoiceNextNumber?: number
  /** @format int32 */
  invoiceNumberLength?: number
}

export interface BillingEventInput {
  subscriptionId?: string
  subscriptionChargeId?: string
  /** @format int64 */
  triggerOn?: number
  amount: number
}

export interface BulkInvoiceRunInput {
  entityId?: string
  name?: string
  description?: string
  /** @format int64 */
  targetDate?: number
  /** @format int64 */
  invoiceDate?: number
  chargeInclusionOption?: 'INCLUDE_USAGE' | 'EXCLUDE_USAGE' | 'ONLY_USAGE'
}

export interface PaymentBankAccountJson {
  id?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  entityIds: string[]
  externalId?: string
  name?: string
  description?: string
  currencyCode: string
  cashLedgerAccountId: string
  expenseLedgerAccountId: string
  status: 'DRAFT' | 'ACTIVE' | 'DEPRECATED'
  hasExistingPayments?: boolean
}

export interface SettlementApplication {
  /** @format uuid */
  id?: string
  entityId?: string
  customerAccountId?: string
  invoiceNumber?: string
  paymentId?: string
  creditMemoNumber?: string
  applicationType?: 'PAYMENT' | 'VOID_PAYMENT' | 'CREDIT' | 'UNAPPLY_CREDIT'
  amount?: number
  note?: string
  /** @format int64 */
  appliedOn?: number
  exchangeRateId?: string
  exchangeRate?: number
  /** @format int64 */
  exchangeRateDate?: number
  functionalAmount?: number
  /** @format int64 */
  createdOn?: number
  status?: 'ATTEMPTING_PAYMENT_COLLECTION' | 'APPLIED_PAYMENT' | 'FAILED'
  /** @format uuid */
  negatedSettlementId?: string
}

export interface ApplyPaymentRequest {
  invoiceNumber?: string
  invoiceAmount?: string
  /** @format uuid */
  paymentMethodId?: string
  paymentBankAccountId?: string
  paymentType?: 'ACH' | 'CARD' | 'CHECK' | 'WIRE' | 'INVOICE' | 'EXTERNAL'
  amount?: number
  bankFee?: number
  note?: string
  /** @format int64 */
  paymentDate?: number
}

export interface BulkPaymentUploadData {
  currencyCode?: string
  amount?: number
  failed?: boolean
  invoiceNumber?: string
  note?: string
  /** @format int64 */
  paymentDate?: number
  /** @format uuid */
  paymentMethodId?: string
  bankFee?: number
  paymentBankAccountId?: string
  paymentMethod?: string
  failureReason?: string
  originalInvoiceBalance?: number
  newInvoiceBalance?: number
}

export interface BulkPaymentUploadResult {
  /** @format int32 */
  paymentsRequestCount?: number
  /** @format int32 */
  failedPaymentsCount?: number
  bulkPaymentUploadData?: BulkPaymentUploadData[]
}

export interface CreditMemoApplicationJson {
  /** @format uuid */
  id?: string
  invoiceNumber?: string
  invoiceAmount?: string
  creditMemoNumber: string
  amount: number
  note?: string
}

export interface CreditMemoUnapplicationJson {
  invoiceNumber?: string
  invoiceBalanceAmount?: string
  creditMemoNumber: string
  creditMemoBalanceAmount?: string
  /** @format uuid */
  settlementToUnapplyId: string
  note?: string
}

export interface AccountingEvent {
  id?: string
  entityId?: string
  /** @format int64 */
  accountingDate?: number
  accountId?: string
  subscriptionId?: string
  sourceTransactionType?:
    | 'INVOICE_POSTED'
    | 'PAYMENT_PROCESSED'
    | 'CREDIT_MEMO_POSTED'
    | 'REVENUE_RECOGNIZED'
    | 'PAYMENT_VOIDED'
    | 'INVOICE_VOIDED'
    | 'REALIZED_GAIN_LOSS_POSTED'
  sourceTransactionId?: string
  sourceEventId?: string
  /** @format int64 */
  sourceEventTimestamp?: number
  sourceEventPayload?: ByteBuffer
  sourceEventMetadata?: Record<string, string>
  /** @format int64 */
  sourceEventSequenceNumber?: number
}

export interface AccountingEventPaginatedResponse {
  data?: AccountingEvent[]
  /** @format int32 */
  count?: number
  pageToken?: string
}

export interface ByteBuffer {
  /** @format int32 */
  short?: number
  char?: string
  /** @format int32 */
  int?: number
  /** @format int64 */
  long?: number
  /** @format float */
  float?: number
  /** @format double */
  double?: number
  direct?: boolean
  readOnly?: boolean
}

export interface ExternalArrScheduleJson {
  id?: string
  externalId?: string
  /** @format int64 */
  startDate: number
  /** @format int64 */
  endDate: number
  category?:
    | 'OPENING_BALANCE'
    | 'NEW'
    | 'ADD_ON'
    | 'RENEWAL_ADD_ON'
    | 'UPSELL'
    | 'MARKUP'
    | 'RENEWAL_UPSELL'
    | 'RENEWAL_MARKUP'
    | 'DOWNSELL'
    | 'MARKDOWN'
    | 'RENEWAL_DOWNSELL'
    | 'RENEWAL_MARKDOWN'
    | 'TERMINATION'
    | 'EXPIRATION'
    | 'PENDING_RENEWAL'
    | 'REACTIVATION'
    | 'DEBOOK'
  amount: number
  /** @format int64 */
  quantity: number
  previousScheduleId?: string
  metadata?: string
  submittedBy?: string
}

export interface NotificationTargetAndSubscriptions {
  name?: string
  description?: string
  notificationId?: string
  notificationTargetType?: 'SLACK' | 'WEBHOOK'
  notificationTarget?: string
  subscribedEvents?: (
    | 'INVOICE_POSTED'
    | 'SUBSCRIPTION_CREATED'
    | 'SUBSCRIPTION_ACTIVATING'
    | 'SUBSCRIPTION_CHARGE_CHANGE'
    | 'SUBSCRIPTION_CANCELLING'
    | 'SUBSCRIPTION_EXPIRING'
    | 'ORDER_SUBMITTED'
    | 'ORDER_EXECUTED'
    | 'ORDER_APPROVED'
    | 'ESIGNATURE_COMPLETED'
  )[]
}

export interface OpportunityJson {
  name?: string
  id?: string
  entityId?: string
  type?: string
  stage?: string
  crmId?: string
  accountId?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  isClosed?: boolean
  primaryOrderId?: string
  opportunityId?: string
}

export interface OpportunityRestJson {
  name?: string
  id?: string
  entityId?: string
  type?: string
  stage?: string
  crmId?: string
  accountId?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  isClosed?: boolean
  primaryOrderId?: string
}

export interface OpportunityPaginationResponse {
  data?: OpportunityJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface AttributeReference {
  attributeDefinitionId?: string
  attributeValue?: string
}

export interface CustomFieldEntry {
  id?: string
  type?: 'STRING' | 'PICKLIST' | 'MULTISELECT_PICKLIST'
  name: string
  label: string
  value?: string
  selections?: string[]
  options?: string[]
  required?: boolean
  source?: 'USER' | 'SYSTEM'
  defaultValue?: CustomFieldDefault
}

export interface DiscountDetailJson {
  name?: string
  percent?: number
  discountAmount?: number
  status?: 'ACTIVE' | 'DEPRECATED'
  discountedPrice?: number
  amount?: number
}

export interface OrderJson {
  /** Uniquely identifies the Order. */
  id?: string
  /** Entity ID associated with the order. */
  entityId?: string
  /** External identifier for the order. */
  externalId?: string
  /** Name of the Order. */
  name?: string
  /** ID of the account associated with the order. */
  accountId?: string
  /** Type of the order. Possible values: NEW, AMENDMENT, RENEWAL, CHANGE, CANCEL. */
  orderType: 'NEW' | 'AMENDMENT' | 'CANCEL' | 'RENEWAL' | 'RESTRUCTURE'
  /** Currency in which the order is billed. Default is USD. */
  currency?: string
  /** Payment terms for the order. */
  paymentTerm?: string
  /** The associated subscription ID. */
  subscriptionId?: string
  /**
   * Target version of the subscription.
   * @format int32
   */
  subscriptionTargetVersion?: number
  /** Shipping contact ID for the order. */
  shippingContactId?: string
  /** Billing contact ID for the order. */
  billingContactId?: string
  /** List of predefined discounts to be applied. */
  predefinedDiscounts?: TenantDiscountJson[]
  /** Line items included in the order. */
  lineItems?: OrderLineItemJson[]
  /** Net effect of line items. */
  lineItemsNetEffect?: OrderLineItemJson[]
  /**
   * The start date of the order.
   * @format int64
   */
  startDate: number
  /**
   * The end date of the order.
   * @format int64
   */
  endDate?: number
  /** The term length for the order. */
  termLength?: RecurrenceJson
  /** 	The billing cycle. Possible values: MONTH, YEAR, etc. */
  billingCycle?: RecurrenceJson
  /** Billing term for the order. Possible values: UP_FRONT, RECURRING. */
  billingTerm?: 'UP_FRONT' | 'IN_ARREARS'
  /**
   * Anchor date for billing in Unix timestamp.
   * @format int64
   */
  billingAnchorDate?: number
  /** Total Amount of the order. */
  totalAmount?: number
  totalListAmount?: number
  totalListAmountBeforeOverride?: number
  /** Estimated taxes for the order. */
  taxEstimate?: number
  /** Current status of the order. */
  status: 'DRAFT' | 'SUBMITTED' | 'APPROVED' | 'EXECUTED' | 'CANCELLED' | 'EXPIRED'
  /**
   * The date the order was executed.
   * @format int64
   */
  executedOn?: number
  /**
   * Timestamp of the last update to the order.
   * @format int64
   */
  updatedOn?: number
  executedOnFormatted?: string
  /** Ramp interval. */
  rampInterval?: number[]
  /** Order form template IDs associated with the order. */
  orderFormTemplateIds?: string[]
  /** Terms and conditions of the order. */
  orderTerms?: OrderTerms[]
  /** Salesforce opportunity ID associated with the order. */
  sfdcOpportunityId?: string
  /** Indicates if the order is the primary order for the Salesforce opportunity. */
  isPrimaryOrderForSfdcOpportunity?: boolean
  /** Salesforce opportunity name. */
  sfdcOpportunityName?: string
  /** Type of Salesforce opportunity. */
  sfdcOpportunityType?: string
  /** Stage of the Salesforce opportunity. */
  sfdcOpportunityStage?: string
  /** Indicates whether the order can be executed in Salesforce. */
  sfdcOrderCanBeExecuted?: boolean
  /** CRM type of the opportunity (e.g., SALESFORCE). */
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  /** ID of the subscription being renewed. */
  renewalForSubscriptionId?: string
  /**
   * The version of the subscription being renewed.
   * @format int32
   */
  renewalForSubscriptionVersion?: number
  /** Owner ID of the order. */
  ownerId?: string
  /** ID of the master document template. */
  documentMasterTemplateId?: string
  /** Purchase order number. */
  purchaseOrderNumber?: string
  /** Indicates if a purchase order is required for invoicing. */
  purchaseOrderRequiredForInvoicing?: boolean
  /**  Indicates if the order is set to auto-renew. */
  autoRenew?: boolean
  /** Approval segment ID. */
  approvalSegmentId?: string
  /** Attachment ID for any associated files. */
  attachmentId?: string
  /** ID of the Composite order. */
  compositeOrderId?: string
  /**
   * Expiration date of the order.
   * @format int64
   */
  expiresOn?: number
  /** Custom fields for the order. */
  customFields?: CustomFieldEntry[]
  /** Start date type (e.g., FIXED). */
  startDateType?: 'FIXED' | 'EXECUTION_DATE'
}

export interface OrderLineItemJson {
  id?: string
  itemGroupId?: string
  isDryRunItem?: boolean
  action?: 'ADD' | 'UPDATE' | 'REMOVE' | 'RENEWAL' | 'NONE' | 'MISSING_RENEWAL' | 'RESTRUCTURE'
  planId?: string
  subscriptionChargeId?: string
  currencyConversionRateId?: string
  subscriptionChargeGroupId?: string
  chargeId: string
  /** @format int64 */
  quantity?: number
  isRamp?: boolean
  listUnitPrice?: number
  sellUnitPrice?: number
  discountAmount?: number
  discounts?: DiscountDetailJson[]
  predefinedDiscounts?: TenantDiscountLineItemJson[]
  attributeReferences?: AttributeReference[]
  amount?: number
  listAmount?: number
  annualizedAmount?: number
  listPriceOverrideRatio?: number
  listUnitPriceBeforeOverride?: number
  listAmountBeforeOverride?: number
  taxEstimate?: number
  /** @format int64 */
  effectiveDate?: number
  /** @format int64 */
  endDate?: number
  customFields?: CustomFieldEntry[]
  arrOverride?: number
  dryRunItem?: boolean
}

export interface OrderTerms {
  /** @format uuid */
  id?: string
  templateGroupId?: string
  /** @format int32 */
  templateGroupVersion?: number
  orderId?: string
  levelType?: 'ORDER' | 'PLAN'
  /** @uniqueItems true */
  planIds?: string[]
  templateId?: string
  deleted?: boolean
}

export interface TenantDiscountJson {
  id?: string
  percent?: number
}

export interface TenantDiscountLineItemJson {
  id?: string
  percent?: number
  amount?: number
}

export interface OrderJsonPaginationResponse {
  data?: OrderJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface DocumentCustomContent {
  /** @format uuid */
  id?: string
  orderId?: string
  title?: string
  content?: string
}

export interface OrderLineItemRequestJson {
  id?: string
  itemGroupId?: string
  isDryRunItem?: boolean
  action?: 'ADD' | 'UPDATE' | 'REMOVE' | 'RENEWAL' | 'NONE' | 'MISSING_RENEWAL' | 'RESTRUCTURE'
  planId?: string
  subscriptionChargeId?: string
  chargeId: string
  /** @format int64 */
  quantity?: number
  isRamp?: boolean
  discounts?: DiscountJson[]
  predefinedDiscounts?: string[]
  /** @format int64 */
  effectiveDate?: number
  /** @format int64 */
  endDate?: number
  listUnitPrice?: number
  listPriceOverrideRatio?: number
  attributeReferences?: AttributeReference[]
  customFields?: CustomFieldEntry[]
  arrOverride?: number
  dryRunItem?: boolean
}

export interface OrderRequestJson {
  id?: string
  externalId?: string
  name?: string
  accountId?: string
  orderType: 'NEW' | 'AMENDMENT' | 'CANCEL' | 'RENEWAL' | 'RESTRUCTURE'
  paymentTerm?: string
  subscriptionId?: string
  shippingContactId?: string
  billingContactId?: string
  predefinedDiscounts?: TenantDiscountJson[]
  lineItems?: OrderLineItemRequestJson[]
  /** @format int64 */
  startDate: number
  /** @format int64 */
  endDate?: number
  /** @format int64 */
  executedOn?: number
  termLength?: RecurrenceJson
  billingCycle?: RecurrenceJson
  billingTerm?: 'UP_FRONT' | 'IN_ARREARS'
  /** @format int64 */
  billingAnchorDate?: number
  rampInterval?: number[]
  orderFormTemplateIds?: string[]
  sfdcOpportunityId?: string
  isPrimaryOrderForSfdcOpportunity?: boolean
  sfdcOpportunityName?: string
  sfdcOpportunityType?: string
  sfdcOpportunityStage?: string
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  ownerId?: string
  renewalForSubscriptionId?: string
  documentMasterTemplateId?: string
  documentCustomContent?: DocumentCustomContent
  purchaseOrderNumber?: string
  purchaseOrderRequiredForInvoicing?: boolean
  autoRenew?: boolean
  approvalSegmentId?: string
  attachmentId?: string
  /** @format int64 */
  expiresOn?: number
  entityId?: string
  customFields?: CustomFieldEntry[]
  startDateType?: 'FIXED' | 'EXECUTION_DATE'
  currency?: string
}

export interface PaymentJson {
  /** @format uuid */
  id?: string
  paymentId?: string
  accountId: string
  /** @format uuid */
  paymentMethodId: string
  currencyCode?: string
  state?:
    | 'CREATED'
    | 'CONFIRMED'
    | 'CAPTURED'
    | 'PROCESSING'
    | 'CARD_DECLINED'
    | 'ACH_TRANSFER_FAILED'
    | 'RECONCILED'
    | 'INITIATED'
    | 'FAILED'
    | 'SUCCEED'
    | 'VOIDED'
  status?: string
  amount?: number
  /** @format int64 */
  paymentDate?: number
  exchangeRateId?: string
  exchangeRate?: number
  /** @format int64 */
  exchangeRateDate?: number
  functionalAmount?: number
  functionalAmountCaptured?: number
  functionalBankFee?: number
}

export interface PaymentJsonPaginationResponse {
  data?: PaymentJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface PaymentBalanceJson {
  accountId?: string
  paymentId?: string
  balance?: number
  /** @format int64 */
  updatedOn?: number
}

export interface VoidPaymentJson {
  paymentId?: string
  /** @format int64 */
  voidDate: number
  invoiceBalance: number
  invoiceNumber: string
  note?: string
}

export interface PaymentConfiguration {
  /** @uniqueItems true */
  supportedPaymentTypes?: ('ACH' | 'CARD' | 'CHECK' | 'WIRE' | 'INVOICE' | 'EXTERNAL')[]
}

export interface LedgerAccount {
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  accountCode?: string
  /**
   * @minLength 0
   * @maxLength 65535
   */
  description?: string
  accountType?:
    | 'ACCOUNTS_RECEIVABLE'
    | 'TAX_LIABILITY'
    | 'CASH'
    | 'DEFERRED_REVENUE'
    | 'RECOGNIZED_REVENUE'
    | 'CONTRACT_ASSET'
    | 'REALIZED_GAIN_LOSS'
    | 'EXPENSE'
  isDefault?: boolean
  inUse?: boolean
  default?: boolean
  id?: string
}

export interface PlanJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  displayName?: string
  description?: string
  status: 'DRAFT' | 'ACTIVE' | 'GRANDFATHERED' | 'ARCHIVED' | 'DEPRECATED'
  productId?: string
  charges?: ChargeJson[]
  currency?: string
  externalId?: string
  templateIds?: string[]
  replacementPlanIds?: string[]
  customFields?: Record<string, CustomFieldValue>
  /** @format int64 */
  updatedOn?: number
}

export interface PlanJsonPaginationResponse {
  data?: PlanJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface PlanMetadataJson {
  /**
   * Entity ids to be updated
   * @uniqueItems true
   */
  entityIds?: string[]
}

export interface ChargePartialJson {
  id?: string
  name?: string
  displayName?: string
  description?: string
  /** @format uuid */
  taxRateId?: string
  /** @format uuid */
  unitOfMeasureId?: string
  isRenewable?: boolean
  isListPriceEditable?: boolean
  /** @format int64 */
  minQuantity?: number
  /** @format int64 */
  defaultQuantity?: number
  /** @format int64 */
  maxQuantity?: number
  externalId?: string
  minAmount?: number
  maxAmount?: number
  recognitionRuleId?: string
  erpId?: string
  itemCode?: string
  listPriceEditable?: boolean
}

export interface DocumentSection {
  /** @format uuid */
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  title?: string
  location?: 'BEFORE_SIGNATURE' | 'AFTER_SIGNATURE'
  deleted?: boolean
}

export interface ProductCategory {
  productCategoryId?: string
  /** @uniqueItems true */
  entityIds?: string[]
  /**
   * @minLength 0
   * @maxLength 100
   */
  name?: string
  description?: string
  inUse?: boolean
  /** @format int64 */
  updatedOn?: number
  /** @format uuid */
  pkId?: string
}

export interface ProductCategoryPaginationResponse {
  data?: ProductCategory[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface ProductJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  displayName?: string
  inUse?: boolean
  description?: string
  sku?: string
  productCategoryId?: string
  productCategory?: ProductCategory
  /** @format int64 */
  updatedOn?: number
  externalId?: string
}

export interface ProductJsonPaginationResponse {
  data?: ProductJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface ProductInputJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  displayName?: string
  description?: string
  sku?: string
  productCategoryId?: string
  externalId?: string
}

export interface RefundDetail {
  id: string
  refundId: string
  referenceId?: string
  creditMemoNumber: string
  amount?: number
  /** @format int64 */
  refundDate: number
  paymentId: string
  paymentMethodType?: string
  createdBy?: string
  notes?: string
  currency: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
}

export interface Refund {
  id?: string
  refundId?: string
  referenceId?: string
  tenantId?: string
  entityId?: string
  creditMemoNumber?: string
  amount?: number
  /** @format int64 */
  refundDate?: number
  paymentId?: string
  paymentMethodType?: string
  currencyCode?: string
  notes?: string
  createdBy?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
}

export interface RefundRequestJson {
  creditMemoNumber: string
  paymentId: string
  amount: number
  /** @format int64 */
  refundDate: number
  paymentMethodType?: string
  createdBy: string
  notes?: string
  referenceId?: string
}

export interface PredefinedReportJson {
  reportId?: string
  params?: Record<string, object>
  duration?: ReportDuration
  /** @format int64 */
  reportDate?: number
}

export interface ReportDuration {
  /** @format int64 */
  start?: number
  /** @format int64 */
  end?: number
}

export interface ReportJobResponse {
  reportId?: string
  reportRunId?: string
  status?: 'OK' | 'FAILED'
  uri?: string
}

export interface PredefinedReportDefChartJson {
  chartType?: string
  mainAxisKey?: string
  crossAxisKey?: string
  mainAxisLabel?: string
  crossAxisLabel?: string
  mainAxisScale?: string
  crossAxisScale?: string
  title?: string
  sortBy?: string
  showLegend?: boolean
}

export interface PredefinedReportDefJson {
  reportId?: string
  name?: string
  description?: string
  filters?: PredefinedReportParamObject[]
  chart?: PredefinedReportDefChartJson
}

export interface PredefinedReportDefsJson {
  reportDefs?: PredefinedReportDefJson[]
}

export interface PredefinedReportParam {
  name?: string
  description?: string
  type: 'value' | 'range' | 'selection'
  datatype: 'date' | 'string' | 'integer'
  allowedValues?: string[]
  defaultValue?: object
  optional?: boolean
}

export interface PredefinedReportParamObject {
  name?: string
  description?: string
  type: 'value' | 'range' | 'selection'
  datatype: 'date' | 'string' | 'integer'
  allowedValues?: string[]
  defaultValue?: object
  optional?: boolean
}

export interface ApiKeyPrincipal {
  tenantId?: string
  role?:
    | 'ADMIN'
    | 'FINANCE'
    | 'SALES'
    | 'SALES_MANAGER'
    | 'ACCOUNTANT'
    | 'BILLING_CLERK'
    | 'REVENUE_CLERK'
    | 'READ_ONLY'
    | 'EXECUTIVE'
    | 'CRM'
    | 'IMPORT'
    | 'BILLY_ADMIN'
    | 'BILLY_ENGINEER'
    | 'BILLY_JOB'
  /** @format uuid */
  apiKeyId?: string
  user?: User
  entityContext?: EntityContext
  name?: string
  ssoLogin?: boolean
}

export interface BillyAuthPrincipal {
  principalType?: 'API_KEY' | 'USER' | 'API_KEY_USER' | 'JOB' | 'NONE'
  name?: string
  role?:
    | 'ADMIN'
    | 'FINANCE'
    | 'SALES'
    | 'SALES_MANAGER'
    | 'ACCOUNTANT'
    | 'BILLING_CLERK'
    | 'REVENUE_CLERK'
    | 'READ_ONLY'
    | 'EXECUTIVE'
    | 'CRM'
    | 'IMPORT'
    | 'BILLY_ADMIN'
    | 'BILLY_ENGINEER'
    | 'BILLY_JOB'
  tenantId?: TenantId
  subskribeUserId?: string
  entityContext?: EntityContext
  authUser?: UserPrincipal
  apiKeyAuthUser?: ApiKeyPrincipal
}

export interface EntityContext {
  /** @uniqueItems true */
  selectedIds?: string[]
  /** @uniqueItems true */
  authorizedIds?: string[]
  empty?: boolean
}

export interface TenantId {
  requiredId?: string
  optionalId?: string
}

export interface User {
  /** @format uuid */
  id?: string
  userId?: string
  tenantId?: string
  tenantName?: string
  displayName?: string
  title?: string
  email?: string
  normalizedEmail?: string
  phoneNumber?: string
  role?:
    | 'ADMIN'
    | 'FINANCE'
    | 'SALES'
    | 'SALES_MANAGER'
    | 'ACCOUNTANT'
    | 'BILLING_CLERK'
    | 'REVENUE_CLERK'
    | 'READ_ONLY'
    | 'EXECUTIVE'
    | 'CRM'
    | 'IMPORT'
    | 'BILLY_ADMIN'
    | 'BILLY_ENGINEER'
    | 'BILLY_JOB'
  state?: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  ssoOnly?: boolean
  /** @format int64 */
  termsAcceptedOn?: number
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  cognitoUserStatus?:
    | 'UNCONFIRMED'
    | 'CONFIRMED'
    | 'ARCHIVED'
    | 'COMPROMISED'
    | 'UNKNOWN'
    | 'RESET_REQUIRED'
    | 'FORCE_CHANGE_PASSWORD'
    | 'EXTERNAL_PROVIDER'
    | 'UNKNOWN_TO_SDK_VERSION'
  userGroups?: string[]
  approvalSegments?: string[]
  /** @uniqueItems true */
  entityIds?: string[]
  externalId?: string
}

export interface UserPrincipal {
  userIdentifier?: string
  clientId?: string
  role?:
    | 'ADMIN'
    | 'FINANCE'
    | 'SALES'
    | 'SALES_MANAGER'
    | 'ACCOUNTANT'
    | 'BILLING_CLERK'
    | 'REVENUE_CLERK'
    | 'READ_ONLY'
    | 'EXECUTIVE'
    | 'CRM'
    | 'IMPORT'
    | 'BILLY_ADMIN'
    | 'BILLY_ENGINEER'
    | 'BILLY_JOB'
  emailAddress?: string
  subskribeUserId?: string
  tenantId?: string
  entityContext?: EntityContext
  name?: string
  ssoLogin?: boolean
  roleString?: string
}

export interface RecognitionEventCompletion {
  tenantId?: string
  entityId?: string
  subscriptionId?: string
  chargeId?: string
  aliasId?: string
  accountingPeriodId?: string
  unitOfCompletion: number
  status?: 'ACCEPTED' | 'PROCESSED'
  /** @format int64 */
  arrivedOn?: number
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  deleted?: boolean
}

export interface RecognitionRule {
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  source?: 'ORDER' | 'INVOICE'
  recognitionType?: 'OVER_TIME' | 'POINT_IN_TIME' | 'EVENT'
  distributionMethod?: 'DAYS' | 'MONTHS_EVEN' | 'MONTHS_PARTIAL_PRORATED'
  recognitionDateAlignment?: 'INVOICE_START_DATE' | 'INVOICE_END_DATE'
  isCatchupRequired?: boolean
  recognitionEventType?: 'PERCENTAGE_OF_COMPLETION' | 'AMOUNT'
  deferredRevenueAccountId?: string
  recognizedRevenueAccountId?: string
  inUse?: boolean
  catchupRequired?: boolean
  id?: string
}

export interface SalesforceClientIntegrationRequestJson {
  clientId?: string
  clientSecret?: string
  redirectUri?: string
  baseLoginUrl?: string
}

export interface SalesforceAccount {
  id?: string
  name?: string
  description?: string
  billingAddress?: SalesforceAccountBillingAddress
  phone?: string
  CurrencyIsoCode?: string
}

export interface SalesforceAccountBillingAddress {
  street?: string
  city?: string
  state?: string
  postalCode?: string
  country?: string
}

export interface Opportunity {
  /** @format uuid */
  id?: string
  entityId?: string
  name?: string
  type?: string
  stage?: string
  crmId?: string
  accountId?: string
  /** @format int64 */
  createdOn?: number
  /** @format int64 */
  updatedOn?: number
  opportunityCrmType?: 'SALESFORCE' | 'HUBSPOT'
  isClosed?: boolean
  primaryOrderId?: string
  opportunityId?: string
}

export interface PaginatedSubscriptionsResponse {
  data?: SubscriptionJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface PurchaseOrder {
  /**
   * @minLength 0
   * @maxLength 255
   */
  purchaseOrderNumber?: string
  originOrderId?: string
  /** @format int64 */
  addedOn?: number
}

export interface SubscriptionChargeJson {
  id?: string
  groupId?: string
  accountId: string
  chargeId: string
  /** @format int64 */
  quantity: number
  isRamp: boolean
  currencyConversionRateId?: string
  discounts?: DiscountJson[]
  predefinedDiscounts?: TenantDiscountLineItemJson[]
  attributeReferences?: AttributeReference[]
  listUnitPrice?: number
  sellUnitPrice?: number
  discountAmount?: number
  /** @format int64 */
  startDate: number
  /** @format int64 */
  endDate: number
  orderLines: string[]
}

export interface SubscriptionJson {
  id?: string
  /** @format int32 */
  version: number
  entityId: string
  externalId?: string
  accountId: string
  shippingContactId?: string
  billingContactId?: string
  state: 'ACTIVE' | 'EXPIRED' | 'CANCELLED' | 'PENDING' | 'PENDING_CANCELLATION'
  /** @format int64 */
  startDate: number
  /** @format int64 */
  endDate: number
  /** @format int64 */
  canceledDate?: number
  termLength: RecurrenceJson
  billingCycle: RecurrenceJson
  paymentTerm?: string
  billingTerm?: 'UP_FRONT' | 'IN_ARREARS'
  charges: SubscriptionChargeJson[]
  predefinedDiscounts?: TenantDiscountJson[]
  orders: string[]
  purchaseOrders?: PurchaseOrder[]
  purchaseOrderRequiredForInvoicing?: boolean
  /** @format int64 */
  creationTime: number
  rampInterval?: number[]
  renewedFromSubscriptionId?: string
  /** @format int64 */
  renewedFromDate?: number
  renewedToSubscriptionId?: string
  /** @format int64 */
  renewedToDate?: number
  restructuredFromSubscriptionId?: string
  /** @format int64 */
  restructuredFromDate?: number
  restructuredToSubscriptionId?: string
  /** @format int64 */
  restructuredToDate?: number
  autoRenew?: boolean
  /** @format int64 */
  activationDate?: number
  name?: string
}

export interface SubscriptionUpdateJson {
  shippingContactId?: string
  billingContactId?: string
  purchaseOrderNumber?: string
  purchaseOrderRequiredForInvoicing?: boolean
  externalId?: string
  autoRenew?: boolean
  emailNotifiersList?: EmailNotifiersList
  /** @format int64 */
  activationDate?: number
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string
}

export interface TaxJarIntegrationInput {
  apiKey?: string
  isSandbox?: boolean
}

export interface TaxJarIntegration {
  isSandbox?: boolean
  tenantId?: string
  /** @format int64 */
  updatedOn?: number
  /** @format int64 */
  createdOn?: number
  integrationId?: string
  maskedApiKey?: string
  isDeleted?: boolean
}

export interface TaxRatePaginationResponseJson {
  data?: TaxRateJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface TenantJson {
  tenantId?: string
  name?: string
  email?: string
  phoneNumber?: string
  address?: AccountAddressJson
  hasSalesforceIntegration?: boolean
  isDeleted?: boolean
  isTest?: boolean
  hasHubSpotIntegration?: boolean
  hasSso?: boolean
  /** @format int64 */
  createdOn?: number
  tenantSetting?: TenantSettingJson
}

export interface TenantSettingJson {
  defaultTimeZone?: string
  supportedCurrencies?: string[]
  percentDerivedFrom?: 'LIST_AMOUNT' | 'SELL_AMOUNT'
  tenantSettingSeal?: 'ON' | 'OFF'
  orderExpiryDurationInDays?: string
  signingOrder?: 'ACCOUNT_FIRST' | 'TENANT_FIRST' | 'ACCOUNT_ONLY'
  globalBccEmail?: string
  isUpdateOrderStartDateEnabled?: boolean
  autoReplacePlans?: boolean
}

export interface PaymentTermSettingsJson {
  defaultPaymentTerms: string[]
  defaultPaymentTerm: string
  customPaymentTermsAllowed: boolean
}

export interface BackendConfig {
  id?: string
  conditions?: Condition[]
}

export interface Condition {
  key?: string
  checks?: ('required' | 'empty')[]
  defaultValue?: string
}

export interface Customization {
  id?: string
  configType: 'state' | 'form' | 'dgpTable'
  hidden?: boolean
  required?: boolean
  defaultValueSetters?: DefaultValueDefinition[]
  columnOrdering?: string[]
  columnDefaultInvisible?: string[]
  backend?: BackendConfig
}

export interface DefaultValueDefinition {
  path?: string
  value?: string
}

export interface TenantUiCustomization {
  version: 'V1_TENANT_LEVEL'
  customizations?: Customization[]
}

export interface TransactionalExchangeRate {
  id?: string
  fromCurrency?: string
  toCurrency?: string
  /** @format int64 */
  effectiveDate?: number
  exchangeRate?: number
  exchangeSource?: 'QUODD' | 'USER'
  updatedBy?: string
  isOverridden?: boolean
}

export interface UnitOfMeasureJson {
  /** @format uuid */
  id?: string
  name?: string
  description?: string
  status: 'DRAFT' | 'ACTIVE' | 'DEPRECATED'
}

export interface UnitOfMeasurePaginationResponseJson {
  data?: UnitOfMeasureJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

export interface Entry {
  /** @format int64 */
  rowNumber?: number
  failed?: boolean
  failureReason?: string
}

export interface UsageBatchInsertResult {
  /** @format int32 */
  rawUsagesTotal?: number
  /** @format int32 */
  totalFailed?: number
  /** @format int32 */
  totalDuplicates?: number
  entries?: Entry[]
}

/** A raw usage record */
export interface RawUsage {
  /** Unique identifier for a particular usage record */
  id?: string
  /** Alias ID for an usage based subscription item. This value is required if subscriptionId and subscriptionChargeId are not provided */
  aliasId?: string
  /** SubscriptionId to attach usage record to. This value is required if aliasId is not provided */
  subscriptionId?: string
  /** ChargeId of an usage based charge to attach usage record to. This value is required if aliasId is not provided */
  chargeId?: string
  /**
   * Usage time in unix timestamp (seconds)
   * @format int64
   */
  usageTime?: number
  /** @format int64 */
  usageQuantity?: number
  /** List of attribute references to derive the price for the usage record */
  attributeReferences?: AttributeReference[]
}

/** A list of RawUsage objects */
export interface RawUsagesData {
  data: RawUsage[]
}

export interface UsageAggregateOutput {
  subscriptionId?: string
  subscriptionChargeGroupId?: string
  attributeReferences?: AttributeReference[]
  /** @format int64 */
  startAt?: number
  /** @format int64 */
  endAt?: number
  /** @format int32 */
  observedDataPoints?: number
  usageSum?: number
  usageMax?: number
  usageMin?: number
}

export interface PrepaidStats {
  accountId?: string
  accountName?: string
  accountCrmId?: string
  subscriptionId?: string
  entityDisplayId?: string
  /** @format int64 */
  subscriptionStartDate?: number
  /** @format int64 */
  subscriptionEndDate?: number
  planName?: string
  drawdownChargeName?: string
  provisionedQuantity?: number
  startingQuantity?: number
  remainingQuantity?: number
  consumedQuantity?: number
  /** @format int64 */
  periodStart?: number
  /** @format int64 */
  periodEnd?: number
}

export interface UserGroupRequestJson {
  id?: string
  /** @uniqueItems true */
  entityIds?: string[]
  name?: string
  description?: string
  users?: string[]
  externalId?: string
}

export type InputStream = object

export interface UserSsoUpdate {
  ssoOnly?: boolean
}

export interface UserInput {
  id?: string
  displayName?: string
  title?: string
  email?: string
  phoneNumber?: string
  state?: 'ACTIVE' | 'DISABLED' | 'EXPIRED'
  role?:
    | 'ADMIN'
    | 'FINANCE'
    | 'SALES'
    | 'SALES_MANAGER'
    | 'ACCOUNTANT'
    | 'BILLING_CLERK'
    | 'REVENUE_CLERK'
    | 'READ_ONLY'
    | 'EXECUTIVE'
    | 'CRM'
    | 'IMPORT'
    | 'BILLY_ADMIN'
    | 'BILLY_ENGINEER'
    | 'BILLY_JOB'
  ssoOnly?: boolean
  entityIds?: string[]
  externalId?: string
}

export interface UserPaginationResponseJson {
  data?: UserJson[]
  /** @format int32 */
  numElements?: number
  /** @format uuid */
  nextCursor?: string
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://api.dev2.subskribe.net' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Subskribe API
 * @version 1.0.0
 * @baseUrl https://api.dev2.subskribe.net
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  accountReceivableContact = {
    /**
     * @description Returns the details of the account receivable contact for your tenant
     *
     * @tags Billing
     * @name GetAccountReceivableContact
     * @summary Get the contact for accounts receivable
     * @request GET:/accountReceivableContact
     */
    getAccountReceivableContact: (params: RequestParams = {}) =>
      this.request<AccountReceivableContactJson, any>({
        path: `/accountReceivableContact`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets the details of the account receivable contact for your tenant
     *
     * @tags Billing
     * @name PutAccountReceivableContact
     * @summary Set the contact for accounts receivable
     * @request PUT:/accountReceivableContact
     */
    putAccountReceivableContact: (
      accountReceivableContactJson: AccountReceivableContactJson,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accountReceivableContact`,
        method: 'PUT',
        body: accountReceivableContactJson,
        type: ContentType.Json,
        ...params,
      }),
  }
  accounts = {
    /**
     * @description Returns a paginated list of accounts
     *
     * @tags Accounts
     * @name GetAccounts
     * @summary Get all accounts
     * @request GET:/accounts
     */
    getAccounts: (
      query?: {
        /**
         * A string token is used to fetch next set of results. If not provided, the first page of results will be returned. Use the 'next_cursor' value from the previous response to fetch the next page.
         * @format uuid
         */
        cursor?: string
        /**
         * An integer specifying the maximum number of results to return per page. Defaults to 10 if not provided.
         * @format int32
         */
        limit?: number
        /** The type of accounts to retrieve. Allowed values are:  ALL: Includes all account types. RESELLER: Includes only reseller accounts. NON_RESELLER: Includes only non-reseller accounts. */
        type?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedAccountsResponse, any>({
        path: `/accounts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create an account with the specified parameters. On success, the id of the newly created account is returned
     *
     * @tags Accounts
     * @name AddAccount
     * @summary Add a new account
     * @request POST:/accounts
     */
    addAccount: (body: AccountJson, params: RequestParams = {}) =>
      this.request<AccountJson, any>({
        path: `/accounts`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves detailed information about a specific account using its unique identifier. It could be Account_ID, CRM_ID or External_ID. This endpoint provides comprehensive data for a particular account, enabling users to access full account details.
     *
     * @tags Accounts
     * @name GetAccount
     * @summary Get an account by id
     * @request GET:/accounts/{id}
     */
    getAccount: (
      id: string,
      query?: {
        /** Specifies the type of ID being used. Allowed values are account_id: The default account ID. crm_id: The CRM (Customer Relationship Management) ID external_id: An external system's ID for the account. Default is account_id */
        idType?: 'ACCOUNT_ID' | 'CRM_ID' | 'EXTERNAL_ID'
      },
      params: RequestParams = {}
    ) =>
      this.request<AccountJson, any>({
        path: `/accounts/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates an existing account with the specified parameters
     *
     * @tags Accounts
     * @name UpdateAccount
     * @summary Update an account
     * @request PUT:/accounts/{id}
     */
    updateAccount: (id: string, body: AccountJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/accounts/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes the account associated with the passed ID
     *
     * @tags Accounts
     * @name DeleteAccount
     * @summary Delete an account
     * @request DELETE:/accounts/{id}
     */
    deleteAccount: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/accounts/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Fetches metrics such as ARR, TCV, etc for the specified account as of the specified target date
     *
     * @tags Accounts
     * @name GetAccountMetrics
     * @summary Returns metrics for the specified account
     * @request GET:/accounts/{id}/metrics
     */
    getAccountMetrics: (
      id: string,
      query?: {
        /** @format int64 */
        targetDate?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MetricsJson, any>({
        path: `/accounts/${id}/metrics`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of contacts associated with the specified account id
     *
     * @tags Accounts
     * @name GetAccountContacts
     * @summary Get contacts for an account
     * @request GET:/accounts/{accountId}/contacts
     */
    getAccountContacts: (
      accountId: string,
      query?: {
        /** When set to true, expands the response to include additional details about each contact, such as address, external id, erp id, fullName. Default is false. */
        expand?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<AccountContactJson[], any>({
        path: `/accounts/${accountId}/contacts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates and adds a new contact for the specified account and returns the new contact ID.
     *
     * @tags Accounts
     * @name AddAccountContact
     * @summary Add a contact for an account
     * @request POST:/accounts/{accountId}/contacts
     */
    addAccountContact: (
      accountId: string,
      body: AccountContactJson,
      query?: {
        /** value = perform basic address validation */
        skipAddressValidation?: boolean
        /** value = require the address to match a canonical address, if it exists */
        strictValidation?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<AccountContactJson, any>({
        path: `/accounts/${accountId}/contacts`,
        method: 'POST',
        query: query,
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update ERP details for an account specified by the account id
     *
     * @tags Accounts
     * @name AddErpDetails
     * @summary Update account ERP details
     * @request PUT:/accounts/{accountId}/erp
     */
    addErpDetails: (
      accountId: string,
      body: ErpInputJson,
      query?: {
        override?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accounts/${accountId}/erp`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns the details of the specified contact
     *
     * @tags Accounts
     * @name GetAccountContact
     * @summary Gets contact details
     * @request GET:/accounts/{accountId}/contacts/{contactId}
     */
    getAccountContact: (accountId: string, contactId: string, params: RequestParams = {}) =>
      this.request<AccountContactJson, any>({
        path: `/accounts/${accountId}/contacts/${contactId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the contact specified by the account id and contact id with the passed information
     *
     * @tags Accounts
     * @name UpdateAccountContact
     * @summary Update a contact
     * @request PUT:/accounts/{accountId}/contacts/{contactId}
     */
    updateAccountContact: (
      accountId: string,
      contactId: string,
      body: AccountContactJson,
      query?: {
        skipAddressValidation?: boolean
        strictValidation?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accounts/${accountId}/contacts/${contactId}`,
        method: 'PUT',
        query: query,
        body: body,
        ...params,
      }),

    /**
     * @description Deletes the contact specified by the account id and contact id
     *
     * @tags Accounts
     * @name DeleteAccountContact
     * @summary Delete a contact
     * @request DELETE:/accounts/{accountId}/contacts/{contactId}
     */
    deleteAccountContact: (contactId: string, accountId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/accounts/${accountId}/contacts/${contactId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Ensures an account exists which matches the passed details.If an account exists that has a matching CRM Id, it will be updated, if not, it will be created
     *
     * @tags Accounts
     * @name ImportCrmAccount
     * @summary Import an account from a CRM
     * @request POST:/accounts/crm/import
     */
    importCrmAccount: (body: AccountJson, params: RequestParams = {}) =>
      this.request<CrmAccountImportResponse, any>({
        path: `/accounts/crm/import`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  accountingPeriods = {
    /**
     * @description Specify the start date of and open an accounting period, making it current
     *
     * @tags Accounting
     * @name SpecifyCurrentAccountingPeriod
     * @summary Specify the current accounting period
     * @request POST:/accountingPeriods
     */
    specifyCurrentAccountingPeriod: (startDate: number, params: RequestParams = {}) =>
      this.request<AccountingPeriod, any>({
        path: `/accountingPeriods`,
        method: 'POST',
        body: startDate,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  ai = {
    /**
     * @description Generate a complete summary of the order with the given id, a full detailed summary will be generated including metrics
     *
     * @tags AI Summary, Experimental
     * @name GenerateOrderSummary
     * @summary Generate a summary of the order in plain english in markdown format
     * @request GET:/ai/summary/order/{orderId}
     */
    generateOrderSummary: (orderId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ai/summary/order/${orderId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new conversation session with Subskribe AI agent, this resource will return a session id which will be used for future conversations
     *
     * @tags AI Agent, Experimental
     * @name CreateAgentSession
     * @summary Create new conversational AI agent session
     * @request POST:/ai/agents/session
     */
    createAgentSession: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ai/agents/session`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description The API responds user message, a session id is required to identify the session this message needs to be posted
     *
     * @tags AI Agent, Experimental
     * @name ChatResponse
     * @summary Chat with a given session id and get back a response for a given message
     * @request PUT:/ai/agents/session/{sessionId}/chat
     */
    chatResponse: (sessionId: string, body: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ai/agents/session/${sessionId}/chat`,
        method: 'PUT',
        body: body,
        type: ContentType.Text,
        format: 'json',
        ...params,
      }),

    /**
     * @description The API responds to user message, a session id is required to identify the session this message needs to be posted
     *
     * @tags AI Agent, Experimental
     * @name ChatResponseAsync
     * @summary Chat with a given session id and get back a response for a given message in a async manner in the form of server side events
     * @request GET:/ai/agents/session/{sessionId}/chatAsync
     */
    chatResponseAsync: (
      sessionId: string,
      query?: {
        /** the user message to which the AI can respond */
        userMessage?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<OutboundEvent, any>({
        path: `/ai/agents/session/${sessionId}/chatAsync`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description The message will be returned in the most recent order, with the latest being the first
     *
     * @tags AI Agent, Experimental
     * @name ChatMessages
     * @summary List the messages belong to this session
     * @request GET:/ai/agents/session/{sessionId}/messages
     */
    chatMessages: (
      sessionId: string,
      query?: {
        /**
         * the number of message to fetch should be a number between 1 to 100 if present
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<Message[], any>({
        path: `/ai/agents/session/${sessionId}/messages`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get the agent session given the id, NOTE: at the moment there is no response body, if 200 is returned then session exists
     *
     * @tags AI Agent, Experimental
     * @name GetAgentSession
     * @summary Get the agent session given the id, NOTE: at the moment there is no response body
     * @request GET:/ai/agents/session/{sessionId}
     */
    getAgentSession: (sessionId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ai/agents/session/${sessionId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Generate a complete summary of the subscription with the given id, a full detailed summary will be generated including metrics
     *
     * @tags AI Agent, Experimental
     * @name GenerateSubscriptionSummary
     * @summary Generate a summary of the subscription in plain english in markdown format
     * @request GET:/ai/summary/subscription/{subscriptionId}
     */
    generateSubscriptionSummary: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ai/summary/subscription/${subscriptionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  alias = {
    /**
     * @description Returns the details of the specified alias id including the subscription id and the charge id it is mapped to.
     *
     * @tags Billing
     * @name GetSubscriptionChargeAlias
     * @summary Get the details of an alias
     * @request GET:/alias/subscriptionCharge/{aliasId}
     */
    getSubscriptionChargeAlias: (aliasId: string, params: RequestParams = {}) =>
      this.request<SubscriptionChargeAlias, any>({
        path: `/alias/subscriptionCharge/${aliasId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description This allows you to specify a string alias for a subscription id and a charge id. This can be useful when, for example, you want to upload usage statistics and would rather specify your own id (or another external id), rather than referring to Subskribe's internal ids.
     *
     * @tags Billing
     * @name AddSubscriptionChargeAlias
     * @summary Create an alias for a subscription id/charge id pair
     * @request PUT:/alias/subscriptionCharge/{aliasId}
     */
    addSubscriptionChargeAlias: (
      aliasId: string,
      query?: {
        /** the subscription id */
        subscriptionId?: string
        /** the charge id */
        chargeId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/alias/subscriptionCharge/${aliasId}`,
        method: 'PUT',
        query: query,
        ...params,
      }),

    /**
     * @description Deletes the specified alias mapping
     *
     * @tags Billing
     * @name DeleteSubscriptionChargeAlias
     * @summary Delete the specified alias
     * @request DELETE:/alias/subscriptionCharge/{aliasId}
     */
    deleteSubscriptionChargeAlias: (aliasId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/alias/subscriptionCharge/${aliasId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Returns all aliases for the specified subscription id.
     *
     * @tags Billing
     * @name ListAliasesForSubscription
     * @summary Get aliases for a subscription
     * @request GET:/alias/subscriptionCharge
     */
    listAliasesForSubscription: (
      query?: {
        /** id of the subscription */
        subscriptionId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SubscriptionChargeAlias[], any>({
        path: `/alias/subscriptionCharge`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  anrok = {
    /**
     * @description Returns the integration ID if successful
     *
     * @tags Integrations
     * @name AddIntegration
     * @summary Add a Anrok integration
     * @request POST:/anrok
     */
    addIntegration: (body: AnrokIntegrationInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/anrok`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name TestIntegration
     * @summary Test an integration is valid
     * @request PUT:/anrok/test
     */
    testIntegration: (body: AnrokIntegrationInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/anrok/test`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name ValidateAddress
     * @summary Validate an address with Anrok
     * @request POST:/anrok/validate
     */
    validateAddress: (body: AccountAddress, params: RequestParams = {}) =>
      this.request<AccountAddress, any>({
        path: `/anrok/validate`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the integration details of the specified integration id
     *
     * @tags Integrations
     * @name GetIntegration
     * @summary Get integration details
     * @request GET:/anrok/{integrationId}
     */
    getIntegration: (integrationId: string, params: RequestParams = {}) =>
      this.request<Integration, any>({
        path: `/anrok/${integrationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  apikey = {
    /**
     * @description Revokes all keys for your tenant
     *
     * @tags Authentication
     * @name RevokeApiKey
     * @summary revoke all keys
     * @request DELETE:/apikey/revoke
     */
    revokeApiKey: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/apikey/revoke`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieve an api key referenced by its id
     *
     * @tags Authentication
     * @name GetApiKeyById
     * @summary Retrieve an API key by id
     * @request GET:/apikey/{id}
     */
    getApiKeyById: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/apikey/${id}`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieves a list of (maximum 500 items) all API keys (including expired and deactivated keys)
     *
     * @tags Authentication
     * @name GetAllApiKeys
     * @summary Retrieves all API keys
     * @request GET:/apikey
     */
    getAllApiKeys: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/apikey`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Create a new api key with the specified parameters. The new key is returned
     *
     * @tags Authentication
     * @name CreateApiKey
     * @summary Create a new api key
     * @request POST:/apikey
     */
    createApiKey: (
      query?: {
        /** Role-based permissions to be associated with the key. Specify this OR userId. */
        role?:
          | 'ADMIN'
          | 'FINANCE'
          | 'SALES'
          | 'DEAL_DESK'
          | 'BILLING_CLERK'
          | 'REVENUE_CLERK'
          | 'READ_ONLY'
          | 'EXECUTIVE'
          | 'CRM'
          | 'IMPORT'
        /**
         * Time of expiry in seconds since Epoch (GMT)
         * @format int64
         */
        expiry?: number
        /** User to associate key with. Specify this OR role. */
        userId?: string
        /** Scope of entities that the key has access to. Either specify a single entity or all entities (i.e. *). */
        entityId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/apikey`,
        method: 'POST',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Revoke an api key referenced by its id
     *
     * @tags Authentication
     * @name RevokeApiKeyById
     * @summary revoke an API key by id
     * @request DELETE:/apikey/revoke/{id}
     */
    revokeApiKeyById: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/apikey/revoke/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),
  }
  approvalFlowNotifications = {
    /**
     * No description
     *
     * @tags Approvals
     * @name FetchNotification
     * @summary make ngrok happy
     * @request GET:/approvalFlowNotifications
     */
    fetchNotification: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalFlowNotifications`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Approvals
     * @name ReceiveApprovalFlowNotification
     * @summary Receive approval flow notification
     * @request POST:/approvalFlowNotifications
     */
    receiveApprovalFlowNotification: (body: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalFlowNotifications`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  approvalFlows = {
    /**
     * @description Returns the details of the specified approval flow
     *
     * @tags Approvals
     * @name GetApprovalFlowById
     * @summary Get Details of an approval flow
     * @request GET:/approvalFlows/{approvalFlowId}
     */
    getApprovalFlowById: (approvalFlowId: string, params: RequestParams = {}) =>
      this.request<ApprovalFlowJson, any>({
        path: `/approvalFlows/${approvalFlowId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified approval flow
     *
     * @tags Approvals
     * @name UpdateApprovalFlow
     * @summary Update an approval flow
     * @request PUT:/approvalFlows/{approvalFlowId}
     */
    updateApprovalFlow: (approvalFlowId: string, body: ApprovalFlowJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalFlows/${approvalFlowId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes an approval flow
     *
     * @tags Approvals
     * @name DeleteApprovalFlow
     * @summary Delete an approval flow
     * @request DELETE:/approvalFlows/{approvalFlowId}
     */
    deleteApprovalFlow: (approvalFlowId: string, params: RequestParams = {}) =>
      this.request<ApprovalFlowJson, any>({
        path: `/approvalFlows/${approvalFlowId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets all approval flows
     *
     * @tags Approvals
     * @name GetApprovalFlows
     * @summary Get approval flows
     * @request GET:/approvalFlows
     */
    getApprovalFlows: (params: RequestParams = {}) =>
      this.request<ApprovalFlowJson[], any>({
        path: `/approvalFlows`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds an approval flow to the order execution hierarchy and returns its ID
     *
     * @tags Approvals
     * @name AddApprovalFlow
     * @summary Add an approval flow
     * @request POST:/approvalFlows
     */
    addApprovalFlow: (body: ApprovalFlowJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/approvalFlows`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  approvalMatrix = {
    /**
     * @description Downloads your order approval matrix as a csv
     *
     * @tags Approvals
     * @name GetApprovalMatrixAsCsv
     * @summary Download approval matrix
     * @request GET:/approvalMatrix/csv/download
     */
    getApprovalMatrixAsCsv: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalMatrix/csv/download`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Returns details regarding the approval matrices that have been uploaded
     *
     * @tags Approvals
     * @name GetAllImportDetails
     * @summary Get approval matrix details
     * @request GET:/approvalMatrix/csv
     */
    getAllImportDetails: (params: RequestParams = {}) =>
      this.request<ApprovalMatrixImportDataJson[], any>({
        path: `/approvalMatrix/csv`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Upload a csv containing the order approval matrix to be used on order execution. The csv should have the following format: Segment, ApprovalRoleName1, ApprovalRoleName2 ... SegmentName1, UserEmailOrGroupName1, UserEmailOrGroupName2 ... Roles, userEmails, userGroups are expected to be added before putting them in this csv. Any new segment names defined here will add a new segment. If a segment isn't added, it shall be deleted.
     *
     * @tags Approvals
     * @name UploadApprovalMatrixCsv
     * @summary Upload an Approval Matrix
     * @request POST:/approvalMatrix/csv
     */
    uploadApprovalMatrixCsv: (data: any, params: RequestParams = {}) =>
      this.request<ApprovalMatrixImportDataJson, any>({
        path: `/approvalMatrix/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the details of an import specified by the passed ID
     *
     * @tags Approvals
     * @name GetImportDetailsById
     * @summary Get import details
     * @request GET:/approvalMatrix/csv/{importId}
     */
    getImportDetailsById: (importId: string, params: RequestParams = {}) =>
      this.request<ApprovalMatrixImportDataJson, any>({
        path: `/approvalMatrix/csv/${importId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Downloads the CSV for an import activity specified by the passed ID
     *
     * @tags Approvals
     * @name GetImportResult
     * @summary Download the csv for an import
     * @request GET:/approvalMatrix/csv/{importId}/download
     */
    getImportResult: (importId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalMatrix/csv/${importId}/download`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Preview the changes that a specified approval matrix import will have once applied
     *
     * @tags Approvals
     * @name GetImportPreview
     * @summary Preview import changes
     * @request GET:/approvalMatrix/csv/{importId}/preview
     */
    getImportPreview: (importId: string, params: RequestParams = {}) =>
      this.request<ApprovalMatrixImportPreview, any>({
        path: `/approvalMatrix/csv/${importId}/preview`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Submit and finalize the import for the specified import operation.
     *
     * @tags Approvals
     * @name SubmitApprovalMatrixCsv
     * @summary Finalize an import
     * @request POST:/approvalMatrix/csv/{importId}/submit
     */
    submitApprovalMatrixCsv: (importId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalMatrix/csv/${importId}/submit`,
        method: 'POST',
        ...params,
      }),
  }
  approvalRoles = {
    /**
     * @description Gets all approval roles
     *
     * @tags Approvals
     * @name GetApprovalRoles
     * @summary Get approval roles
     * @request GET:/approvalRoles
     */
    getApprovalRoles: (params: RequestParams = {}) =>
      this.request<ApprovalRoleJson[], any>({
        path: `/approvalRoles`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds an approval role to the order execution hierarchy and returns its ID
     *
     * @tags Approvals
     * @name AddApprovalRole
     * @summary Add an approval role
     * @request POST:/approvalRoles
     */
    addApprovalRole: (body: ApprovalRoleJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/approvalRoles`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the specified approval role
     *
     * @tags Approvals
     * @name GetApprovalRoleById
     * @summary Get Details of an approval role
     * @request GET:/approvalRoles/{approvalRoleId}
     */
    getApprovalRoleById: (approvalRoleId: string, params: RequestParams = {}) =>
      this.request<ApprovalRoleJson, any>({
        path: `/approvalRoles/${approvalRoleId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified approval role
     *
     * @tags Approvals
     * @name UpdateApprovalRole
     * @summary Update an approval role
     * @request PUT:/approvalRoles/{approvalRoleId}
     */
    updateApprovalRole: (approvalRoleId: string, body: ApprovalRoleJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalRoles/${approvalRoleId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes an approval role
     *
     * @tags Approvals
     * @name DeleteApprovalRole
     * @summary Delete an approval role
     * @request DELETE:/approvalRoles/{approvalRoleId}
     */
    deleteApprovalRole: (approvalRoleId: string, params: RequestParams = {}) =>
      this.request<ApprovalRoleJson, any>({
        path: `/approvalRoles/${approvalRoleId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  }
  approvalSegments = {
    /**
     * @description Gets the details of the specified approval segment
     *
     * @tags Approvals
     * @name GetApprovalSegmentById
     * @summary Get approval segment details
     * @request GET:/approvalSegments/{approvalSegmentId}
     */
    getApprovalSegmentById: (approvalSegmentId: string, params: RequestParams = {}) =>
      this.request<ApprovalSegmentJson, any>({
        path: `/approvalSegments/${approvalSegmentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Update the details of the specified approval segment
     *
     * @tags Approvals
     * @name UpdateApprovalSegment
     * @summary Update an approval segment
     * @request PUT:/approvalSegments/{approvalSegmentId}
     */
    updateApprovalSegment: (approvalSegmentId: string, body: ApprovalSegmentJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/approvalSegments/${approvalSegmentId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes the segment specified by the id
     *
     * @tags Approvals
     * @name DeleteApprovalSegment
     * @summary Delete a segment
     * @request DELETE:/approvalSegments/{approvalSegmentId}
     */
    deleteApprovalSegment: (approvalSegmentId: string, params: RequestParams = {}) =>
      this.request<ApprovalSegmentJson, any>({
        path: `/approvalSegments/${approvalSegmentId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get all approval segments
     *
     * @tags Approvals
     * @name GetApprovalSegments
     * @summary Get approval segments
     * @request GET:/approvalSegments
     */
    getApprovalSegments: (params: RequestParams = {}) =>
      this.request<ApprovalSegmentJson[], any>({
        path: `/approvalSegments`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Define and add an approval segment which can be later specified in an approval matrix. The ID of the segment is returned.
     *
     * @tags Approvals
     * @name AddApprovalSegment
     * @summary Add an approval segment
     * @request POST:/approvalSegments
     */
    addApprovalSegment: (body: ApprovalSegmentJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/approvalSegments`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  attachments = {
    /**
     * @description Updates the details of the specified document
     *
     * @tags Attachments
     * @name ModifyAttachment
     * @summary Update the details of an attachment
     * @request PUT:/attachments
     */
    modifyAttachment: (body: Attachment, params: RequestParams = {}) =>
      this.request<Attachment, any>({
        path: `/attachments`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Lists all the documents attached to an account
     *
     * @tags Attachments
     * @name ListAccountAttachments
     * @summary Get attachments for an account
     * @request GET:/attachments/account/{accountId}
     */
    listAccountAttachments: (accountId: string, params: RequestParams = {}) =>
      this.request<Attachment[], any>({
        path: `/attachments/account/${accountId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Attaches a document to the specified account. The post body should contain the body of the document that is to be attached. On success the Id of the attachment is returned.
     *
     * @tags Attachments
     * @name AddAttachmentToAccount
     * @summary Add an attachment
     * @request POST:/attachments/account/{accountId}
     */
    addAttachmentToAccount: (
      accountId: string,
      data: {
        file?: File
      },
      query?: {
        /** name to associate with the attachment */
        fileName?: string
        /** description of the document */
        description?: string
        /**
         * size in bytes of the document
         * @format int64
         */
        size?: number
        /** tag to apply to the attachment */
        tag?: 'MASTER_SUBSCRIPTION_AGREEMENT' | 'STATEMENT_OF_WORK' | 'ORDER_FORM' | 'OTHER'
      },
      params: RequestParams = {}
    ) =>
      this.request<Attachment, any>({
        path: `/attachments/account/${accountId}`,
        method: 'POST',
        query: query,
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the contents of the specified attachment
     *
     * @tags Attachments
     * @name GetAttachment
     * @summary Get attachment contents
     * @request GET:/attachments/{attachmentId}
     */
    getAttachment: (attachmentId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/attachments/${attachmentId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Unattaches and deletes the specified document
     *
     * @tags Attachments
     * @name DeleteAttachmentFromAccount
     * @summary Delete an attachment
     * @request DELETE:/attachments/{attachmentId}
     */
    deleteAttachmentFromAccount: (attachmentId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/attachments/${attachmentId}`,
        method: 'DELETE',
        ...params,
      }),
  }
  auth = {
    /**
     * @description Adds a saml integration
     *
     * @tags Authentication
     * @name AddSamlIntegration
     * @summary Add a saml integration
     * @request POST:/auth/saml
     */
    addSamlIntegration: (body: AuthSamlIntegrationJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/auth/saml`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  automatedInvoiceRules = {
    /**
     * @description Returns the details of the specified automated invoice rule
     *
     * @tags Billing
     * @name GetAutomatedInvoiceRule
     * @summary Get automated invoice rule details
     * @request GET:/automatedInvoiceRules/{id}
     */
    getAutomatedInvoiceRule: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Updates the details of the specified automated invoice rule
     *
     * @tags Billing
     * @name UpdateAutomatedInvoiceRule
     * @summary Update automated invoice rule details
     * @request PUT:/automatedInvoiceRules/{id}
     */
    updateAutomatedInvoiceRule: (id: string, body: AutomatedInvoiceRuleRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules/${id}`,
        method: 'PUT',
        body: body,
        ...params,
      }),

    /**
     * @description Returns all the configured automated invoice rules for the tenant
     *
     * @tags Billing
     * @name GetAutomatedInvoiceRules
     * @summary Get automated invoice rules for a tenant
     * @request GET:/automatedInvoiceRules
     */
    getAutomatedInvoiceRules: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Creates an automated invoice rule with the specified parameters
     *
     * @tags Billing
     * @name AddAutomatedInvoiceRule
     * @summary Create an automated invoice rule
     * @request POST:/automatedInvoiceRules
     */
    addAutomatedInvoiceRule: (body: AutomatedInvoiceRuleRequestJson, params: RequestParams = {}) =>
      this.request<AutomatedInvoiceRule, any>({
        path: `/automatedInvoiceRules`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAutomatedInvoiceRuleByInternalId
     * @request GET:/automatedInvoiceRules/internal/{id}
     * @deprecated
     */
    getAutomatedInvoiceRuleByInternalId: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules/internal/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateAutomatedInvoiceRuleUsingInternalId
     * @request PUT:/automatedInvoiceRules/internal/{id}
     * @deprecated
     */
    updateAutomatedInvoiceRuleUsingInternalId: (
      id: string,
      body: AutomatedInvoiceRuleRequestJson,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/automatedInvoiceRules/internal/${id}`,
        method: 'PUT',
        body: body,
        ...params,
      }),
  }
  avalara = {
    /**
     * @description Gets the integration details of the specified integration id
     *
     * @tags Integrations
     * @name GetIntegration1
     * @summary Gets Avalara integration details
     * @request GET:/avalara/{integrationId}
     */
    getIntegration1: (integrationId: string, params: RequestParams = {}) =>
      this.request<AvalaraIntegration, any>({
        path: `/avalara/${integrationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the Avalara integration for your tenant
     *
     * @tags Integrations
     * @name GetIntegrationByTenant
     * @summary Gets Avalara integration details
     * @request GET:/avalara
     */
    getIntegrationByTenant: (params: RequestParams = {}) =>
      this.request<AvalaraIntegration, any>({
        path: `/avalara`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates an integration with Avalara according to the specified details. On success the id of the integration is returned
     *
     * @tags Integrations
     * @name Integrate
     * @summary Create an Avalara integration
     * @request POST:/avalara
     */
    integrate: (body: AvalaraIntegrationInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/avalara`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Once an integration is set up Avalara can be pinged to ensure it is working correctly. On success "PONG" is returned.
     *
     * @tags Integrations
     * @name Ping
     * @summary Pings Avalara
     * @request PUT:/avalara/ping
     */
    ping: (body: AvalaraIntegrationInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/avalara/ping`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  charges = {
    /**
     * @description Gets the details of the specified charge.
     *
     * @tags Product Catalog
     * @name GetCharge
     * @summary Get charge details
     * @request GET:/charges/{chargeId}
     */
    getCharge: (chargeId: string, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/charges/${chargeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  compositeOrders = {
    /**
     * @description Downloads the order form PDF for the specified order. This PDF must have been generated via a POST to /{id}/pdf. The response is the PDF document.
     *
     * @tags Orders
     * @name GetCompositeOrderDocument
     * @summary Fetch composite order form PDF
     * @request GET:/compositeOrders/{id}/pdf
     */
    getCompositeOrderDocument: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/compositeOrders/${id}/pdf`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Generates a composite order form PDF. When completed this document can be downloaded via a get to /{id}/pdf.
     *
     * @tags Orders
     * @name CreateCompositeOrderDocument
     * @summary Generate a composite order PDF
     * @request POST:/compositeOrders/{id}/pdf
     */
    createCompositeOrderDocument: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/compositeOrders/${id}/pdf`,
        method: 'POST',
        ...params,
      }),
  }
  creditmemos = {
    /**
     * @description Gets the balance of the specified credit memo
     *
     * @tags Credit Memo
     * @name GetCreditBalance
     * @summary Get credit memo balance
     * @request GET:/creditmemos/{creditMemoNumber}/balance
     */
    getCreditBalance: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoBalanceJson, any>({
        path: `/creditmemos/${creditMemoNumber}/balance`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Downloads the pdf version of the credit memo. Note the credit memo must already have been created. If the credit memo is still in the process of being created, HTTP 202 is returned.
     *
     * @tags Credit Memo
     * @name GetCreditMemoDocumentPdf
     * @summary Download credit memo pdf
     * @request GET:/creditmemos/{creditMemoNumber}/pdf
     */
    getCreditMemoDocumentPdf: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/creditmemos/${creditMemoNumber}/pdf`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Creates a pdf version of the credit memo
     *
     * @tags Credit Memo
     * @name CreateCreditMemoDocument
     * @summary Create a credit memo document
     * @request POST:/creditmemos/{creditMemoNumber}/pdf
     */
    createCreditMemoDocument: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/creditmemos/${creditMemoNumber}/pdf`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Returns a paginated list of credit memos for the specified account. Pass the cursor returned to subsequent calls to retrieve all data.
     *
     * @tags Credit Memo
     * @name GetCreditMemoForAccount
     * @summary Get credit memos for an account
     * @request GET:/creditmemos
     */
    getCreditMemoForAccount: (
      query?: {
        /** id of the account */
        accountId?: string
        /**
         * cursor used to move the pages
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<CreditMemoPaginationResponseJson, any>({
        path: `/creditmemos`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a standalone credit memo for a specified account. On success the number of the new memo is returned
     *
     * @tags Credit Memo
     * @name CreateStandaloneCreditMemo
     * @summary Create a standalone credit memo
     * @request POST:/creditmemos
     */
    createStandaloneCreditMemo: (body: StandaloneCreditMemoRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/creditmemos`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the specified credit memo
     *
     * @tags Credit Memo
     * @name GetCreditMemo
     * @summary Get credit memo details
     * @request GET:/creditmemos/{creditMemoNumber}
     */
    getCreditMemo: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/${creditMemoNumber}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of a credit memo in DRAFT status
     *
     * @tags Credit Memo
     * @name UpdateDraftCreditMemo
     * @summary Update a credit memo
     * @request PUT:/creditmemos/{creditMemoNumber}
     */
    updateDraftCreditMemo: (creditMemoNumber: string, body: StandaloneCreditMemoRequest, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/${creditMemoNumber}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes the specified credit memo. The credit memo must be in DRAFT status
     *
     * @tags Credit Memo
     * @name DeleteCreditMemo
     * @summary Delete a credit memo
     * @request DELETE:/creditmemos/{creditMemoNumber}
     */
    deleteCreditMemo: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/${creditMemoNumber}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Sets the status of the specified credit memo to POSTED
     *
     * @tags Credit Memo
     * @name PostCreditMemo
     * @summary Post a credit memo
     * @request POST:/creditmemos/{creditMemoNumber}/post
     */
    postCreditMemo: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/${creditMemoNumber}/post`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the credit memo configuration for your tenant
     *
     * @tags Credit Memo
     * @name GetCreditMemoConfiguration
     * @summary Get credit memo configuration for the tenant
     * @request GET:/creditmemos/configuration
     */
    getCreditMemoConfiguration: (params: RequestParams = {}) =>
      this.request<TenantCreditMemoConfigurationJson, any>({
        path: `/creditmemos/configuration`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the credit memo configuration for you tenant.
     *
     * @tags Credit Memo
     * @name UpdateCreditMemoConfiguration
     * @summary Update credit memo configuration
     * @request POST:/creditmemos/configuration
     */
    updateCreditMemoConfiguration: (body: TenantCreditMemoConfigurationJson, params: RequestParams = {}) =>
      this.request<TenantCreditMemoConfigurationJson, any>({
        path: `/creditmemos/configuration`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Converts the specified invoice to a credit memo. Note the invoice must be in DRAFT status and have a negative balance.
     *
     * @tags Credit Memo
     * @name ConvertNegativeDraftInvoice
     * @summary Convert invoice to credit memo
     * @request PUT:/creditmemos/convert/{invoiceNumber}
     */
    convertNegativeDraftInvoice: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<CreditMemoJson, any>({
        path: `/creditmemos/convert/${invoiceNumber}`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),
  }
  customFieldDefinition = {
    /**
     * @description Creates a new custom field definition for your tenant. On success the id of the custom field definition is returned.
     *
     * @tags Custom Field
     * @name CreateCustomFieldDefinition
     * @summary Create a custom field definition
     * @request POST:/customFieldDefinition
     */
    createCustomFieldDefinition: (body: CustomFieldDefinitionCreateInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/customFieldDefinition`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates an existing custom field definition for your tenant. On success the update custom field definition is returned.
     *
     * @tags Custom Field
     * @name UpdateCustomFieldDefinition
     * @summary Update an existing custom field definition
     * @request PUT:/customFieldDefinition/{id}
     */
    updateCustomFieldDefinition: (id: string, body: CustomFieldDefinitionUpdateInput, params: RequestParams = {}) =>
      this.request<CustomFieldDefinitionJson, any>({
        path: `/customFieldDefinition/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes an existing custom field definition for your tenant. On success the deleted custom field definition object is returned.
     *
     * @tags Custom Field
     * @name DeleteCustomFieldDefinition
     * @summary Delete a custom field definition
     * @request DELETE:/customFieldDefinition/{id}
     */
    deleteCustomFieldDefinition: (id: string, params: RequestParams = {}) =>
      this.request<CustomFieldDefinitionJson, any>({
        path: `/customFieldDefinition/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all custom field definitions for a specific parent object type
     *
     * @tags Custom Field
     * @name GetCustomFieldDefinitions
     * @summary Get custom field definitions
     * @request GET:/customFieldDefinition/{parentObjectType}
     */
    getCustomFieldDefinitions: (parentObjectType: string, params: RequestParams = {}) =>
      this.request<CustomFieldDefinitionJson[], any>({
        path: `/customFieldDefinition/${parentObjectType}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  customField = {
    /**
     * @description Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.
     *
     * @tags Custom Field
     * @name UpdateCustomField
     * @summary Update an existing set of custom fields
     * @request PUT:/customField/{parentObjectType}/{parentObjectId}/{customFieldName}
     */
    updateCustomField: (
      parentObjectType: string,
      parentObjectId: string,
      customFieldName: string,
      body: CustomFieldUpdateInput,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}/${customFieldName}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.
     *
     * @tags Custom Field
     * @name ForceUpdateCustomField
     * @summary Update an existing set of custom fields
     * @request PUT:/customField/{parentObjectType}/{parentObjectId}/{customFieldName}/force
     */
    forceUpdateCustomField: (
      parentObjectType: string,
      parentObjectId: string,
      customFieldName: string,
      body: CustomFieldUpdateInput,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}/${customFieldName}/force`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns all custom fields for a specific parent object type and id
     *
     * @tags Custom Field
     * @name GetCustomFields
     * @summary Get custom fields by type and parent object id
     * @request GET:/customField/{parentObjectType}/{parentObjectId}
     */
    getCustomFields: (parentObjectType: string, parentObjectId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.
     *
     * @tags Custom Field
     * @name UpdateCustomFields
     * @summary Update an existing set of custom fields
     * @request PUT:/customField/{parentObjectType}/{parentObjectId}
     */
    updateCustomFields: (
      parentObjectType: string,
      parentObjectId: string,
      body: Record<string, CustomFieldValue>,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Updates an existing set of custom fields for a given parent object type and id. On success the update custom fields are returned.
     *
     * @tags Custom Field
     * @name ForceUpdateCustomFields
     * @summary Update an existing set of custom fields
     * @request PUT:/customField/{parentObjectType}/{parentObjectId}/force
     */
    forceUpdateCustomFields: (
      parentObjectType: string,
      parentObjectId: string,
      body: Record<string, CustomFieldValue>,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/customField/${parentObjectType}/${parentObjectId}/force`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  beta = {
    /**
     * @description runs the provided zeppa script and returns a list of Rule actions that were applicable after zeppa script ran
     *
     * @tags Customization
     * @name TestSelectionCustomizationZeppaScript
     * @summary Test a selection customization zeppa script
     * @request PUT:/beta/customization/selectionCustomization/test
     */
    testSelectionCustomizationZeppaScript: (
      query: {
        /** account id against which the test needs to happen */
        accountId: string
      },
      body: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/beta/customization/selectionCustomization/test`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Text,
        ...params,
      }),

    /**
     * @description runs the provided zeppa script and returns a list of Rule actions that were applicable after zeppa script ran
     *
     * @tags Customization
     * @name TestOrderCreationCustomizationZeppaScript
     * @summary Test a order creation customization zeppa script
     * @request PUT:/beta/customization/orderCreationCustomization/test
     */
    testOrderCreationCustomizationZeppaScript: (
      query: {
        /** order id against which the test needs to happen */
        orderId: string
        /** the user id against which this test needs to happen, the user should be part of the tenancy */
        userId?: string
      },
      body: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/beta/customization/orderCreationCustomization/test`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Text,
        ...params,
      }),

    /**
     * @description if this endpoint returns OK it means the selection customization zeppa script has successfully compiled
     *
     * @tags Customization
     * @name CompileSelectionCustomizationZeppaScript
     * @summary Compile and Verify a  selection customization for correctness
     * @request PUT:/beta/customization/selectionCustomization/compile
     */
    compileSelectionCustomizationZeppaScript: (
      query: {
        /** account id against which the compilation should happen */
        accountId: string
      },
      body: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/beta/customization/selectionCustomization/compile`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Text,
        ...params,
      }),

    /**
     * @description if this endpoint returns OK it means the order creation customization zeppa script has successfully compiled
     *
     * @tags Customization
     * @name CompileOrderCreationCustomizationZeppaScript
     * @summary Compile and Verify a order creation customization for correctness
     * @request PUT:/beta/customization/orderCreationCustomization/compile
     */
    compileOrderCreationCustomizationZeppaScript: (
      query: {
        /** order id against which the compilation should happen */
        orderId: string
        /** order id against which the compilation should happen */
        userId?: string
      },
      body: string,
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/beta/customization/orderCreationCustomization/compile`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Text,
        ...params,
      }),
  }
  discounts = {
    /**
     * @description Returns all discounts that have been defined
     *
     * @tags Discounts
     * @name GetDiscounts
     * @summary Get discounts
     * @request GET:/discounts
     */
    getDiscounts: (params: RequestParams = {}) =>
      this.request<DiscountJson[], any>({
        path: `/discounts`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a discount with the specified details. On success, the ID of the new discount is returned.
     *
     * @tags Discounts
     * @name AddDiscount
     * @summary Create a discount
     * @request POST:/discounts
     */
    addDiscount: (body: DiscountJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/discounts`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the specified discount.
     *
     * @tags Discounts
     * @name GetDiscount
     * @summary Get discount
     * @request GET:/discounts/{id}
     */
    getDiscount: (id: string, params: RequestParams = {}) =>
      this.request<DiscountJson, any>({
        path: `/discounts/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified discount. Note that a discount cannot be updated once it is in use by an order.
     *
     * @tags Discounts
     * @name UpdateDiscount
     * @summary Update discount details
     * @request PUT:/discounts/{id}
     */
    updateDiscount: (id: string, body: DiscountJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/discounts/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes a discount. Note that a discount may not be deleted if it is in use.
     *
     * @tags Discounts
     * @name DeleteDiscount
     * @summary Delete a discount
     * @request DELETE:/discounts/{id}
     */
    deleteDiscount: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/discounts/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Updates the status of a discount
     *
     * @tags Discounts
     * @name UpdateDiscountStatus
     * @summary Update discount status
     * @request PUT:/discounts/{id}/status/{status}
     */
    updateDiscountStatus: (id: string, status: 'ACTIVE' | 'DEPRECATED', params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/discounts/${id}/status/${status}`,
        method: 'PUT',
        type: ContentType.Json,
        ...params,
      }),
  }
  docusign = {
    /**
     * @description Complete the docusign integration. This should be called after the integration has been created and an authorization code has been received from Docusign.
     *
     * @tags Integrations
     * @name CompleteIntegration
     * @summary Complete Docusign integration
     * @request GET:/docusign
     */
    completeIntegration: (
      query?: {
        /** authorization code received from Docusign */
        code?: string
        /** id of the integration */
        state?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<DocuSignIntegrationResponseJson, any>({
        path: `/docusign`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates an integration with Docusign. On success a redirect URL is returned.
     *
     * @tags Integrations
     * @name InitiateIntegration
     * @summary Create Docusign integration
     * @request POST:/docusign
     */
    initiateIntegration: (body: DocuSignIntegrationRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/docusign`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes integration with Docusign from your tenant
     *
     * @tags Integrations
     * @name DeleteIntegration
     * @summary Delete Docusign integration
     * @request DELETE:/docusign
     */
    deleteIntegration: (params: RequestParams = {}) =>
      this.request<DocuSignIntegrationResponseJson, any>({
        path: `/docusign`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  a = {
    /**
     * @description Returns a specific predefined terms by Id.
     *
     * @tags Documents
     * @name GetDocumentTemplate
     * @summary Get predefined terms detail
     * @request GET:/{a}/{id}
     */
    getDocumentTemplate: (id: string, params: RequestParams = {}) =>
      this.request<DocumentTemplateJson, any>({
        path: `/{a}/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates a predefined terms
     *
     * @tags Documents
     * @name UpdateDocumentTemplate
     * @summary Update predefined terms
     * @request PUT:/{a}/{id}
     */
    updateDocumentTemplate: (id: string, body: DocumentTemplateRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/{a}/${id}`,
        method: 'PUT',
        body: body,
        ...params,
      }),

    /**
     * @description Deletes the predefined terms specified.
     *
     * @tags Documents
     * @name DeleteDocumentTemplate
     * @summary Delete predefined terms
     * @request DELETE:/{a}/{id}
     */
    deleteDocumentTemplate: (id: string, params: RequestParams = {}) =>
      this.request<DocumentTemplateJson, any>({
        path: `/{a}/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a specific predefined terms by Id and version.
     *
     * @tags Documents
     * @name GetDocumentTemplateVersion
     * @summary Get predefined terms version detail
     * @request GET:/{a}/{id}/versions/{version}
     */
    getDocumentTemplateVersion: (id: string, version: number, params: RequestParams = {}) =>
      this.request<DocumentTemplateJson, any>({
        path: `/{a}/${id}/versions/${version}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns predefined terms by type. These templates can be attached to orders as part of PDF document generation.
     *
     * @tags Documents
     * @name GetDocumentTemplates
     * @summary Get predefined terms
     * @request GET:/{a}
     */
    getDocumentTemplates: (
      query?: {
        type?:
          | 'ORDER'
          | 'INVOICE'
          | 'CREDIT_MEMO'
          | 'EMAIL'
          | 'UPSELL_EARLY_RENEWAL'
          | 'DUNNING'
          | 'CANCEL_AND_RESTRUCTURE'
      },
      params: RequestParams = {}
    ) =>
      this.request<DocumentTemplateJson, any>({
        path: `/{a}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add a new predefined terms that can be attached to an order as part of PDF document generation.
     *
     * @tags Documents
     * @name AddDocumentTemplate
     * @summary Add new predefined terms
     * @request POST:/{a}
     */
    addDocumentTemplate: (body: DocumentTemplateRequestJson, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/{a}`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns predefined terms versions by Id.
     *
     * @tags Documents
     * @name GetDocumentTemplateVersions
     * @summary Get predefined terms versions
     * @request GET:/{a}/{id}/versions
     */
    getDocumentTemplateVersions: (id: string, params: RequestParams = {}) =>
      this.request<DocumentTemplateJson, any>({
        path: `/{a}/${id}/versions`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the status of a predefined terms
     *
     * @tags Documents
     * @name UpdateDocumentTemplateStatus
     * @summary Update predefined terms status
     * @request PUT:/{a}/{id}/status/{status}
     */
    updateDocumentTemplateStatus: (id: string, status: 'DRAFT' | 'ACTIVE' | 'DEPRECATED', params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/{a}/${id}/status/${status}`,
        method: 'PUT',
        type: ContentType.Json,
        ...params,
      }),
  }
  dunning = {
    /**
     * @description Sends a test email for dunning to the use associated with this API call. Note A user bound api key is required for this operation.
     *
     * @tags Billing
     * @name SendTestDunningEmail
     * @summary Send a test email
     * @request POST:/dunning/sendTestEmail/{reminderType}
     */
    sendTestDunningEmail: (
      reminderType:
        | 'WEEK_BEFORE_DUE_DATE'
        | 'DUE_DATE'
        | 'WEEK_AFTER_DUE_DATE'
        | 'TWO_WEEKS_AFTER_DUE_DATE'
        | 'MONTH_AFTER_DUE_DATE'
        | 'TWO_MONTHS_AFTER_DUE_DATE',
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/dunning/sendTestEmail/${reminderType}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Sends a reminder email for the specified invoice
     *
     * @tags Billing
     * @name SendInvoiceReminder
     * @summary Send an Invoice Reminder
     * @request POST:/dunning/sendInvoiceReminder/{invoiceNumber}
     */
    sendInvoiceReminder: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/dunning/sendInvoiceReminder/${invoiceNumber}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),
  }
  dunningSetting = {
    /**
     * @description Returns the dunning settings for your tenant
     *
     * @tags Billing
     * @name GetDunningSetting
     * @summary Get dunning settings
     * @request GET:/dunningSetting
     */
    getDunningSetting: (params: RequestParams = {}) =>
      this.request<DunningSettingJson, any>({
        path: `/dunningSetting`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the dunning settings for your tenant
     *
     * @tags Billing
     * @name UpdateDunningSetting
     * @summary Update dunning settings
     * @request PUT:/dunningSetting
     */
    updateDunningSetting: (body: DunningSettingJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/dunningSetting`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  entities = {
    /**
     * @description Gets all entities
     *
     * @tags Entities
     * @name GetEntities
     * @summary Gets entities
     * @request GET:/entities
     */
    getEntities: (params: RequestParams = {}) =>
      this.request<EntityJson[], any>({
        path: `/entities`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Create an entity. On success return the created entity.
     *
     * @tags Entities
     * @name Create
     * @summary Create an entity
     * @request POST:/entities
     */
    create: (body: EntityJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/entities`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Gets the entity details of the specified entity id
     *
     * @tags Entities
     * @name GetEntityById
     * @summary Gets entity details
     * @request GET:/entities/{id}
     */
    getEntityById: (id: string, params: RequestParams = {}) =>
      this.request<Entity, any>({
        path: `/entities/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete the entity if there is no data associated with it.
     *
     * @tags Entities
     * @name DeleteEntity
     * @summary Delete entity
     * @request DELETE:/entities/{id}
     */
    deleteEntity: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/entities/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Get the current logo stored for the given entity
     *
     * @tags Entities
     * @name GetLogo
     * @summary Get entity logo
     * @request GET:/entities/logo/{entityId}
     */
    getLogo: (entityId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/entities/logo/${entityId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Updates the logo used in external facing communication such as order forms and invoices
     *
     * @tags Entities
     * @name UploadLogo
     * @summary Update entity logo
     * @request PUT:/entities/logo/{entityId}
     */
    uploadLogo: (
      entityId: string,
      data: {
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/logo/${entityId}`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Move subscription and associated transactions to a target entity
     *
     * @tags Entities
     * @name SubscriptionEntityMigration
     * @summary Move subscription to a target entity
     * @request POST:/entities/migrate/subscription
     */
    subscriptionEntityMigration: (
      query?: {
        targetEntityId?: string
        subscriptionId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/migrate/subscription`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * @description Move order to a target entity
     *
     * @tags Entities
     * @name OrderEntityMigration
     * @summary Update order entity
     * @request POST:/entities/migrate/order
     */
    orderEntityMigration: (
      query?: {
        targetEntityId?: string
        orderId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/migrate/order`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * @description Move account along with its subscriptions, orders, invoices and other associated transactions to a target entity
     *
     * @tags Entities
     * @name AccountEntityMigration
     * @summary Move account to a target entity
     * @request POST:/entities/migrate/account
     */
    accountEntityMigration: (
      query?: {
        targetEntityId?: string
        accountId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/migrate/account`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * @description Move composite order along with its subscriptions, orders and other associated transactions to a target entity
     *
     * @tags Entities
     * @name CompositeOrderEntityMigration
     * @summary Move composite order to a target entity
     * @request POST:/entities/migrate/compositeOrder
     */
    compositeOrderEntityMigration: (
      query?: {
        targetEntityId?: string
        compositeOrderId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/entities/migrate/compositeOrder`,
        method: 'POST',
        query: query,
        ...params,
      }),
  }
  erp = {
    /**
     * @description Trigger ERP journal entry sync task for given accounting period id
     *
     * @tags ERP
     * @name ProcessSyncTasks
     * @summary Trigger ERP journal entry sync task
     * @request POST:/erp/processSyncTasks/{accountingPeriodId}
     */
    processSyncTasks: (accountingPeriodId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/processSyncTasks/${accountingPeriodId}`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Trigger ERP journal entry deletion task for the given accounting period id
     *
     * @tags ERP
     * @name ProcessDeleteTasks
     * @summary Trigger ERP journal entry deletion task
     * @request POST:/erp/processDeleteTasks/{accountingPeriodId}
     */
    processDeleteTasks: (accountingPeriodId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/processDeleteTasks/${accountingPeriodId}`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Trigger ERP invoice sync task for the given invoice id
     *
     * @tags ERP
     * @name SyncInvoiceToErp
     * @summary Trigger ERP invoice sync task
     * @request POST:/erp/syncInvoice/{invoiceId}
     */
    syncInvoiceToErp: (invoiceId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/syncInvoice/${invoiceId}`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Trigger ERP credit memo sync task for the given credit memo number
     *
     * @tags ERP
     * @name SyncCreditMemoToErp
     * @summary Trigger ERP credit memo sync task
     * @request POST:/erp/syncCreditMemo/{creditMemoNumber}
     */
    syncCreditMemoToErp: (creditMemoNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/syncCreditMemo/${creditMemoNumber}`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Trigger ERP void invoice sync task for the given invoice number
     *
     * @tags ERP
     * @name SyncVoidInvoiceToErp
     * @summary Trigger ERP void invoice sync task
     * @request POST:/erp/syncVoidInvoice/{invoiceNumber}
     */
    syncVoidInvoiceToErp: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/erp/syncVoidInvoice/${invoiceNumber}`,
        method: 'POST',
        ...params,
      }),
  }
  hubspot = {
    /**
     * @description Endpoint to handle and process a HubSpot authorization code
     *
     * @tags Integrations
     * @name AuthorizationCodeCallback
     * @summary Handle HubSpot authorization code
     * @request GET:/hubspot
     */
    authorizationCodeCallback: (
      query: {
        /** authorization code */
        code: string
        /** HubSpot integration id */
        state: string
        /** uri to redirect to HubSpot */
        redirect_uri: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/hubspot`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Initiates an integration with HubSpot. On success redirect URL is returned.
     *
     * @tags Integrations
     * @name InitiateIntegration1
     * @summary Initiate a HubSpot integration
     * @request POST:/hubspot
     */
    initiateIntegration1: (email: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/hubspot`,
        method: 'POST',
        body: email,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes your integration with HubSpot.
     *
     * @tags Integrations
     * @name DeleteIntegration1
     * @summary Delete HubSpot Integration
     * @request DELETE:/hubspot
     */
    deleteIntegration1: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hubspot`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Add custom properties to HubSpot
     *
     * @tags Integrations
     * @name SetupHubSpot
     * @summary Add custom properties to HubSpot
     * @request POST:/hubspot/setup
     */
    setupHubSpot: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hubspot/setup`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Verify custom objects and properties
     *
     * @tags Integrations
     * @name VerifySetup
     * @summary Verify HubSpot setup
     * @request POST:/hubspot/setup/verify
     */
    verifySetup: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hubspot/setup/verify`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name SyncEsignDetailsForOrderToHubSpot
     * @summary Sync esign details for order id
     * @request POST:/hubspot/esign/{orderId}
     */
    syncEsignDetailsForOrderToHubSpot: (orderId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/hubspot/esign/${orderId}`,
        method: 'POST',
        type: ContentType.Json,
        ...params,
      }),
  }
  import = {
    /**
     * @description Returns the details of an import by its ID
     *
     * @tags Import
     * @name GetImportResult1
     * @summary Get import details
     * @request GET:/import/{importId}/result
     */
    getImportResult1: (importId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/import/${importId}/result`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Gets new orders in a format appropriate for re-import. On success, the output is a csv containing the orders.
     *
     * @tags Import
     * @name GetNewOrderExportInImportFormat
     * @summary Get new orders in import CSV format
     * @request GET:/import/export/newOrders
     */
    getNewOrderExportInImportFormat: (
      query?: {
        /** use the subskribe ID instead of external ID for exported objects */
        useRealIds?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/import/export/newOrders`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Gets amendment orders in a format appropriate for re-import for the given generation. On success, the output is a csv containing the orders.
     *
     * @tags Import
     * @name GetAmendmentOrderExportInImportFormat
     * @summary Get amendment orders in import CSV format
     * @request GET:/import/export/amendmentOrders
     */
    getAmendmentOrderExportInImportFormat: (
      query?: {
        /**
         * the generation of amendments to include in the export, generations are defined as 1 based index of number of amendments applied to subscription
         * @format int32
         */
        generation?: number
        /** use the subskribe ID instead of external ID for exported objects */
        useRealIds?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/import/export/amendmentOrders`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Gets account contacts in a format appropriate for re-import. On success, the output is a csv containing the account and contacts.
     *
     * @tags Import
     * @name GetAccountContactsInImportFormat
     * @summary Get account contacts for export
     * @request GET:/import/export/accountContact
     */
    getAccountContactsInImportFormat: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/import/export/accountContact`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Gets catalog data in a format appropriate for re-import. On success, the output is a csv containing the catalog data containing Product, Plan and Charge data.
     *
     * @tags Import
     * @name GetCatalogDataInImportFormat
     * @summary Get catalog data for export
     * @request GET:/import/export/catalog
     */
    getCatalogDataInImportFormat: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/import/export/catalog`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Processes the import specified.
     *
     * @tags Import
     * @name ProcessImport
     * @summary Process an import by ID
     * @request PUT:/import/{importId}/process
     */
    processImport: (importId: string, params: RequestParams = {}) =>
      this.request<DataImport, any>({
        path: `/import/${importId}/process`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets all items that was imported
     *
     * @tags Import
     * @name GetDataImports
     * @summary Gets all import items
     * @request GET:/import
     */
    getDataImports: (params: RequestParams = {}) =>
      this.request<DataImport, any>({
        path: `/import`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Validates a multi-part import file. Returns an import ID on success.
     *
     * @tags Import
     * @name ValidateMultiPartFileImport
     * @summary Validate import file
     * @request POST:/import
     */
    validateMultiPartFileImport: (data: any, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/import`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the set of schemas available for import
     *
     * @tags Import
     * @name GetAvailableSchemas
     * @summary Return available schemas
     * @request GET:/import/schemas
     */
    getAvailableSchemas: (params: RequestParams = {}) =>
      this.request<Record<string, object[]>, any>({
        path: `/import/schemas`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a Flatfile workbook and adds it to a space
     *
     * @tags Import
     * @name CreateFlatfileWorkbook
     * @summary Create a Flatfile workbook
     * @request POST:/import/flatfile/{domain}
     */
    createFlatfileWorkbook: (domain: 'ORDER', params: RequestParams = {}) =>
      this.request<FlatfileWorkbookResponse, any>({
        path: `/import/flatfile/${domain}`,
        method: 'POST',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets an item that was imported by its ID
     *
     * @tags Import
     * @name GetDataImportById
     * @summary Gets an import item
     * @request GET:/import/{importId}
     */
    getDataImportById: (importId: string, params: RequestParams = {}) =>
      this.request<DataImport, any>({
        path: `/import/${importId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  invoices = {
    /**
     * @description Generates the invoices for the given subscription
     *
     * @tags Billing
     * @name GenerateInvoice
     * @summary Generate subscription invoices
     * @request POST:/invoices/generate
     */
    generateInvoice: (
      query: {
        /** id of the subscription */
        subscriptionId: string
        /**
         * Time in seconds since Epoch (GMT) to generate invoice from
         * @format int64
         */
        targetDate: number
        /**
         * Time in seconds since Epoch (GMT) to set invoice date to
         * @format int64
         */
        invoiceDate?: number
        /** types of charges to include */
        invoiceChargeInclusionOption: 'INCLUDE_USAGE' | 'EXCLUDE_USAGE' | 'ONLY_USAGE'
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceJson[], any>({
        path: `/invoices/generate`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the unbilled usage invoice items for the specified subscription
     *
     * @tags Billing
     * @name PreviewInvoiceByOrderPeriod
     * @summary Get unbilled usage
     * @request GET:/invoices/unbilledUsage
     */
    previewInvoiceByOrderPeriod: (
      query: {
        /** id of the subscription */
        subscriptionId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceItemJson[], any>({
        path: `/invoices/unbilledUsage`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a preview of invoice for the specified order id OR subscription id
     *
     * @tags Billing
     * @name PreviewInvoiceByOrderPeriod1
     * @summary Preview invoices
     * @request GET:/invoices/preview
     */
    previewInvoiceByOrderPeriod1: (
      query?: {
        /** id of order */
        orderId?: string
        /** id of subscription */
        subscriptionId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoicePreviewJson[], any>({
        path: `/invoices/preview`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all invoices for a subscription. The result is paginated. Use the cursor returned from a request in subsequent calls to retrieve all results.
     *
     * @tags Billing
     * @name GetInvoices
     * @summary Get all invoices for a subscription
     * @request GET:/invoices
     */
    getInvoices: (
      query?: {
        subscriptionId?: string
        /** status filter for invoices */
        status?: 'DRAFT' | 'POSTED' | 'PAID' | 'CONVERTED' | 'VOIDED'
        /**
         * used to iterate through all results
         * @format uuid
         */
        cursor?: string
        /**
         * number of items per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceJsonPaginationResponse, any>({
        path: `/invoices`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the specified invoice number
     *
     * @tags Billing
     * @name GetInvoice
     * @summary Get invoice details
     * @request GET:/invoices/{number}
     */
    getInvoice: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified invoice.
     *
     * @tags Billing
     * @name UpdateInvoice
     * @summary Update invoice details
     * @request PUT:/invoices/{number}
     */
    updateInvoice: (number: string, body: UpdateInvoiceRequest, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes the specified invoice
     *
     * @tags Billing
     * @name DeleteInvoice
     * @summary Delete invoice
     * @request DELETE:/invoices/{number}
     */
    deleteInvoice: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the balance of the specified invoice number
     *
     * @tags Billing
     * @name GetInvoiceBalance
     * @summary Get invoice balance
     * @request GET:/invoices/{number}/balance
     */
    getInvoiceBalance: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceBalanceJson, any>({
        path: `/invoices/${number}/balance`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Marks the specified invoice as posted
     *
     * @tags Billing
     * @name PostInvoice
     * @summary Mark invoice as posted
     * @request POST:/invoices/{number}/post
     */
    postInvoice: (number: string, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}/post`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Marks the specified invoice as voided
     *
     * @tags Billing
     * @name VoidInvoice
     * @summary Mark invoice as voided
     * @request PUT:/invoices/{number}/void
     */
    voidInvoice: (number: string, body: VoidInvoiceRequest, params: RequestParams = {}) =>
      this.request<InvoiceJson, any>({
        path: `/invoices/${number}/void`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Downloads the PDF for an invoice after it has been generated via a POST to /{invoiceNumber}/pdf. The data return will be the PDF document contents.
     *
     * @tags Billing
     * @name GetInvoiceDocumentPdf
     * @summary Download invoice PDF
     * @request GET:/invoices/{invoiceNumber}/pdf
     */
    getInvoiceDocumentPdf: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/invoices/${invoiceNumber}/pdf`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Generates a PDF version of the specified invoice. Once generated, PDF can be later fetched via a call to /{invoiceNumber}/pdf.
     *
     * @tags Billing
     * @name CreateInvoiceDocument
     * @summary Generate invoice PDF
     * @request POST:/invoices/{invoiceNumber}/pdf
     */
    createInvoiceDocument: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/invoices/${invoiceNumber}/pdf`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Billing schedules must be deleted in reverse chronological order.
     *
     * @tags Billing
     * @name DeleteBillingSchedule
     * @summary Delete an existing billing schedule
     * @request DELETE:/invoices/schedule/{id}
     */
    deleteBillingSchedule: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/invoices/schedule/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Retrieve all billing schedules for a subscription line item
     *
     * @tags Billing
     * @name GetBillingSchedules
     * @summary Get billing schedules for a subscription line item
     * @request GET:/invoices/schedule
     */
    getBillingSchedules: (
      query: {
        subscriptionId: string
        subscriptionChargeId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<BillingEventEntry[], any>({
        path: `/invoices/schedule`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new billing schedule entry for an event based charge
     *
     * @tags Billing
     * @name AddBillingSchedule
     * @summary Add a new billing schedule for subscription line item
     * @request POST:/invoices/schedule
     */
    addBillingSchedule: (body: BillingEventInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/invoices/schedule`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the specified bulk invoice run
     *
     * @tags Billing
     * @name GetBulkInvoiceRun
     * @summary Get bulk invoice run details
     * @request GET:/invoices/bulk/{bulkInvoiceRunId}
     */
    getBulkInvoiceRun: (bulkInvoiceRunId: string, params: RequestParams = {}) =>
      this.request<BulkInvoiceRun, any>({
        path: `/invoices/bulk/${bulkInvoiceRunId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the items associated with the specified bulk invoice run
     *
     * @tags Billing
     * @name GetBulkInvoiceRunItems
     * @summary Get items for bulk invoice run
     * @request GET:/invoices/bulk/{bulkInvoiceRunId}/runItems
     */
    getBulkInvoiceRunItems: (bulkInvoiceRunId: string, params: RequestParams = {}) =>
      this.request<BulkInvoiceRunItem[], any>({
        path: `/invoices/bulk/${bulkInvoiceRunId}/runItems`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Marks all invoices associated with the specified bulk run as posted
     *
     * @tags Billing
     * @name PostInvoicesForBulkInvoiceRun
     * @summary Posts invoices for a bulk run
     * @request PUT:/invoices/bulk/{bulkInvoiceRunId}/post
     */
    postInvoicesForBulkInvoiceRun: (bulkInvoiceRunId: string, body: string[], params: RequestParams = {}) =>
      this.request<BulkInvoiceRun, any>({
        path: `/invoices/bulk/${bulkInvoiceRunId}/post`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the invoice sequence by invoice config id.
     *
     * @tags Billing
     * @name UpdateInvoiceSequence
     * @summary Update invoice sequence
     * @request POST:/invoices/sequence/{invoiceConfigId}
     */
    updateInvoiceSequence: (
      invoiceConfigId: string,
      query?: {
        /**
         * long integer value
         * @format int64
         */
        'next invoice number'?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<TenantInvoiceConfig, any>({
        path: `/invoices/sequence/${invoiceConfigId}`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the usage invoice items for the specified subscription and charge
     *
     * @tags Billing
     * @name GetUsageForSubscriptionCharge
     * @summary Get usage
     * @request GET:/invoices/usage
     */
    getUsageForSubscriptionCharge: (
      query: {
        /** id of the subscription */
        subscriptionId: string
        /** id of the charge */
        chargeId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<InvoiceItemJson[], any>({
        path: `/invoices/usage`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Invoice document JSON that contains all details required to render full Invoice document
     *
     * @tags Billing
     * @name GetRawInvoiceDocumentJson
     * @summary Get the invoice document JSON that is used to render invoice PDF
     * @request GET:/invoices/{invoiceNumber}/documentJson
     */
    getRawInvoiceDocumentJson: (invoiceNumber: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/invoices/${invoiceNumber}/documentJson`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Creates a bulk invoice run as specified by the input parameters. On success the id of the run is returned.
     *
     * @tags Billing
     * @name CreateBulkInvoiceRun
     * @summary Create a bulk invoice run
     * @request POST:/invoices/bulk
     */
    createBulkInvoiceRun: (body: BulkInvoiceRunInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/invoices/bulk`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  settlements = {
    /**
     * @description Returns a list of applicable payment bank accounts that can be used for payment for a given invoice number
     *
     * @tags Billing
     * @name GetApplicablePaymentBankAccountsForInvoicePayment
     * @summary Get applicable payment bank accounts for invoice payment
     * @request GET:/settlements/applicablePaymentBankAccounts
     */
    getApplicablePaymentBankAccountsForInvoicePayment: (
      query: {
        /** Invoice number */
        invoiceNumber: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentBankAccountJson[], any>({
        path: `/settlements/applicablePaymentBankAccounts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the settlement applications for the specified invoice number or payment object.
     *
     * @tags Billing
     * @name GetSettlementApplications
     * @summary Get settlement applications
     * @request GET:/settlements
     */
    getSettlementApplications: (
      query?: {
        /** number of the invoice */
        invoiceNumber?: string
        /** Id of payment */
        paymentId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SettlementApplication[], any>({
        path: `/settlements`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the details of specified settlement application.
     *
     * @tags Billing
     * @name GetSettlementApplication
     * @summary Get settlement application details
     * @request GET:/settlements/{id}
     */
    getSettlementApplication: (id: string, params: RequestParams = {}) =>
      this.request<SettlementApplication, any>({
        path: `/settlements/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Apply a payment per the specified parameters.
     *
     * @tags Billing
     * @name AddAndApplyPayment
     * @summary Apply a payment on a specific invoice
     * @request POST:/settlements/addAndApplyPayment
     */
    addAndApplyPayment: (body: ApplyPaymentRequest, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settlements/addAndApplyPayment`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Bulk upload payments to invoices in CSV file
     *
     * @tags Usage
     * @name AddAndApplyBulkPaymentsCsv
     * @summary Add and apply bulk payments to invoices CSV
     * @request POST:/settlements/addAndApplyPaymentsInBulk/csv
     */
    addAndApplyBulkPaymentsCsv: (data: any, params: RequestParams = {}) =>
      this.request<BulkPaymentUploadResult, any>({
        path: `/settlements/addAndApplyPaymentsInBulk/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Applies a credit memo per the specified parameters
     *
     * @tags Billing
     * @name ApplyCreditMemo
     * @summary Apply a credit memo
     * @request POST:/settlements/applyCreditMemo
     */
    applyCreditMemo: (body: CreditMemoApplicationJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settlements/applyCreditMemo`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Unapplies a credit memo per the specified parameters
     *
     * @tags Billing
     * @name UnapplyCreditMemo
     * @summary Unapply a credit memo
     * @request POST:/settlements/unapplyCreditMemo
     */
    unapplyCreditMemo: (body: CreditMemoUnapplicationJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settlements/unapplyCreditMemo`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  }
  accounting = {
    /**
     * @description Returns all accounting related events between the from and to dates. Since there can be a large number of these, the results are paginated. To retrieve subsequent pages of events, pass in the pageToken returned from the prior call.
     *
     * @tags Accounting
     * @name GetAccountingEvents
     * @summary Get accounting events for the specified dates
     * @request GET:/accounting/journalEntry/events
     */
    getAccountingEvents: (
      query: {
        /**
         * start date in seconds since Epoch(GMT)
         * @format int64
         */
        from: number
        /**
         * end date in seconds since Epoch(GMT)
         * @format int64
         */
        to: number
        /**
         * number of items per page
         * @format int32
         */
        limit?: number
        /** pass this to subsequent calls */
        pageToken?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<AccountingEventPaginatedResponse, any>({
        path: `/accounting/journalEntry/events`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all Journal Entries for the specified accounting period as text/csv
     *
     * @tags Accounting
     * @name GetJournalEntries
     * @summary Return Journal Entries for period
     * @request GET:/accounting/journalEntry
     */
    getJournalEntries: (
      query: {
        /** Id of the period */
        accountingPeriodId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accounting/journalEntry`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns deferred revenue and contract asset balances for the given revenue schedule, as of the given date.If no date is provided, the balances are returned as of the current date.
     *
     * @tags Accounting
     * @name GetRunningBalances
     * @summary Get running balances of revenue schedule
     * @request GET:/accounting/journalEntry/runningBalances
     */
    getRunningBalances: (
      query: {
        /** Revenue schedule id */
        scheduleId: string
        /**
         * Date in seconds since Epoch(GMT)
         * @format int64
         */
        asOf?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/accounting/journalEntry/runningBalances`,
        method: 'GET',
        query: query,
        ...params,
      }),
  }
  metricsReporting = {
    /**
     * @description returns 200 if the result is successfully submitted to a background task
     *
     * @tags MetricsReporting
     * @name PopulateArrMetricsForSubscription
     * @summary submit request to generate/regenerate arr metrics for a subscription
     * @request PUT:/metricsReporting/populateArrMetrics/subscriptions/{subscriptionId}
     */
    populateArrMetricsForSubscription: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/metricsReporting/populateArrMetrics/subscriptions/${subscriptionId}`,
        method: 'PUT',
        ...params,
      }),

    /**
     * @description returns the paginated list of external arr metrics requests
     *
     * @tags MetricsReporting
     * @name GetExternalArrMetrics1
     * @summary gets external arr metrics requests submitted
     * @request GET:/metricsReporting/externalArrMetrics
     */
    getExternalArrMetrics1: (
      query?: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/metricsReporting/externalArrMetrics`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description returns the request with its captured id. Arr metrics will be generated as a backend job
     *
     * @tags MetricsReporting
     * @name AddExternalArrMetrics
     * @summary submit request to generate external arr metrics
     * @request POST:/metricsReporting/externalArrMetrics
     */
    addExternalArrMetrics: (body: ExternalArrScheduleJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/metricsReporting/externalArrMetrics`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description returns the external arr metrics request for the provided id
     *
     * @tags MetricsReporting
     * @name GetExternalArrMetrics
     * @summary gets external arr metrics request for the provided id
     * @request GET:/metricsReporting/externalArrMetrics/{id}
     */
    getExternalArrMetrics: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/metricsReporting/externalArrMetrics/${id}`,
        method: 'GET',
        ...params,
      }),
  }
  notifications = {
    /**
     * @description Unsubscribes the specified notification target from the specified event
     *
     * @tags Notifications
     * @name UnsubscribeTargetOrEvent
     * @summary Unsubscribe from an event
     * @request POST:/notifications/unsubscribe/{notificationId}
     */
    unsubscribeTargetOrEvent: (
      notificationId: string,
      query: {
        /** type of event */
        notificationEventType: 'INVOICE_POSTED' | 'SUBSCRIPTION_CREATED' | 'ORDER_SUBMITTED' | 'ORDER_EXECUTED'
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/notifications/unsubscribe/${notificationId}`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * @description Returns all notification subscriptions for your tenant
     *
     * @tags Notifications
     * @name GetAllNotificationSubscriptionsForTenant
     * @summary Get all notifications
     * @request GET:/notifications
     */
    getAllNotificationSubscriptionsForTenant: (params: RequestParams = {}) =>
      this.request<NotificationTargetAndSubscriptions[], any>({
        path: `/notifications`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds a notification target and events according to the specified parameters
     *
     * @tags Notifications
     * @name AddTargetAndEventsSubscriptions
     * @summary Add a notification target
     * @request POST:/notifications
     */
    addTargetAndEventsSubscriptions: (body: NotificationTargetAndSubscriptions, params: RequestParams = {}) =>
      this.request<NotificationTargetAndSubscriptions, any>({
        path: `/notifications`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Attaches a notification event to the target specified by the notification id
     *
     * @tags Notifications
     * @name SubscribeExistingNotificationTargetToEvent
     * @summary Attach an event to a notification
     * @request POST:/notifications/{notificationId}
     */
    subscribeExistingNotificationTargetToEvent: (
      notificationId: string,
      query: {
        /** type of event */
        notificationEventType: 'INVOICE_POSTED' | 'SUBSCRIPTION_CREATED' | 'ORDER_SUBMITTED' | 'ORDER_EXECUTED'
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/notifications/${notificationId}`,
        method: 'POST',
        query: query,
        ...params,
      }),
  }
  opportunity = {
    /**
     * @description Returns the details of a specified opportunity
     *
     * @tags Orders
     * @name GetOpportunityByCrmOpportunityId
     * @summary Return details of an opportunity
     * @request GET:/opportunity/crm/{id}
     */
    getOpportunityByCrmOpportunityId: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity/crm/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete an opportunity with given CRM ID if there are no associated orders
     *
     * @tags Orders
     * @name DeleteOpportunityByCrmId
     * @summary Delete an opportunity with given CRM ID
     * @request DELETE:/opportunity/crm/{id}
     */
    deleteOpportunityByCrmId: (id: string, params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/opportunity/crm/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of a specified opportunity
     *
     * @tags Orders
     * @name GetOpportunityByOpportunityId
     * @summary Return details of an opportunity
     * @request GET:/opportunity/{id}
     */
    getOpportunityByOpportunityId: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the updated opportunity
     *
     * @tags Opportunity
     * @name UpdateOpportunity
     * @summary Updates a standalone opportunity
     * @request PUT:/opportunity/{id}
     */
    updateOpportunity: (id: string, body: OpportunityRestJson, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity/${id}`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of orders
     *
     * @tags Orders
     * @name GetOrdersByCrmOpportunityId
     * @summary Return a list of orders associated with a CRM opportunity id
     * @request GET:/opportunity/crm/{id}/orders
     */
    getOrdersByCrmOpportunityId: (id: string, params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/opportunity/crm/${id}/orders`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of the updated opportunity
     *
     * @tags Opportunity
     * @name ResetOpportunityClosedState
     * @summary Opens an opportunity
     * @request POST:/opportunity/{id}/open
     */
    resetOpportunityClosedState: (id: string, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity/${id}/open`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all opportunities associated with the specified account. The results are paginated. To fetch all results, pass the cursor returned from a call to subsequent calls.
     *
     * @tags Orders
     * @name GetOpportunities
     * @summary Return all opportunities
     * @request GET:/opportunity
     */
    getOpportunities: (
      query: {
        /**
         * pass the cursor returned from a call to to subsequent calls until all values are fetched
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
        /** id of account */
        accountId: string
        /** CRM ID of the opportunity */
        crmId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<OpportunityPaginationResponse, any>({
        path: `/opportunity`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the details of a specified opportunity
     *
     * @tags Opportunity
     * @name CreateOpportunity
     * @summary Creates a standalone opportunity
     * @request POST:/opportunity
     */
    createOpportunity: (body: OpportunityRestJson, params: RequestParams = {}) =>
      this.request<OpportunityJson, any>({
        path: `/opportunity`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),
  }
  orders = {
    /**
     * @description Retrieve details of a specific order by its ID.
     *
     * @tags Orders
     * @name GetOrder
     * @summary Get order details
     * @request GET:/orders/{id}
     */
    getOrder: (id: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/orders/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a specific order by its ID.
     *
     * @tags Orders
     * @name DeleteOrder
     * @summary Delete an order
     * @request DELETE:/orders/{id}
     */
    deleteOrder: (id: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/orders/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Marks the order as executed. Optionally, the execution time can be specified using the executedOn query parameter.
     *
     * @tags Orders
     * @name ExecuteOrder
     * @summary Mark order as executed.
     * @request PUT:/orders/{id}/execute
     */
    executeOrder: (
      id: string,
      query?: {
        /**
         * The date and time when the order was executed.
         * @format int64
         */
        executedOn?: number
        /** Bypass approval flows by admin */
        adminApprovalFlowBypass?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJson, any>({
        path: `/orders/${id}/execute`,
        method: 'PUT',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Forcefully executes an order. This endpoint bypasses approval checks and immediately executes the order.
     *
     * @tags Orders
     * @name ForceExecuteOrder
     * @summary Mark order as executed
     * @request PUT:/orders/{id}/execute/force
     */
    forceExecuteOrder: (
      id: string,
      query?: {
        /**
         * The date and time when the order was executed.
         * @format int64
         */
        executedOn?: number
        /** Indicates whether to skip the approval check. Default is false. */
        skipApprovalCheck?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJson, any>({
        path: `/orders/${id}/execute/force`,
        method: 'PUT',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves metrics for all line items associated with the specified order.
     *
     * @tags Orders
     * @name GetOrderLineMetrics
     * @summary Get order line metrics
     * @request GET:/orders/{id}/lineItems/metrics
     */
    getOrderLineMetrics: (id: string, params: RequestParams = {}) =>
      this.request<Record<string, MetricsJson>, any>({
        path: `/orders/${id}/lineItems/metrics`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets all orders for your tenant. The results are paginated. To fetch all results, take the cursor returned from a call and pass it to subsequent calls.
     *
     * @tags Orders
     * @name GetOrders
     * @summary Get all Orders
     * @request GET:/orders
     */
    getOrders: (
      query?: {
        /**
         * A string token is used to fetch next set of results. If not provided, the first page of results will be returned. Use the 'next_cursor' value from the previous response to fetch the next page.
         * @format uuid
         */
        cursor?: string
        /**
         * An integer specifying the maximum number of results to return per page. Defaults to 10 if not provided.
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJsonPaginationResponse, any>({
        path: `/orders`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description creates an order with the specified parameters. On success the order id is returned.
     *
     * @tags Orders
     * @name AddOrder
     * @summary Create an order
     * @request POST:/orders
     */
    addOrder: (
      body: OrderRequestJson,
      query?: {
        /** indicates whether this order should be persisted. */
        isDryRun?: boolean
        /** indicates whether an amendment should populate missing lines not provided here. */
        populateMissingLines?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<OrderJson, any>({
        path: `/orders`,
        method: 'POST',
        query: query,
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified order.
     *
     * @tags Orders
     * @name UpdateOrder
     * @summary Update order details
     * @request PUT:/orders
     */
    updateOrder: (
      body: OrderRequestJson,
      query?: {
        /** true if the order should not be persisted. */
        isDryRun?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/orders`,
        method: 'PUT',
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update the status of a specific order by its ID.
     *
     * @tags Orders
     * @name UpdateOrderStatus
     * @summary Update order status
     * @request PUT:/orders/{id}/status/{status}
     */
    updateOrderStatus: (
      id: string,
      status: 'DRAFT' | 'SUBMITTED' | 'EXECUTED' | 'CANCELLED',
      query?: {
        /**
         * The timestamp when order status was updated.
         * @format int64
         */
        statusUpdatedOn?: number
        /** Admin approval to bypass the approval flow */
        adminApprovalFlowByPass?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/orders/${id}/status/${status}`,
        method: 'PUT',
        query: query,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Retrieve the metrics for a specific order by its ID. Metrics can be filtered by a target date.
     *
     * @tags Orders
     * @name GetOrderMetrics
     * @summary Get order metrics
     * @request GET:/orders/{id}/metrics
     */
    getOrderMetrics: (
      id: string,
      query?: {
        /**
         * The target date for filtering metrics.
         * @format int64
         */
        targetDate?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MetricsJson, any>({
        path: `/orders/${id}/metrics`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Rebase a specific order by its ID. Rebasing an order involves recalculating its metrics or values based on updated data or criteria.
     *
     * @tags Orders
     * @name RebaseAmendment
     * @summary Mark order as executed
     * @request PUT:/orders/{orderId}/rebase
     */
    rebaseAmendment: (orderId: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/orders/${orderId}/rebase`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the PDF version of the order form for a specific order identified by its ID.
     *
     * @tags Orders
     * @name GetOrderDocument
     * @summary Fetch order form PDF
     * @request GET:/orders/{id}/pdf
     */
    getOrderDocument: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/orders/${id}/pdf`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Generate and retrieve a PDF representation of the order details for a specific order by its ID.
     *
     * @tags Orders
     * @name CreateOrderDocument
     * @summary Generate an order PDF
     * @request POST:/orders/{id}/pdf
     */
    createOrderDocument: (
      id: string,
      query?: {
        /** Force regeneration of the PDF document even if there has been no changes. Defaults to false. */
        force?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/orders/${id}/pdf`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * @description Download a Microsoft Word document of the order form for a specific order by its ID.
     *
     * @tags Orders
     * @name GetOrderDocumentDoc
     * @summary Download word doc version of order form
     * @request GET:/orders/{id}/doc
     */
    getOrderDocumentDoc: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/orders/${id}/doc`,
        method: 'GET',
        ...params,
      }),
  }
  payments = {
    /**
     * @description Returns all payments for you tenant. The results are paginated. To fetch all take the cursor returned from a call and pass it to a subsequent call.
     *
     * @tags Payments
     * @name GetPayments
     * @summary Get all payments
     * @request GET:/payments
     */
    getPayments: (
      query?: {
        /**
         * cursor returned from previous call
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentJsonPaginationResponse, any>({
        path: `/payments`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the balance of a payment
     *
     * @tags Payments
     * @name GetPaymentBalance
     * @summary Get payment balance
     * @request GET:/payments/{id}/balance
     */
    getPaymentBalance: (id: string, params: RequestParams = {}) =>
      this.request<PaymentBalanceJson, any>({
        path: `/payments/${id}/balance`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Voids the specified payment per the specified parameters
     *
     * @tags Payments
     * @name VoidPayment
     * @summary Voids a payment
     * @request PUT:/payments/{id}/void
     */
    voidPayment: (id: string, body: VoidPaymentJson, params: RequestParams = {}) =>
      this.request<PaymentJson, any>({
        path: `/payments/${id}/void`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the payment configuration for your tenant
     *
     * @tags Payments
     * @name GetPaymentConfiguration
     * @summary Get payment configuration
     * @request GET:/payments/configuration
     */
    getPaymentConfiguration: (params: RequestParams = {}) =>
      this.request<PaymentConfiguration, any>({
        path: `/payments/configuration`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the payment configuration for your tenant.
     *
     * @tags Payments
     * @name UpdatePaymentConfiguration
     * @summary Update payment configuration
     * @request POST:/payments/configuration
     */
    updatePaymentConfiguration: (
      body: ('ACH' | 'CARD' | 'CHECK' | 'WIRE' | 'INVOICE' | 'EXTERNAL')[],
      params: RequestParams = {}
    ) =>
      this.request<PaymentConfiguration, any>({
        path: `/payments/configuration`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description The bank account would be mapped to a cash and an expense ledger account from this list
     *
     * @tags Payments
     * @name GetLedgerAccountsForPaymentBankAccount
     * @summary Gets cash and expense ledger accounts for bank account creation
     * @request GET:/payments/bank-account/accounts/ledger
     */
    getLedgerAccountsForPaymentBankAccount: (
      query?: {
        id?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<LedgerAccount[], any>({
        path: `/payments/bank-account/accounts/ledger`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the details of the specified payment
     *
     * @tags Payments
     * @name GetPayment
     * @summary Get payment details
     * @request GET:/payments/{id}
     */
    getPayment: (id: string, params: RequestParams = {}) =>
      this.request<PaymentJson, any>({
        path: `/payments/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the payments for the specified account
     *
     * @tags Payments
     * @name GetAccountPayment
     * @summary Get payments
     * @request GET:/payments/account/{id}
     */
    getAccountPayment: (id: string, params: RequestParams = {}) =>
      this.request<PaymentJson[], any>({
        path: `/payments/account/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a payment management link for an account
     *
     * @tags Payments
     * @name GetAccountPaymentManagementLink
     * @summary Get account payment management link
     * @request GET:/payments/account-payment/{id}
     */
    getAccountPaymentManagementLink: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/payments/account-payment/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description The bank account would be mapped to a cash and an expense ledger account
     *
     * @tags Payments
     * @name UpsertBankAccount
     * @summary Adds a new payment bank account
     * @request POST:/payments/bank-account
     */
    upsertBankAccount: (body: PaymentBankAccountJson, params: RequestParams = {}) =>
      this.request<PaymentBankAccountJson, any>({
        path: `/payments/bank-account`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name GetBankAccount
     * @summary Get a payment bank account by id
     * @request GET:/payments/bank-account/{id}
     */
    getBankAccount: (id: string, params: RequestParams = {}) =>
      this.request<PaymentBankAccountJson, any>({
        path: `/payments/bank-account/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payments
     * @name DeleteBankAccount
     * @summary Get a payment bank account by id
     * @request DELETE:/payments/bank-account/{id}
     */
    deleteBankAccount: (id: string, params: RequestParams = {}) =>
      this.request<PaymentBankAccountJson, any>({
        path: `/payments/bank-account/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  }
  plans = {
    /**
     * @description Returns the details of the specified plan.
     *
     * @tags Product Catalog
     * @name GetPlan
     * @summary Get plan details
     * @request GET:/plans/{planId}
     */
    getPlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified plan. Note you can't update the details of a plan once it's in use.
     *
     * @tags Product Catalog
     * @name UpdatePlan
     * @summary Update plan
     * @request PUT:/plans/{planId}
     */
    updatePlan: (planId: string, body: PlanJson, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes a plan. Note you can't delete a plan that's in use.
     *
     * @tags Product Catalog
     * @name DeletePlan
     * @summary Delete a plan
     * @request DELETE:/plans/{planId}
     */
    deletePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Marks a plan as active
     *
     * @tags Product Catalog
     * @name ActivatePlan
     * @summary Activate a plan
     * @request PUT:/plans/{planId}/activate
     */
    activatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/activate`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),

    /**
     * @description Marks a plan as deprecated. Once deprecated a plan may not be attached to new orders.
     *
     * @tags Product Catalog
     * @name DeprecatePlan
     * @summary Deprecate a plan
     * @request PUT:/plans/{planId}/deprecate
     */
    deprecatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/deprecate`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),

    /**
     * @description Reactivates a deprecated plan.
     *
     * @tags Product Catalog
     * @name ReactivatePlan
     * @summary Reactivate a plan
     * @request PUT:/plans/{planId}/reactivate
     */
    reactivatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/reactivate`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),

    /**
     * @description Duplicates the specified plan. On success the new plan id is returned.
     *
     * @tags Product Catalog
     * @name DuplicatePlan
     * @summary Duplicate a plan
     * @request POST:/plans/{planId}/duplicate
     */
    duplicatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/duplicate`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds a charge to the specified plan.  On success the id of the new charge is returned
     *
     * @tags Product Catalog
     * @name AddCharge
     * @summary Add charge to plan
     * @request POST:/plans/{planId}/charges
     */
    addCharge: (planId: string, body: ChargeJson, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/plans/${planId}/charges`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the details of the specified charge on the specified plan.
     *
     * @tags Product Catalog
     * @name GetCharge1
     * @summary Get charge details
     * @request GET:/plans/{planId}/charges/{chargeId}
     */
    getCharge1: (planId: string, chargeId: string, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/plans/${planId}/charges/${chargeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified charge on the specified plan.
     *
     * @tags Product Catalog
     * @name UpdateCharge
     * @summary Update charge details
     * @request PUT:/plans/{planId}/charges/{chargeId}
     */
    updateCharge: (planId: string, chargeId: string, body: ChargeJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/plans/${planId}/charges/${chargeId}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes a charge from a plan.
     *
     * @tags Product Catalog
     * @name DeleteCharge
     * @summary Delete a charge
     * @request DELETE:/plans/{planId}/charges/{chargeId}
     */
    deleteCharge: (planId: string, chargeId: string, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/plans/${planId}/charges/${chargeId}`,
        method: 'DELETE',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all plans for a product. The result is paginated. To retrieve all results pass the cursor returned from a call to the next call until all results are returned.
     *
     * @tags Product Catalog
     * @name GetPlans
     * @summary Get plans
     * @request GET:/plans
     */
    getPlans: (
      query?: {
        /** id of the product */
        productId?: string
        /** filter by plan status */
        status?: 'DRAFT' | 'ACTIVE' | 'GRANDFATHERED' | 'ARCHIVED' | 'DEPRECATED'
        /**
         * cursor from the last call
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<PlanJsonPaginationResponse, any>({
        path: `/plans`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a plan. On success the id of the new plan is returned.
     *
     * @tags Product Catalog
     * @name AddPlan
     * @summary Create a plan
     * @request POST:/plans
     */
    addPlan: (body: PlanJson, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the ledger accounts mapped to the specified charge
     *
     * @tags Product Catalog
     * @name GetChargeLedgerAccounts
     * @summary Get ledger accounts
     * @request GET:/plans/{planId}/charges/{chargeId}/ledgerAccounts
     */
    getChargeLedgerAccounts: (planId: string, chargeId: string, params: RequestParams = {}) =>
      this.request<LedgerAccount[], any>({
        path: `/plans/${planId}/charges/${chargeId}/ledgerAccounts`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Map ledger accounts to the specified charge for the specified plan.
     *
     * @tags Product Catalog
     * @name MapLedgerAccountsToCharge
     * @summary Map ledger accounts
     * @request PUT:/plans/{planId}/charges/{chargeId}/ledgerAccounts
     */
    mapLedgerAccountsToCharge: (planId: string, chargeId: string, body: string[], params: RequestParams = {}) =>
      this.request<LedgerAccount[], any>({
        path: `/plans/${planId}/charges/${chargeId}/ledgerAccounts`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates predefined terms associated with the specified plan.
     *
     * @tags Product Catalog
     * @name UpdatePlanTerms
     * @summary Update plan terms
     * @request PUT:/plans/{planId}/terms
     */
    updatePlanTerms: (planId: string, body: string[], params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/plans/${planId}/terms`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update plan metadata. For now, this can be used only to change the entities assigned to a plan
     *
     * @tags Product Catalog
     * @name UpdatePlanMetadata
     * @summary Update plan metadata
     * @request PUT:/plans/{planId}/metadata
     */
    updatePlanMetadata: (planId: string, body: PlanMetadataJson, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/metadata`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Marks a plan as draft
     *
     * @tags Product Catalog
     * @name DeactivatePlan
     * @summary Revert a plan to draft
     * @request PUT:/plans/{planId}/revertToDraft
     */
    deactivatePlan: (planId: string, params: RequestParams = {}) =>
      this.request<PlanJson, any>({
        path: `/plans/${planId}/revertToDraft`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates certain details of the specified charge which won't impact its financial treatment
     *
     * @tags Product Catalog
     * @name PatchCharge
     * @summary Update non-financial charge details
     * @request PUT:/plans/{planId}/charges/{chargeId}/partial
     */
    patchCharge: (planId: string, chargeId: string, body: ChargePartialJson, params: RequestParams = {}) =>
      this.request<ChargeJson, any>({
        path: `/plans/${planId}/charges/${chargeId}/partial`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  termsections = {
    /**
     * @description Returns predefined terms sections.
     *
     * @tags Settings
     * @name GetTermSections
     * @summary Get predefined terms sections
     * @request GET:/termsections
     */
    getTermSections: (params: RequestParams = {}) =>
      this.request<DocumentSection, any>({
        path: `/termsections`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Adds a predefined terms section.
     *
     * @tags Settings
     * @name AddTermSection
     * @summary Add predefined terms section
     * @request POST:/termsections
     */
    addTermSection: (body: DocumentSection, params: RequestParams = {}) =>
      this.request<DocumentSection, any>({
        path: `/termsections`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns predefined terms section detail.
     *
     * @tags Settings
     * @name GetTermSection
     * @summary Get predefined terms section detail
     * @request GET:/termsections/{id}
     */
    getTermSection: (id: string, params: RequestParams = {}) =>
      this.request<DocumentSection, any>({
        path: `/termsections/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the predefined terms section specified.
     *
     * @tags Settings
     * @name UpdateTermSection
     * @summary Update predefined terms section
     * @request PUT:/termsections/{id}
     */
    updateTermSection: (id: string, body: DocumentSection, params: RequestParams = {}) =>
      this.request<DocumentSection, any>({
        path: `/termsections/${id}`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes the predefined terms section specified.
     *
     * @tags Settings
     * @name DeleteTermSection
     * @summary Delete predefined terms section
     * @request DELETE:/termsections/{id}
     */
    deleteTermSection: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/termsections/${id}`,
        method: 'DELETE',
        ...params,
      }),
  }
  product = {
    /**
     * @description Gets all product categories for your tenant. The results are paginated. To fetch all results pass the cursor returned from a call to the subsequent calls until all results are returned. Initially the cursor should not be specified.
     *
     * @tags Product Catalog
     * @name GetProductCategories
     * @summary Get product Categories
     * @request GET:/product/categories
     */
    getProductCategories: (
      query?: {
        /**
         * cursor from prior call
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductCategoryPaginationResponse, any>({
        path: `/product/categories`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a product category. On success the id of the category is returned.
     *
     * @tags Product Catalog
     * @name AddProductCategory
     * @summary Create a product category
     * @request POST:/product/categories
     */
    addProductCategory: (body: ProductCategory, params: RequestParams = {}) =>
      this.request<ProductCategory, any>({
        path: `/product/categories`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the details of the specified product category
     *
     * @tags Product Catalog
     * @name GetProductCategory
     * @summary Get product category details
     * @request GET:/product/categories/{id}
     */
    getProductCategory: (id: string, params: RequestParams = {}) =>
      this.request<ProductCategory, any>({
        path: `/product/categories/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of a product category
     *
     * @tags Product Catalog
     * @name UpdateProductCategory
     * @summary Update product category details
     * @request PUT:/product/categories/{id}
     */
    updateProductCategory: (id: string, body: ProductCategory, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/product/categories/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes the specified product category. Note that a product category can't be delete once it's in use.
     *
     * @tags Product Catalog
     * @name DeleteProductCategory
     * @summary Delete a product category
     * @request DELETE:/product/categories/{id}
     */
    deleteProductCategory: (id: string, params: RequestParams = {}) =>
      this.request<ProductCategory, any>({
        path: `/product/categories/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  }
  products = {
    /**
     * @description Gets the details of the specified product.
     *
     * @tags Product Catalog
     * @name GetProduct
     * @summary Get product details.
     * @request GET:/products/{id}
     */
    getProduct: (id: string, params: RequestParams = {}) =>
      this.request<ProductJson, any>({
        path: `/products/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified product
     *
     * @tags Product Catalog
     * @name UpdateProduct
     * @summary Update product details
     * @request PUT:/products/{id}
     */
    updateProduct: (id: string, body: ProductInputJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/products/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Delete the specified product.
     *
     * @tags Product Catalog
     * @name DeleteProduct
     * @summary Delete a product
     * @request DELETE:/products/{id}
     */
    deleteProduct: (id: string, params: RequestParams = {}) =>
      this.request<ProductJson, any>({
        path: `/products/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns all products for your tenant. The results on paginated. To fetch them all pass the cursor returned from a call to the subsequent call until all results are fetched. Initially the cursor should not be specified.
     *
     * @tags Product Catalog
     * @name GetProducts
     * @summary Get products
     * @request GET:/products
     */
    getProducts: (
      query?: {
        /**
         * cursor received from prior call
         * @format uuid
         */
        cursor?: string
        /**
         * number of results per page
         * @format int32
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<ProductJsonPaginationResponse, any>({
        path: `/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a product for your tenant. On success the id of the product is returned.
     *
     * @tags Product Catalog
     * @name AddProduct
     * @summary Create a product
     * @request POST:/products
     */
    addProduct: (body: ProductInputJson, params: RequestParams = {}) =>
      this.request<ProductJson, any>({
        path: `/products`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Export the product catalog in CSV format.
     *
     * @tags Product Catalog
     * @name ExportProductCatalog
     * @summary Export product catalog
     * @request GET:/products/export
     */
    exportProductCatalog: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/products/export`,
        method: 'GET',
        ...params,
      }),
  }
  ratecards = {
    /**
     * @description Fetch the list of rate cards stored in the system
     *
     * @tags RateCard
     * @name GetRateCards
     * @summary Fetch the list of rate cards stored in the system
     * @request GET:/ratecards
     */
    getRateCards: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ratecards`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description create a rate card using the multipart form data, where a CSV can be passed in for the price table
     *
     * @tags RateCard
     * @name AddRateCardCsv
     * @summary create a rate card using CSV price table multipart form data
     * @request POST:/ratecards/csv
     */
    addRateCardCsv: (
      data: {
        name?: string
        description?: string
        currency?: string
        /** @format binary */
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/ratecards/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description create a rate card using the multipart form data, where a CSV can be passed in for the price table
     *
     * @tags RateCard
     * @name UpdateRateCardCsv
     * @summary create a rate card using CSV price table multipart form data
     * @request PUT:/ratecards/{id}/csv
     */
    updateRateCardCsv: (
      id: string,
      data: {
        name?: string
        description?: string
        currency?: string
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/ratecards/${id}/csv`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Get all the price attributes in the system in CSV format one per row
     *
     * @tags RateCard
     * @name GetPriceAttributesCsv
     * @summary Fetch the price attributes defined in the system in CSV format
     * @request GET:/ratecards/attributes/csv
     */
    getPriceAttributesCsv: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ratecards/attributes/csv`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Import the price attributes from an input CSV file, the output provides details on each row of the input
     *
     * @tags RateCard
     * @name ImportPriceAttributes
     * @summary Import price attributes into the system
     * @request POST:/ratecards/attributes/csv
     */
    importPriceAttributes: (
      data: {
        /** @format binary */
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/ratecards/attributes/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Get the price table for the rate card given the id
     *
     * @tags RateCard
     * @name GetRateCardPriceTable
     * @summary Fetch the price table for the rate card given the id
     * @request GET:/ratecards/{id}/priceTable
     */
    getRateCardPriceTable: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/ratecards/${id}/priceTable`,
        method: 'GET',
        ...params,
      }),
  }
  refunds = {
    /**
     * @description Get all refunds for the specified account, OR if a credit memo number is specified in addition to the account id, get the details of only that.
     *
     * @tags Refunds
     * @name GetRefunds
     * @summary Get refunds
     * @request GET:/refunds
     */
    getRefunds: (
      query: {
        /** id of the account */
        accountId: string
        /** id of a credit memo */
        creditMemoNumber?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<RefundDetail[], any>({
        path: `/refunds`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates and applies a refund per the specified parameters. On success the id of the refund is returned.
     *
     * @tags Refunds
     * @name CreateAndApplyRefund
     * @summary Create and apply refund
     * @request POST:/refunds
     */
    createAndApplyRefund: (body: RefundRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/refunds`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get the details of the specified refund.
     *
     * @tags Refunds
     * @name GetRefundById
     * @summary Get refund details
     * @request GET:/refunds/{id}
     */
    getRefundById: (
      id: string,
      query: {
        /** id of the account */
        accountId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<Refund, any>({
        path: `/refunds/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  reports = {
    /**
     * @description Runs the specified report and returns the result as a csv.
     *
     * @tags Reports
     * @name Run
     * @summary Run a report
     * @request POST:/reports/run
     */
    run: (body: PredefinedReportJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/reports/run`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Generates a report with the specified parameters. This report can later be downloaded via /reports/{reportRunId}/result
     *
     * @tags Reports
     * @name Generate
     * @summary Generate a report
     * @request POST:/reports/generate
     */
    generate: (body: PredefinedReportJson, params: RequestParams = {}) =>
      this.request<ReportJobResponse, any>({
        path: `/reports/generate`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the definitions of the reports defined for your tenant.
     *
     * @tags Reports
     * @name GetPredefinedReportDefs
     * @summary Get report definitions
     * @request GET:/reports
     */
    getPredefinedReportDefs: (params: RequestParams = {}) =>
      this.request<PredefinedReportDefsJson, any>({
        path: `/reports`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Runs a report generated with /generate. On success a csv of the report is returned.
     *
     * @tags Reports
     * @name GetReportOutput
     * @summary Run a generated report
     * @request GET:/reports/{reportRunId}/result
     */
    getReportOutput: (reportRunId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/reports/${reportRunId}/result`,
        method: 'GET',
        ...params,
      }),
  }
  revenueEnablement = {
    /**
     * No description
     *
     * @name EnableRevenueRecognition
     * @summary Enable revenue recognition
     * @request PUT:/revenueEnablement/enable
     */
    enableRevenueRecognition: (body: BillyAuthPrincipal, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/enable`,
        method: 'PUT',
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @name DoAllEntitiesHaveOpenAccountingPeriod
     * @summary Check if all entities have open accounting periods
     * @request GET:/revenueEnablement/readiness/doAllEntitiesHaveOpenAccountingPeriod
     */
    doAllEntitiesHaveOpenAccountingPeriod: (body: BillyAuthPrincipal, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/doAllEntitiesHaveOpenAccountingPeriod`,
        method: 'GET',
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @name AreAllChargesTaggedWithRevenueRules
     * @summary Check if all charges are tagged with revenue rules
     * @request GET:/revenueEnablement/readiness/areAllChargesTaggedWithRevenueRules
     */
    areAllChargesTaggedWithRevenueRules: (body: BillyAuthPrincipal, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/areAllChargesTaggedWithRevenueRules`,
        method: 'GET',
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @name AreAllChargesTaggedWithGlAccounts
     * @summary Check if all charges are tagged with GL accounts
     * @request GET:/revenueEnablement/readiness/areAllChargesTaggedWithGLAccounts
     */
    areAllChargesTaggedWithGlAccounts: (body: BillyAuthPrincipal, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/areAllChargesTaggedWithGLAccounts`,
        method: 'GET',
        body: body,
        ...params,
      }),

    /**
     * No description
     *
     * @name DoAllOrderLinesHaveSchedules
     * @summary Check if all order lines have schedules
     * @request GET:/revenueEnablement/readiness/doAllOrderLinesHaveSchedules
     */
    doAllOrderLinesHaveSchedules: (body: BillyAuthPrincipal, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revenueEnablement/readiness/doAllOrderLinesHaveSchedules`,
        method: 'GET',
        body: body,
        ...params,
      }),
  }
  revrec = {
    /**
     * @description Gets revenue recognition events for the specified subscription and charge.
     *
     * @tags Revenue Recognition
     * @name GetRecognitionEventsBySubscriptionIdChargeId
     * @summary Get revenue recognition events
     * @request GET:/revrec/events
     */
    getRecognitionEventsBySubscriptionIdChargeId: (
      query: {
        /** id of the accounting period to limit events to */
        accountingPeriodId?: string
        /** id of the subscription */
        subscriptionId: string
        /** id of the charge */
        chargeId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<RecognitionEventCompletion[], any>({
        path: `/revrec/events`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a revenue recognition event, only if it is different from the previous event for same subscription and charge.
     *
     * @tags Revenue Recognition
     * @name CreateRecognitionEvent
     * @summary Create a revenue recognition event
     * @request POST:/revrec/events
     */
    createRecognitionEvent: (body: RecognitionEventCompletion, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/events`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Downloads a revenue waterfall report in csv format.
     *
     * @tags Revenue Recognition
     * @name GetRevenueWaterfall
     * @summary Download waterfall report
     * @request GET:/revrec/waterfall
     */
    getRevenueWaterfall: (
      query?: {
        /**
         * report start date as unix timestamp
         * @format int64
         */
        startDate?: number
        /**
         * report end date as unix timestamp
         * @format int64
         */
        endDate?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/revrec/waterfall`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Uploads revenue events from a csv file. The format of the file is Subscription.Id,Charge.Id,Alias.Id,RevrecEvent.PercentComplete Percent complete should be a decimal.
     *
     * @tags Revenue Recognition
     * @name UploadCompletionEvents
     * @summary Upload revenue events
     * @request POST:/revrec/events/upload
     */
    uploadCompletionEvents: (data: any, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/events/upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Get revenue recognition rules.
     *
     * @tags Revenue Recognition
     * @name GetRecognitionRuleById
     * @summary Get revenue recognition rules
     * @request GET:/revrec/rules
     */
    getRecognitionRuleById: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/rules`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Creates a revenue recognition rule.
     *
     * @tags Revenue Recognition
     * @name AddRecognitionRule
     * @summary Create a revenue recognition rule
     * @request POST:/revrec/rules
     */
    addRecognitionRule: (body: RecognitionRule, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/rules`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get a revenue recognition rule using id.
     *
     * @tags Revenue Recognition
     * @name GetRecognitionRuleById1
     * @summary Get revenue recognition rule
     * @request GET:/revrec/rules/{id}
     */
    getRecognitionRuleById1: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/revrec/rules/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Deletes a recognition rule. Note you can't delete a recognition rule that's in use.
     *
     * @tags Revenue Recognition
     * @name DeleteRule
     * @summary Delete a recognition rule
     * @request DELETE:/revrec/rules/{ruleId}
     */
    deleteRule: (ruleId: string, params: RequestParams = {}) =>
      this.request<RecognitionRule, any>({
        path: `/revrec/rules/${ruleId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  }
  sfdc = {
    /**
     * @description Handles the authorization code callback from Salesforce
     *
     * @tags Integrations
     * @name AuthorizationCodeCallback1
     * @summary Callback for the authorization code
     * @request GET:/sfdc
     */
    authorizationCodeCallback1: (
      query: {
        /** authorization code */
        code: string
        /** id of the integration */
        state: string
        /** uri to redirect to */
        redirect_uri?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/sfdc`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Initiate a Salesforce integration. On success a redirect url is returned.
     *
     * @tags Integrations
     * @name InitiateIntegration2
     * @summary Initiate integration with Salesforce
     * @request POST:/sfdc
     */
    initiateIntegration2: (body: SalesforceClientIntegrationRequestJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/sfdc`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Removes the Salesforce integration for the tenant.
     *
     * @tags Integrations
     * @name DeleteIntegration2
     * @summary Delete Salesforce integration
     * @request DELETE:/sfdc
     */
    deleteIntegration2: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/sfdc`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns Salesforce accounts matching the specified name.
     *
     * @tags Integrations
     * @name GetAccountsByName
     * @summary Get Salesforce accounts
     * @request GET:/sfdc/account
     */
    getAccountsByName: (
      query: {
        /** account name */
        name: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SalesforceAccount[], any>({
        path: `/sfdc/account`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Imports an account from Salesforce. On success a redirect uri is returned.
     *
     * @tags Integrations
     * @name ImportAccount
     * @summary Import account from Salesforce
     * @request POST:/sfdc/account
     */
    importAccount: (body: SalesforceAccount, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/sfdc/account`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Gets a Salesforce account by its id.
     *
     * @tags Integrations
     * @name GetAccountById
     * @summary Get Salesforce account
     * @request GET:/sfdc/account/{id}
     */
    getAccountById: (id: string, params: RequestParams = {}) =>
      this.request<SalesforceAccount, any>({
        path: `/sfdc/account/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns the Salesforce opportunities associated withe the specified account.
     *
     * @tags Integrations
     * @name GetOpportunitiesByAccountId
     * @summary Get Salesforce opportunities
     * @request GET:/sfdc/opportunity
     */
    getOpportunitiesByAccountId: (
      query: {
        /** id of the account */
        accountId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<Opportunity[], any>({
        path: `/sfdc/opportunity`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Marks an order as the primary order for its opportunity.
     *
     * @tags Integrations
     * @name UpdatePrimaryOrderIdForOpportunity
     * @summary Make an order primary
     * @request PUT:/sfdc/order/{id}
     */
    updatePrimaryOrderIdForOpportunity: (id: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/sfdc/order/${id}`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Syncs the specified order to Salesforce.
     *
     * @tags Integrations
     * @name SyncOrderToSalesforce
     * @summary Sync order to Salesforce
     * @request PUT:/sfdc/sync/{id}
     */
    syncOrderToSalesforce: (id: string, params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/sfdc/sync/${id}`,
        method: 'PUT',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Syncs the specified order to Salesforce.
     *
     * @tags Integrations
     * @name SyncOrdersToSalesforce
     * @summary Sync orders to Salesforce
     * @request PUT:/sfdc/syncOrders
     */
    syncOrdersToSalesforce: (ids: string[], params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/sfdc/syncOrders`,
        method: 'PUT',
        body: ids,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Syncs the specified tenant to Salesforce. Returns paginated JSON of accounts which were synced
     *
     * @tags Integrations
     * @name SyncTenantToSalesforce
     * @summary Sync whole tenant to Salesforce in a paginated fashion
     * @request PUT:/sfdc/syncTenant
     */
    syncTenantToSalesforce: (
      query: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
        tenantId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/sfdc/syncTenant`,
        method: 'PUT',
        query: query,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Syncs the specified order to Salesforce.
     *
     * @tags Integrations
     * @name SyncDeletedOrdersToSalesforce
     * @summary Sync orders to Salesforce
     * @request PUT:/sfdc/syncDeletedOrders
     */
    syncDeletedOrdersToSalesforce: (ids: string[], params: RequestParams = {}) =>
      this.request<OrderJson, any>({
        path: `/sfdc/syncDeletedOrders`,
        method: 'PUT',
        body: ids,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Syncs the account ARR and its orders to Salesforce.
     *
     * @tags Integrations
     * @name SyncAccountToSalesforce
     * @summary Sync an account to Salesforce
     * @request PUT:/sfdc/syncAccount/{id}
     */
    syncAccountToSalesforce: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/sfdc/syncAccount/${id}`,
        method: 'PUT',
        type: ContentType.Json,
        ...params,
      }),
  }
  subscriptions = {
    /**
     * @description returns all Subscriptions in the system in a paginated fashion
     *
     * @tags Subscriptions
     * @name GetSubscriptions
     * @summary Get paginated subscriptions
     * @request GET:/subscriptions
     */
    getSubscriptions: (
      query?: {
        /**
         * number of items per page
         * @format int32
         */
        limit?: number
        /** pass this to subsequent calls */
        pageToken?: string
        /** optionally pass in account Id, only subscriptions for this account will will returned */
        accountId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedSubscriptionsResponse, any>({
        path: `/subscriptions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Deletes the subscription for given subscription Id if invoices have not been generated and revenue has not been recognized
     *
     * @tags Subscriptions
     * @name DeleteSubscription
     * @summary Delete subscription
     * @request DELETE:/subscriptions/{id}/{version}
     */
    deleteSubscription: (id: string, version: number, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/${version}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Reverts the subscription for given subscription Id and version to it's previous version. Operation is allowed only if invoices have not been generated and revenue has not been recognized
     *
     * @tags Subscriptions
     * @name RevertSubscription
     * @summary Revert subscription
     * @request PUT:/subscriptions/{id}/{version}/revert
     */
    revertSubscription: (id: string, version: number, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/${version}/revert`,
        method: 'PUT',
        ...params,
      }),

    /**
     * @description Update renewal opportunity CRM id on subscription
     *
     * @tags Subscriptions
     * @name UpdateRenewalOpportunity
     * @summary Link renewal opportunity to subscription
     * @request POST:/subscriptions/{id}/renewalOpportunity
     */
    updateRenewalOpportunity: (
      id: string,
      query: {
        /** renewal opportunity CRM id */
        renewalOpportunityCrmId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/renewalOpportunity`,
        method: 'POST',
        query: query,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns the metrics associated with the specified subscription. Metrics include ACV, ARR, etc.
     *
     * @tags Subscriptions
     * @name GetSubscriptionMetrics
     * @summary Get subscription metrics
     * @request GET:/subscriptions/{id}/metrics
     */
    getSubscriptionMetrics: (
      id: string,
      query?: {
        /**
         * As of date for the metrics. If omitted defaults to now.
         * @format int64
         */
        targetDate?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<MetricsJson, any>({
        path: `/subscriptions/${id}/metrics`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the details of the specified subscription.
     *
     * @tags Subscriptions
     * @name GetSubscriptionById
     * @summary Get subscription details
     * @request GET:/subscriptions/{id}
     */
    getSubscriptionById: (id: string, params: RequestParams = {}) =>
      this.request<SubscriptionJson, any>({
        path: `/subscriptions/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the specified subscription.
     *
     * @tags Subscriptions
     * @name UpdateSubscription
     * @summary Update subscription details
     * @request PUT:/subscriptions/{id}
     */
    updateSubscription: (id: string, body: SubscriptionUpdateJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns true if subscription can be deleted.
     *
     * @tags Subscriptions
     * @name SubscriptionModifiable
     * @summary Subscription can be modified
     * @request GET:/subscriptions/{id}/modifiable
     */
    subscriptionModifiable: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/modifiable`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns true if subscription can be reverted to a prior version.
     *
     * @tags Subscriptions
     * @name SubscriptionReversible
     * @summary Subscription can be reverted
     * @request GET:/subscriptions/{id}/reversible
     */
    subscriptionReversible: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/subscriptions/${id}/reversible`,
        method: 'GET',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Gets the billing periods for the specified subscription.
     *
     * @tags Subscriptions
     * @name GetSubscriptionBillingPeriods
     * @summary Get billing periods
     * @request GET:/subscriptions/{id}/billingPeriods
     */
    getSubscriptionBillingPeriods: (id: string, params: RequestParams = {}) =>
      this.request<number[], any>({
        path: `/subscriptions/${id}/billingPeriods`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  taxjar = {
    /**
     * @description Returns the integration ID if successful
     *
     * @tags Integrations
     * @name AddIntegration1
     * @summary Add a TaxJar integration
     * @request POST:/taxjar
     */
    addIntegration1: (body: TaxJarIntegrationInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/taxjar`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Integrations
     * @name TestIntegration1
     * @summary Test an integration is valid
     * @request PUT:/taxjar/test
     */
    testIntegration1: (body: TaxJarIntegrationInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/taxjar/test`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a suggested addresses if found
     *
     * @tags Integrations
     * @name ValidateAddress1
     * @summary Validate an address with TaxJar
     * @request POST:/taxjar/validate
     */
    validateAddress1: (body: AccountAddress, params: RequestParams = {}) =>
      this.request<AccountAddress, any>({
        path: `/taxjar/validate`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Gets the integration details of the specified integration id
     *
     * @tags Integrations
     * @name GetIntegration2
     * @summary Get integration details
     * @request GET:/taxjar/{integrationId}
     */
    getIntegration2: (integrationId: string, params: RequestParams = {}) =>
      this.request<TaxJarIntegration, any>({
        path: `/taxjar/${integrationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  taxrates = {
    /**
     * @description Get all available tax rates. The result is paginated. To retrieve all results pass the cursor returned from a call to the next call until all results are returned.
     *
     * @tags Settings
     * @name GetTaxRates
     * @summary Get tax rates
     * @request GET:/taxrates
     */
    getTaxRates: (
      query?: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<TaxRatePaginationResponseJson, any>({
        path: `/taxrates`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add a new tax rate object
     *
     * @tags Settings
     * @name AddTaxRate
     * @summary Add tax rate
     * @request POST:/taxrates
     */
    addTaxRate: (body: TaxRateJson, params: RequestParams = {}) =>
      this.request<TaxRateJson, any>({
        path: `/taxrates`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves the tax rate object by Id
     *
     * @tags Settings
     * @name GetTaxRate
     * @summary Get tax rate by Id
     * @request GET:/taxrates/{id}
     */
    getTaxRate: (id: string, params: RequestParams = {}) =>
      this.request<TaxRatePaginationResponseJson, any>({
        path: `/taxrates/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the specific tax rate object
     *
     * @tags Settings
     * @name UpdateTaxRate
     * @summary Update tax rate
     * @request PUT:/taxrates/{id}
     */
    updateTaxRate: (id: string, body: TaxRateJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/taxrates/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Deletes the tax rate object by Id. If successful the deleted tax rate object is returned
     *
     * @tags Settings
     * @name DeleteTaxRate
     * @summary Delete tax rate
     * @request DELETE:/taxrates/{id}
     */
    deleteTaxRate: (id: string, params: RequestParams = {}) =>
      this.request<TaxRateJson, any>({
        path: `/taxrates/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  }
  tenants = {
    /**
     * @description Get the details of the current tenant
     *
     * @tags Settings
     * @name GetTenant
     * @summary Get tenant details
     * @request GET:/tenants
     */
    getTenant: (params: RequestParams = {}) =>
      this.request<TenantJson, any>({
        path: `/tenants`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the details of the current tenant based on the input
     *
     * @tags Settings
     * @name UpdateTenant
     * @summary Update tenant details
     * @request PUT:/tenants
     */
    updateTenant: (body: TenantJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/tenants`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get the current logo stored
     *
     * @tags Settings
     * @name GetTenantLogo
     * @summary Get tenant logo
     * @request GET:/tenants/logo
     */
    getTenantLogo: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/tenants/logo`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Updates the logo used in external facing communication such as order forms and invoices
     *
     * @tags Settings
     * @name UploadTenantLogo
     * @summary Update tenant logo
     * @request PUT:/tenants/logo
     */
    uploadTenantLogo: (data: any, params: RequestParams = {}) =>
      this.request<TenantJson, any>({
        path: `/tenants/logo`,
        method: 'PUT',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  settings = {
    /**
     * @description Returns all tenant settings for your tenant.
     *
     * @tags Settings
     * @name GetTenantSetting
     * @summary Get tenant settings
     * @request GET:/settings
     */
    getTenantSetting: (params: RequestParams = {}) =>
      this.request<TenantSettingJson, any>({
        path: `/settings`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates settings for your tenant.
     *
     * @tags Settings
     * @name UpdateTenantSetting
     * @summary Update tenant settings
     * @request PUT:/settings
     */
    updateTenantSetting: (body: TenantSettingJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get supported currencies for your tenant
     *
     * @tags Settings
     * @name GetSupportedCurrencies
     * @summary Get supported currencies
     * @request GET:/settings/supportedCurrencies
     */
    getSupportedCurrencies: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/supportedCurrencies`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Update supported currencies for your tenant
     *
     * @tags Settings
     * @name UpdateSupportedCurrencies
     * @summary Update supported currencies
     * @request PUT:/settings/supportedCurrencies
     */
    updateSupportedCurrencies: (body: string[], params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/supportedCurrencies`,
        method: 'PUT',
        body: body,
        ...params,
      }),

    /**
     * @description Gets plan replacement settings
     *
     * @tags Settings
     * @name GetAutoReplacePlans
     * @summary Get plan replacement settings
     * @request GET:/settings/autoReplacePlans
     */
    getAutoReplacePlans: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/autoReplacePlans`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Update plan replacement settings
     *
     * @tags Settings
     * @name UpdateAutoReplacePlans
     * @summary Update plan replacement settings
     * @request PUT:/settings/autoReplacePlans
     */
    updateAutoReplacePlans: (body: boolean, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/autoReplacePlans`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name GetPaymentTermSettings
     * @summary Get payment term settings
     * @request GET:/settings/paymentTerms
     */
    getPaymentTermSettings: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/paymentTerms`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name UpdatePaymentTermSettings
     * @summary Update payment term settings
     * @request PUT:/settings/paymentTerms
     */
    updatePaymentTermSettings: (body: PaymentTermSettingsJson, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/paymentTerms`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name GetTenantUiCustomizationConfig
     * @summary Fetch UI customization config for the tenant
     * @request GET:/settings/ui/customizations
     */
    getTenantUiCustomizationConfig: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/ui/customizations`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name UpdateTenantUiCustomizationConfig
     * @summary Update UI customization config for the tenant
     * @request POST:/settings/ui/customizations
     */
    updateTenantUiCustomizationConfig: (body: TenantUiCustomization, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/ui/customizations`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Settings
     * @name DeleteTenantUiCustomizationConfig
     * @summary Remove UI customization config for the tenant
     * @request DELETE:/settings/ui/customizations
     */
    deleteTenantUiCustomizationConfig: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/settings/ui/customizations`,
        method: 'DELETE',
        ...params,
      }),
  }
  fx = {
    /**
     * @description Refresh transactional exchange rates for all supported currencies to functional currencies for the given effective date
     *
     * @tags Foreign Exchange
     * @name RefreshExchangeRates
     * @summary Refresh transactional exchange rates
     * @request POST:/fx/transactional/refresh
     */
    refreshExchangeRates: (
      query: {
        /** @format int64 */
        effectiveDate: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/fx/transactional/refresh`,
        method: 'POST',
        query: query,
        ...params,
      }),

    /**
     * @description Get latest transactional exchange rates for all currency pairs of supported currencies to functional currencies
     *
     * @tags Foreign Exchange
     * @name GetLatestExchangeRates
     * @summary Get latest transactional exchange rates
     * @request GET:/fx/transactional/latest
     */
    getLatestExchangeRates: (params: RequestParams = {}) =>
      this.request<TransactionalExchangeRate[], any>({
        path: `/fx/transactional/latest`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Get transactional exchange rate for a currency pair as of a specific date
     *
     * @tags Foreign Exchange
     * @name GetExchangeRateAsOf
     * @summary Get as of transactional exchange rate for a currency pair
     * @request GET:/fx/transactional/asof
     */
    getExchangeRateAsOf: (
      query: {
        fromCurrency?: string
        toCurrency?: string
        /** @format int64 */
        asOf: number
      },
      params: RequestParams = {}
    ) =>
      this.request<TransactionalExchangeRate, any>({
        path: `/fx/transactional/asof`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  unitsOfMeasure = {
    /**
     * @description Returns a paginated list of units of measure
     *
     * @tags Settings
     * @name GetUnitsOfMeasure
     * @summary Get units of measure
     * @request GET:/unitsOfMeasure
     */
    getUnitsOfMeasure: (
      query?: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<UnitOfMeasurePaginationResponseJson, any>({
        path: `/unitsOfMeasure`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add a new instance of unit of measure as specified by the input
     *
     * @tags Settings
     * @name AddUnitOfMeasure
     * @summary Add unit of measure
     * @request POST:/unitsOfMeasure
     */
    addUnitOfMeasure: (body: UnitOfMeasureJson, params: RequestParams = {}) =>
      this.request<UnitOfMeasureJson, any>({
        path: `/unitsOfMeasure`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update the unit of measure by Id provided.
     *
     * @tags Settings
     * @name UpdateUnitOfMeasure
     * @summary Update unit of measure
     * @request PUT:/unitsOfMeasure/{id}
     */
    updateUnitOfMeasure: (id: string, body: UnitOfMeasureJson, params: RequestParams = {}) =>
      this.request<UnitOfMeasureJson, any>({
        path: `/unitsOfMeasure/${id}`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete the unit of measure by Id provided. Returns the deleted unit of measure object if successful.
     *
     * @tags Settings
     * @name DeleteUnitOfMeasure
     * @summary Delete unit of measure
     * @request DELETE:/unitsOfMeasure/{id}
     */
    deleteUnitOfMeasure: (id: string, params: RequestParams = {}) =>
      this.request<UnitOfMeasureJson, any>({
        path: `/unitsOfMeasure/${id}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),

    /**
     * @description Activates the specified unit of measure making it available to be attached to charges
     *
     * @tags Settings
     * @name ActivateUnitOfMeasure
     * @summary Activate unit of measure
     * @request POST:/unitsOfMeasure/{id}/activate
     */
    activateUnitOfMeasure: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/unitsOfMeasure/${id}/activate`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Deprecates the specified unit of measure making it unavailable to be attached to charges going forward
     *
     * @tags Settings
     * @name DeprecateUnitOfMeasure
     * @summary Deprecate unit of measure
     * @request POST:/unitsOfMeasure/{id}/deprecate
     */
    deprecateUnitOfMeasure: (id: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/unitsOfMeasure/${id}/deprecate`,
        method: 'POST',
        ...params,
      }),
  }
  v2 = {
    /**
     * @description Upload usage records in CSV file. Each row of the file represents a single usage record
     *
     * @tags Usage
     * @name UploadSubscriptionUsageCsv
     * @summary Upload usage record CSV
     * @request POST:/v2/usage/csv
     */
    uploadSubscriptionUsageCsv: (data: any, params: RequestParams = {}) =>
      this.request<UsageBatchInsertResult, any>({
        path: `/v2/usage/csv`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Load usage records into the system
     *
     * @tags Usage
     * @name AddUsage
     * @summary Add usage record
     * @request POST:/v2/usage
     */
    addUsage: (body: RawUsagesData, params: RequestParams = {}) =>
      this.request<UsageBatchInsertResult, any>({
        path: `/v2/usage`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve the current aggregated usage data for a subscription between 2 instants
     *
     * @tags Usage
     * @name GetUsageAggregatesForSubscription
     * @summary Get aggregated usage
     * @request GET:/v2/usage/{subscriptionId}
     */
    getUsageAggregatesForSubscription: (
      subscriptionId: string,
      query: {
        /** @format int64 */
        from: number
        /** @format int64 */
        to: number
      },
      params: RequestParams = {}
    ) =>
      this.request<UsageAggregateOutput[], any>({
        path: `/v2/usage/${subscriptionId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve the current prepaid drawdown statistics for a subscription
     *
     * @tags Usage
     * @name GetUsageStatsForSubscription
     * @summary Get prepaid drawdown usage stats for subscription
     * @request GET:/v2/usage/stats/{subscriptionId}
     */
    getUsageStatsForSubscription: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<PrepaidStats[], any>({
        path: `/v2/usage/stats/${subscriptionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve the current prepaid drawdown statistics for a subscription in CSV format
     *
     * @tags Usage
     * @name GetUsageStatsForSubscriptionCsv
     * @summary Get prepaid drawdown usage stats CSV
     * @request GET:/v2/usage/stats/{subscriptionId}/csv
     */
    getUsageStatsForSubscriptionCsv: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/v2/usage/stats/${subscriptionId}/csv`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Retrieve the current prepaid drawdown statistics for a subscription in PDF format
     *
     * @tags Usage
     * @name GetUsageStatsForSubscriptionPdf
     * @summary Get prepaid drawdown usage stats PDF
     * @request GET:/v2/usage/stats/{subscriptionId}/pdf
     */
    getUsageStatsForSubscriptionPdf: (subscriptionId: string, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/v2/usage/stats/${subscriptionId}/pdf`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Retrieve the current prepaid drawdown statistics for all subscriptions for a time range
     *
     * @tags Usage
     * @name GetUsageStatsForAllSubscriptions
     * @summary Get all prepaid drawdown usage stats
     * @request GET:/v2/usage/stats
     */
    getUsageStatsForAllSubscriptions: (
      query: {
        /** @format int64 */
        from: number
        /** @format int64 */
        to: number
      },
      params: RequestParams = {}
    ) =>
      this.request<PrepaidStats[], any>({
        path: `/v2/usage/stats`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve the current prepaid drawdown statistics for all subscriptions for a time range in CSV format
     *
     * @tags Usage
     * @name GetUsageStatsForAllSubscriptionsCsv
     * @summary Get all prepaid drawdown usage stats CSV
     * @request GET:/v2/usage/stats/csv
     */
    getUsageStatsForAllSubscriptionsCsv: (
      query: {
        /** @format int64 */
        from: number
        /** @format int64 */
        to: number
      },
      params: RequestParams = {}
    ) =>
      this.request<any, void>({
        path: `/v2/usage/stats/csv`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Retrieve the latest usage record upload to the system as epoch seconds
     *
     * @tags Usage
     * @name GetUsageArrivalTimeCheckpoint
     * @summary Get usage arrival checkpoint
     * @request GET:/v2/usage/aggregate/usageArrivalTimeCheckpoint
     */
    getUsageArrivalTimeCheckpoint: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/v2/usage/aggregate/usageArrivalTimeCheckpoint`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Trigger the process to aggregate any remaining raw usage records
     *
     * @tags Usage
     * @name PerformOnDemandUsageAggregation
     * @summary Aggregate raw usage
     * @request PUT:/v2/usage/aggregate
     */
    performOnDemandUsageAggregation: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/v2/usage/aggregate`,
        method: 'PUT',
        ...params,
      }),
  }
  userGroups = {
    /**
     * @description Get all available user groups
     *
     * @tags Users
     * @name GetUserGroups
     * @summary Get user groups
     * @request GET:/userGroups
     */
    getUserGroups: (params: RequestParams = {}) =>
      this.request<UserGroupJson[], any>({
        path: `/userGroups`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new user group based on the parameters in the input
     *
     * @tags Users
     * @name AddUserGroup
     * @summary Add user group
     * @request POST:/userGroups
     */
    addUserGroup: (body: UserGroupRequestJson, params: RequestParams = {}) =>
      this.request<UserGroupJson, any>({
        path: `/userGroups`,
        method: 'POST',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the specified user group according to the input
     *
     * @tags Users
     * @name UpdateUserGroup
     * @summary Update user group
     * @request PUT:/userGroups
     */
    updateUserGroup: (body: UserGroupRequestJson, params: RequestParams = {}) =>
      this.request<UserGroupJson, any>({
        path: `/userGroups`,
        method: 'PUT',
        body: body,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a user group by Id
     *
     * @tags Users
     * @name GetUserGroup
     * @summary Get user group
     * @request GET:/userGroups/{userGroupId}
     */
    getUserGroup: (userGroupId: string, params: RequestParams = {}) =>
      this.request<UserGroupJson, any>({
        path: `/userGroups/${userGroupId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete the user group by Id
     *
     * @tags Users
     * @name DeleteUserGroup
     * @summary Delete user group
     * @request DELETE:/userGroups/{userGroupId}
     */
    deleteUserGroup: (userGroupId: string, params: RequestParams = {}) =>
      this.request<UserGroupJson, any>({
        path: `/userGroups/${userGroupId}`,
        method: 'DELETE',
        format: 'json',
        ...params,
      }),
  }
  users = {
    /**
     * @description Returns a specific user by Id
     *
     * @tags Users
     * @name GetUser
     * @summary Get user by Id
     * @request GET:/users/{id}
     */
    getUser: (id: string, params: RequestParams = {}) =>
      this.request<UserJson, any>({
        path: `/users/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Updates the user information. Email cannot be updated.
     *
     * @tags Users
     * @name UpdateUser
     * @summary Update user
     * @request PUT:/users/{id}
     */
    updateUser: (id: string, body: UserInput, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/${id}`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Loads a list of users to be added to in CSV format.
     *
     * @tags Users
     * @name UploadCsv
     * @summary Bulk user upload
     * @request POST:/users/upload
     */
    uploadCsv: (body: InputStream, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/upload`,
        method: 'POST',
        body: body,
        type: ContentType.Text,
        ...params,
      }),

    /**
     * @description Toggle user SSO configuration by user Id.
     *
     * @tags Users
     * @name UpdateUserSsoConfig
     * @summary Update user SSO configuration
     * @request PUT:/users/{id}/sso
     */
    updateUserSsoConfig: (id: string, body: UserSsoUpdate, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/${id}/sso`,
        method: 'PUT',
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns a paginated list of users
     *
     * @tags Users
     * @name GetUsers
     * @summary Get users list
     * @request GET:/users
     */
    getUsers: (
      query?: {
        /** @format uuid */
        cursor?: string
        /** @format int32 */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<UserPaginationResponseJson, any>({
        path: `/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Add a new user to the system. Users in the system must have unique emails. If successful, the path to the new user object is returned and a welcome email containing a temporary password will be sent to the email associated with the user. The login credentials expires in 24 hours. If the user does not login to the system within that time, a new invitation is required.
     *
     * @tags Users
     * @name AddUser
     * @summary Add a new user
     * @request POST:/users
     */
    addUser: (body: UserInput, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Before a user gains access to the application, they must accept Subskribe's terms and conditions. This operation requires user bound access tokens.
     *
     * @tags Users
     * @name AcceptTermsForCurrentUser
     * @summary Accept terms and conditions
     * @request POST:/users/terms-and-conditions
     */
    acceptTermsForCurrentUser: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/users/terms-and-conditions`,
        method: 'POST',
        ...params,
      }),

    /**
     * @description Resend welcome email conditioning temporary credentials. This is required if the user does not register within 24 hours of user activation
     *
     * @tags Users
     * @name ResendEmailForExistingUser
     * @summary Resend welcome email
     * @request POST:/users/resend-email/{email}
     */
    resendEmailForExistingUser: (email: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/users/resend-email/${email}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description Disables a user by Id. If successful, returns the user just disabled
     *
     * @tags Users
     * @name DisableUser
     * @summary Disable user
     * @request PUT:/users/disable/{id}
     */
    disableUser: (id: string, params: RequestParams = {}) =>
      this.request<UserJson, any>({
        path: `/users/disable/${id}`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),

    /**
     * @description Enables a user by Id. If successful, returns the user just enabled
     *
     * @tags Users
     * @name EnableUser
     * @summary Enable user
     * @request PUT:/users/enable/{id}
     */
    enableUser: (id: string, params: RequestParams = {}) =>
      this.request<UserJson, any>({
        path: `/users/enable/${id}`,
        method: 'PUT',
        format: 'json',
        ...params,
      }),
  }
}
