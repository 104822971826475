import React, { Fragment } from 'react'
import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'
import ActionButton, { ButtonData } from '../button/actionButton'
import { ActionMenuButton } from '../menu/actionMenuButton'

export const useStyles = makeStyles()((theme: Theme) => ({
  toolbarMenuLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: '100%',
  },
}))

export type ButtonDataList<T = string> = ButtonData<T>[]

type Props = {
  buttonDataList: ButtonDataList
}

function SecondaryBarWithButtons({ buttonDataList }: Props): JSX.Element {
  return (
    <>
      <div style={{ flex: 1 }} />
      <Box display="inline-flex">
        {buttonDataList.map((buttonData) => {
          if (buttonData.menu) {
            if (buttonData.menu.length > 0) {
              return <ActionMenuButton key={buttonData.label} buttonData={buttonData} />
            } else return <Fragment key={buttonData.label}></Fragment>
          } else {
            return <ActionButton key={buttonData.label} buttonData={buttonData} />
          }
        })}
      </Box>
    </>
  )
}

export default SecondaryBarWithButtons
