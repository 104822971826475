export enum AI_SUMMARY_OBJECT {
  SUBSCRIPTION = 'subscription',
  ORDER = 'order',
}

export function getSummaryApiEndpoint(objectName: AI_SUMMARY_OBJECT, objectId: string) {
  return `/api/backend/ai/summary/${objectName}/${objectId}`
}

export function getChatMessageApiEndpoint(sessionId: string, messageContent: string) {
  return `/api/backend/ai/agents/session/${sessionId}/chatAsync?userMessage=${messageContent}`
}
